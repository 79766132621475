import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Injector,
	Input,
	Output,
	TemplateRef,
	ViewChild,
	ViewContainerRef,
} from '@angular/core';
import { ExpansionService } from '../../expansion.service';
import { ExpanderCluster } from '../../types/expanderCluster';

export interface ExpansionComponent<EXPANSION_CONTEXT> {
	context: EXPANSION_CONTEXT;
}

@Component({
	selector: 'ws-expander',
	templateUrl: './expander.component.html',
})
export class ExpanderComponent<COMPONENT_INTERFACE extends Record<string, any> = Record<string, any>>
	implements AfterViewInit
{
	@ViewChild('container', { read: ViewContainerRef }) container!: ViewContainerRef;
	@Input() wrapperTemplate?: TemplateRef<any>;
	@Input() scope!: string;
	@Input() contextName: string = 'context';
	@Input() styleClass?: string;
	@Input() context!: any;
	@Output() contextChange: EventEmitter<any> = new EventEmitter<any>();

	// @ts-ignore
	protected cluster!: ExpanderCluster = new ExpanderCluster();

	constructor(private expansionService: ExpansionService, protected cdr: ChangeDetectorRef) {}

	ngAfterViewInit(): void {
		this.cluster.pushInstances(this.expansionService.applyExpansions(this.scope, this.container, this.styleClass));

		this.cluster.assign(this.contextName, this.context);

		this.cdr.detectChanges();
	}

	public call(method: string, params?: any) {
		this.cluster?.call(method, params);
	}

	public callDeferred(method: string, params?: any) {
		return this.cluster?.callDeferred(method, params);
	}
}
