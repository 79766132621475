<span *ngIf="mode === 'cell'" class="cell">
	<input
		disabled="{{ disabled }}"
		(input)="onInput($event)"
		class="form-control"
		(keydown.enter)="onEnter()"
		type="text"
		[maxlength]="maxlength"
		placeholder="{{ placeholder }}"
		[(ngModel)]="value"
	/>
</span>
<span *ngIf="mode === 'cfg'" class="cfg">
	<input
		disabled="{{ disabled }}"
		(input)="onInput($event)"
		class="form-control"
		(keydown.enter)="onEnter()"
		type="text"
		placeholder="{{ placeholder }}"
		[(ngModel)]="value"
	/>
</span>
