import { StorageSettings } from 'ws-framework';

//ToDo Implement in a proper way‚
export class StorageSettingsImpl implements StorageSettings {
	get appStorageScopeKey(): string {
		return 'myAppStorageScopeKey';
	}

	get tokenStorageKey(): string {
		return 'myTokenStorageKey';
	}
}
