export class ResourceError extends Error {
	public constructor(resourceName: string, bundleName: string, message?: string) {
		super(
			'Resource error in resource "' +
				resourceName +
				'" of bundle "' +
				bundleName +
				'" ' +
				(message !== undefined ? 'Error: ' + message : '')
		);
	}
}
