import {
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
} from '@angular/core';

@Component({
	selector: 'ws-input-time',
	templateUrl: './input-time.component.html',
	styleUrls: ['./input-time.component.scss'],
})
export class InputTimeComponent implements OnInit, OnChanges {
	@Input() label!: string;
	@Input() labelClass!: string;
	@Input() model: any;
	@Input() disabled!: boolean;
	@Input() checkbox!: boolean;
	@Input() required!: boolean;
	@Output() resultChange = new EventEmitter<string>();
	hours!: string;
	minutes!: string;

	constructor(private cd: ChangeDetectorRef) {}

	ngOnInit() {
		this.required = this.required || false;
		if (!this.model) {
			// @ts-ignore
			delete this.hours;
			// @ts-ignore
			delete this.minutes;
		} else {
			this.parseTime(this.model);
		}
	}

	calculateResult() {
		let hours = parseInt(this.hours, 10) || 0;
		let minutes = parseInt(this.minutes, 10) || 0;
		if (hours > 24) {
			hours = 24;
		}
		if (minutes > 59) {
			minutes = 59;
		}
		if (hours === 24 && minutes > 0) {
			minutes = 0;
		}

		this.model = hours * 60 + minutes;
		this.minutes = minutes.toString();
		this.hours = hours.toString();
		this.formatTime();
		this.cd.markForCheck();
		this.resultChange.emit(this.model);
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.result !== undefined) {
			this.parseTime(changes.result.currentValue);
		}
	}

	decreaseHours() {
		let hours = parseInt(this.hours, 10) || 0;
		if (hours > 0) {
			hours--;
		} else {
			hours = 23;
		}
		this.hours = hours.toString();
		this.calculateResult();
	}

	decreaseMinutes() {
		let minutes = parseInt(this.minutes, 10) || 0;
		if (minutes > 0) {
			minutes--;
		} else {
			minutes = 59;
		}
		this.minutes = minutes.toString();
		this.calculateResult();
	}

	increaseHours() {
		let hours = parseInt(this.hours, 10) || 0;
		if (hours < 23) {
			hours++;
		} else {
			hours = 0;
		}
		this.hours = hours.toString();
		this.calculateResult();
	}

	increaseMinutes() {
		let minutes = parseInt(this.minutes, 10) || 0;
		if (minutes < 59) {
			minutes++;
		} else {
			minutes = 0;
		}
		this.minutes = minutes.toString();
		this.calculateResult();
	}

	private parseTime(result: any) {
		this.hours = Math.floor(this.model / 60).toString();
		this.minutes = (this.model % 60).toString();
		this.formatTime();
	}

	private formatTime() {
		if (this.minutes.length === 1) {
			this.minutes = '0' + this.minutes;
		}
		if (this.hours.length === 1) {
			this.hours = '0' + this.hours;
		}
	}
}
