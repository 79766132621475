<ng-container ngForm #frm="ngForm">
	<label [ngClass]="labelClass" *ngIf="label">{{ label }}</label>
	<mat-radio-group
		[ngClass]="groupClass"
		[(ngModel)]="result"
		(ngModelChange)="setValue($event)"
		[name]="name"
		[appCustomPatternValidator]="fieldValidationConstraints"
		#control="ngModel"
	>
		<mat-radio-button
			[color]="color"
			[ngClass]="elementClass"
			*ngFor="let option of options"
			[value]="getValue(option, value)"
			[disabled]="disabled"
		>
			{{ getValue(option, display) !== undefined ? getValue(option, display) : getValue(option, value) }}
			<i *ngIf="option.icon" class="{{ option.icon }}"></i>
			<ws-tooltip
				*ngIf="option.tooltip"
				type="INFO"
				position="after"
				[tooltips]="tooltips"
				[id]="option.tooltip"
				class="tooltip"
			></ws-tooltip>
		</mat-radio-button>
		<mat-error>
			<ws-field-error
				[error]="error"
				[interpolationParams]="errorInterpolationParams"
				[control]="frm.controls[name]"
			></ws-field-error>
		</mat-error>
	</mat-radio-group>
</ng-container>
