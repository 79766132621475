import { EventEmitter, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { v4 } from 'uuid';
import { SessionStorage } from '../util/storage';

export interface PaginationParameters {
	page: number;
	page_size: number;
}

@Injectable()
export abstract class PaginationService {
	private _paginatorRefresh = new EventEmitter();
	private _pagedSource = new Subject<PagedEvent>();

	@SessionStorage
	private _current_page!: number;
	//@SessionStorage // TODO figure out how to properly default
	private _page_size: number = 10;
	private _total_pages = 0;
	private _dataset_size = 0;

	protected constructor() {
		//this.initLocalStorage();
	}

	public get paged$() {
		return this._pagedSource.asObservable();
	}
	public get paginatorRefresh$() {
		return this._paginatorRefresh;
	}

	public init() {
		// apply defaults
		this.flush();
	}

	public get currentPage() {
		return this._current_page;
	}
	public set page(value: number) {
		this._current_page = value || 0;
	}
	public get pageSize() {
		return this._page_size;
	}
	public set pageSize(value: number) {
		this._page_size = value || 0;
	}
	public get totalPages() {
		return this._total_pages;
	}
	public get dataSize() {
		return this._dataset_size;
	}
	public get pagination(): PaginationParameters {
		return {
			page: this._current_page,
			page_size: this._page_size,
		};
	}

	public paged(event: PagedEvent, ignorePaginator?: any) {
		if (typeof event.page === 'undefined') {
			event.page = this._current_page;
		}
		if (!event.size) {
			event.size = this._page_size;
		}
		this._current_page = event.page;
		this._page_size = event.size;
		this._pagedSource.next(event);
		if (!ignorePaginator) {
			this.paginatorRefresh$.emit();
		}
	}

	public config(settings: PaginationSettings) {
		this._current_page = settings.currentPage || 0;
		this._page_size = settings.pageSize || this._page_size;
		this._total_pages = settings.totalPages;
		this._dataset_size = settings.datasetSize;
		this.initFlock();
	}

	public flush() {
		this._current_page = 0;
		this._page_size = 10;
		this._total_pages = 0;
		this._dataset_size = 0;
	}

	private initFlock() {
		this.paged({ page: this._current_page, size: this._page_size }, true);
	}

	/*private initLocalStorage() {
		const self = this;
		Object.defineProperty(this, '_current_page', {
			get: function () {
				const storageValue = localStorage.getItem(`page_${self.id}`);
				let val = storageValue === 'undefined' ? undefined : storageValue === 'null' ? null : storageValue;

				if (!val) {
					localStorage.setItem(`page_${self.id}`, '0');
					val = '0';
				}

				return parseInt(val);
			},
			set: function (v: number) {
				localStorage.setItem(`page_${self.id}`, v.toString());
			},
		});
	}*/
}

export interface PaginationSettings {
	currentPage: number;
	totalPages: number;
	datasetSize: number;
	pageSize: number;
}

export class PagedEvent {
	page?: number;
	size?: number;
}
