export interface ValidationError {
	field: string;
	property: string;
	message: ValidationMessage;
	pattern: string;
}

export interface ValidationMessage {
	message: string;
	interpolation?: Record<string, any>;
}

export class ControlError implements ValidationError {
	constructor(
		private _field: string,
		private _property: string,
		private _message: ValidationMessage,
		private _pattern: string,
		private _control: any
	) {}

	public get field() {
		return this._field;
	}

	public get property() {
		return this._property;
	}

	public get message() {
		return this._message;
	}

	public get pattern() {
		return this._pattern;
	}

	public detectErrors() {
		this._control.markAsTouched();
	}
}
