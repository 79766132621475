import { AfterViewInit, Directive, EventEmitter, Input, OnDestroy, Optional, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { PaginationService } from './pagination.service';
import { PaginationConsumer } from './pagination-consumer';

@Directive({
	selector: '[wsPaginator]',
})
export class PaginatorDirective extends PaginationConsumer implements AfterViewInit, OnDestroy {
	@Output()
	paged = new EventEmitter();

	private pageEventSubscription = new Subscription();

	constructor(@Optional() paginationService: PaginationService) {
		super(paginationService);
	}

	ngAfterViewInit() {
		this.pageEventSubscription = this.paginationService.paginatorRefresh$.subscribe((event) => {
			this.paged.emit(event);
		});
	}

	ngOnDestroy() {
		this.pageEventSubscription.unsubscribe();
	}
}
