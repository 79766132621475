import { NgModule } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DirectivesModule } from '../directives/directives.module';
import { PipesModule } from '../pipes/pipes.module';
import { CommonModule } from '@angular/common';
import { TextFieldModule } from '@angular/cdk/text-field';
import { TextareaComponent } from './textarea/textarea.component';
import { FormControlComponent } from './form-control/form-control.component';
import { FieldErrorComponent } from './field-error/field-error.component';
import { EditableFieldComponent } from './editable-field/editable-field.component';
import { InputNumberComponent } from './input-number/input-number.component';
import { InputDateComponent } from './input-date/input-date.component';
import { InputTimeComponent } from './input-time/input-time.component';
import { ButtonComponent } from './button/button.component';
import { TooltipComponent } from './tooltip/tooltip.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { InputComponent } from './input/input.component';
import { SelectComponent } from './select/select.component';
import { RadioGroupComponent } from './radio-group/radio-group.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AutocompleteComponent } from './autocomplete/autocomplete.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { DateAdapterService } from './service/date-adapter.service';
import { JsonFormComponent } from './json-form/json-form.component';
import { TabComponent } from './tab/tab.component';

@NgModule({
	providers: [DateAdapterService],
	declarations: [
		TextareaComponent,
		FormControlComponent,
		FieldErrorComponent,
		EditableFieldComponent,
		InputNumberComponent,
		InputDateComponent,
		InputTimeComponent,
		ButtonComponent,
		TooltipComponent,
		CheckboxComponent,
		InputComponent,
		SelectComponent,
		RadioGroupComponent,
		AutocompleteComponent,
		JsonFormComponent,
		TabComponent,
	],
	imports: [
		RouterModule,
		MatFormFieldModule,
		FormsModule,
		MatInputModule,
		MatButtonModule,
		MatCheckboxModule,
		MatSelectModule,
		MatButtonModule,
		MatIconModule,
		MatCheckboxModule,
		MatRadioModule,
		MatTooltipModule,
		MatSlideToggleModule,
		CommonModule,
		DirectivesModule,
		PipesModule,
		MatDatepickerModule,
		MatListModule,
		TextFieldModule,
		MatAutocompleteModule,
		MatProgressSpinnerModule,
		ReactiveFormsModule,
		TranslateModule,
	],
	exports: [
		MatFormFieldModule,
		MatButtonModule,
		MatSlideToggleModule,
		MatTooltipModule,
		MatIconModule,
		MatRadioModule,
		MatCheckboxModule,
		MatSelectModule,
		FormsModule,
		CommonModule,
		DirectivesModule,
		PipesModule,
		MatInputModule,
		MatListModule,
		TextFieldModule,
		MatAutocompleteModule,
		EditableFieldComponent,
		TextareaComponent,
		InputNumberComponent,
		InputDateComponent,
		InputTimeComponent,
		ButtonComponent,
		TooltipComponent,
		CheckboxComponent,
		InputComponent,
		SelectComponent,
		RadioGroupComponent,
		AutocompleteComponent,
		FieldErrorComponent,
		JsonFormComponent,
		TabComponent,
	],
})
export class ControlsModule {}

export * from './json-form/json-form.component';
