export class Mailbox {
	private subscriptions: unknown[];

	constructor() {
		this.subscriptions = [];
	}

	public push(sub: unknown) {
		if (!this.subscriptions) {
			console.log('This box was burned');
			return;
		}
		this.subscriptions.push(sub);
	}

	public burn() {
		for (let sub of this.subscriptions) {
			// @ts-ignore
			sub.unsubscribe();
		}

		// @ts-ignore
		this.subscriptions = undefined;
	}
}
