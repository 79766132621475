import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HtmlEditorComponent } from './components/html-editor/html-editor.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { FormsModule } from '@angular/forms';

@NgModule({
	declarations: [HtmlEditorComponent],
	imports: [CommonModule, EditorModule, FormsModule],
	providers: [],
	exports: [HtmlEditorComponent],
})
export class HtmlEditorModule {}

export * from './components/html-editor/html-editor.component';
