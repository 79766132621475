import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'ws-entity-list-header',
	templateUrl: './entity-list-header.component.html',
	styleUrls: ['./entity-list-header.component.scss'],
})
export class EntityListHeaderComponent {
	@Output() onRefresh: EventEmitter<any> = new EventEmitter();
	@Output() onDelete: EventEmitter<any> = new EventEmitter();

	@Input() noCreate = false;
	@Input() noRefresh = true;
	@Input() noDelete = false;
	@Input() entityLabel!: string;
	@Input() createAction: Function = this.routeToNew;

	constructor(private router: Router) {}

	routeToNew() {
		this.router.navigateByUrl(this.router.url + '/new');
	}
}
