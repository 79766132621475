import { ExpansionInstance } from './expansion-instance';

export class ExpanderCluster<COMPONENT_INTERFACE extends Record<string, any> = Record<string, any>> {
	get instances() {
		return this._instances;
	}

	private _instances: ExpansionInstance<COMPONENT_INTERFACE>[] = [];

	constructor() {}

	pushInstances(instances: ExpansionInstance<COMPONENT_INTERFACE>[]) {
		for (let ini of instances) {
			this._instances.push(ini);
		}

		this._instances.sort((lh, rh) => lh.position - rh.position);
	}

	call(method: string, params?: any) {
		for (let instance of this._instances) {
			if (typeof instance.ref?.instance[method] === 'function') {
				instance.ref?.instance[method](params);
			}
		}
	}

	callDeferred(method: string, params?: any, cutoff = 20000) {
		const promises = [];

		for (let instance of this._instances) {
			if (typeof instance.ref?.instance[method] === 'function') {
				const promise = instance.ref?.instance[method](params);

				if (promise instanceof Promise) {
					promises.push(promise);
				}
			}
		}

		if (!promises.length) {
			return Promise.resolve();
		} else {
			return Promise.race([
				Promise.all(promises),
				new Promise((resolve, reject) => {
					setTimeout(() => {
						reject();
					}, cutoff);
				}),
			]);
		}
	}

	assign(property: string, value: any) {
		// TODO possibly use an Observable here
		for (let instance of this._instances) {
			// @ts-ignore
			instance?.ref?.instance[property] = value;
		}
	}
}
