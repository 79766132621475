import { Component, OnInit, Optional } from '@angular/core';
import { TenantService } from '../../service/tenant.service';
import { Tenant } from '../../types/tenant';
import { JwtDecoderService } from '../../../services/jwt-decoder.service';

@Component({
	selector: 'ws-tenant-selector',
	templateUrl: './tenant-selector.component.html',
	styleUrls: ['./tenant-selector.component.scss'],
})
export class TenantSelectorComponent implements OnInit {
	constructor(@Optional() private _tenantService: TenantService, private jwtDecoderService: JwtDecoderService) {}

	public get initialized() {
		return this._tenantService.initialized;
	}

	public get activeTenantId() {
		return this._tenantService?.tenant?.id.toString() || '';
	}

	public get activeTenant() {
		// @ts-ignore
		return this._tenantService?.tenant;
	}

	public set activeTenant(value: Tenant) {
		this._tenantService.selectTenant(value.id.toString());
	}

	public get tenants() {
		return this._tenantService?.tenants;
	}

	ngOnInit() {}

	ngAfterViewInit() {}

	/*onSelect(index: number) {
		console.log(index);

		this._tenantService.selectTenant(index);
	}*/
}
