<div class="selection-wrapper">
	<select class="form-control form-control-sm" [(ngModel)]="size" name="size" (change)="onSelect()">
		<option [ngValue]="5">5</option>
		<option [ngValue]="10">10</option>
		<option [ngValue]="15">15</option>
		<option [ngValue]="25">25</option>
		<option [ngValue]="50">50</option>
		<option [ngValue]="100">100</option>
		<option [ngValue]="500">500</option>
		<option [ngValue]="1000">1000</option>
	</select>
</div>
