import {
	AfterViewInit,
	Component,
	ContentChild,
	ElementRef,
	EventEmitter,
	OnInit,
	Output,
	ViewChild,
} from '@angular/core';
import { ViewModeDirective } from '../../directives/view-mode.directive';
import { EditModeDirective } from '../../directives/edit-mode.directive';
import { fromEvent, Subject } from 'rxjs';
import { filter, switchMapTo, take } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
	selector: 'ws-editable-field',
	template: '<ng-container *ngTemplateOutlet="currentView"></ng-container>',
})
export class EditableFieldComponent implements OnInit, AfterViewInit {
	@Output() update = new EventEmitter();
	@ContentChild(ViewModeDirective) viewModeTpl!: ViewModeDirective;
	@ContentChild(EditModeDirective) editModeTpl!: EditModeDirective;

	@ContentChild('input') child!: ElementRef;
	public input: any;
	mode: 'view' | 'edit' = 'view';

	editMode = new Subject();
	editMode$ = this.editMode.asObservable();

	constructor(private host: ElementRef) {}

	public get currentView() {
		return this.mode === 'view' ? this.viewModeTpl.tpl : this.editModeTpl.tpl;
	}

	ngOnInit() {
		this.viewModeHandler();
		this.editModeHandler();
	}

	toViewMode() {
		this.update.next(this.child.nativeElement.value);
		this.mode = 'view';
	}

	ngAfterViewInit(): void {
		console.log(this.child);
	}

	private viewModeHandler() {
		fromEvent(this.element, 'dblclick')
			.pipe(untilDestroyed(this))
			.subscribe(() => {
				this.mode = 'edit';
				this.editMode.next(true);
			});
	}

	private get element() {
		return this.host.nativeElement;
	}

	private editModeHandler() {
		const clickOutside$ = fromEvent(document, 'click').pipe(
			filter(({ target }) => !this.host.nativeElement.contains(target)),
			take(1)
		);

		this.editMode$.pipe(switchMapTo(clickOutside$), untilDestroyed(this)).subscribe((event) => {
			this.update.next(null);
			this.mode = 'view';
		});
	}
}
