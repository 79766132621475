import {
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import { ValidationConstraint } from '../../domain/validation-constraint';
import * as _ from 'lodash';
import { ControlError, ValidationError } from '../../domain/validation-error';
import { deepCopy } from '../../util/helpers';

@Component({
	selector: 'ws-form-control',
	template: '',
})
export class FormControlComponent implements OnInit, OnChanges {
	@Input() id = '';
	@Input() focus = false;
	@Input() disabled = false;
	@Input() result: any;
	@Input() inputClass = '';
	@Input() labelClass = '';
	@Input() placeholder = '';
	@Input() required = false;
	@Input() tabIndex: number | undefined;
	@Input() errors!: ValidationError[];
	@Input() label: string | undefined;
	@Input() validationConstraints: ValidationConstraint[] | undefined;
	@Input() readonly = false;
	@Input() hint: string | undefined;
	@Input() path = '';
	@Input() name = 'control';
	@Input() model = ''; // Model path for validation
	@Input() property = ''; // Property path For validation

	@Output() resultChange = new EventEmitter();
	@Output() enterKeyUp = new EventEmitter();
	@Output() enterKeyDown = new EventEmitter();
	@Output() blurEvent = new EventEmitter();
	@Output() pasteEvent = new EventEmitter();

	@ViewChild('control', { static: false }) input!: ElementRef;
	@ViewChild('frm') form: any;

	public error: string | undefined;
	public fieldValidationConstraints!: ValidationConstraint[];

	private _errorInterpolationParams?: Record<string, any>;

	constructor() {}

	public get errorInterpolationParams() {
		return this._errorInterpolationParams;
	}

	markAsTouched() {
		this.form.controls[this.name].markAsTouched();
	}

	ngOnInit(): void {
		if (this.focus) {
			setTimeout(() => this.input.nativeElement.focus(), 2000);
		}
	}

	onEnter() {
		this.enterKeyUp.emit();
	}

	onModelChanged($event: any) {
		this.resultChange.emit($event);
	}

	onBlur($event: FocusEvent) {
		this.blurEvent.emit();
	}

	ngOnChanges(changes: SimpleChanges): void {
		setTimeout(() => {
			this.setErrors();
		}, 200);

		if (changes.validationConstraints) {
			this.updateValidationConstraints();
		}
	}

	private setErrors() {
		if (!this.errors) {
			this.errors = [];
		}
		const isPushed = this.pushErrors();
		const isRemoved = this.removeErrors();
		if (isPushed || isRemoved) {
			this.setErrorMessage();
		}
	}

	private removeErrors() {
		let isRemoved = false;
		if (!!this.errors) {
			for (let i = this.errors.length - 1; i >= 0; i--) {
				const doesDeletableErrorExist = _.find(this.fieldValidationConstraints, (fvc) => {
					return (
						!fvc.errorMessage &&
						fvc.model === this.errors[i].field &&
						fvc.property === this.errors[i].property &&
						fvc.pattern === this.errors[i].pattern
					);
				});
				if (
					doesDeletableErrorExist ||
					((!this.fieldValidationConstraints || this.fieldValidationConstraints.length === 0) &&
						this.errors[i].field === this.model &&
						this.errors[i].property == this.property)
				) {
					isRemoved = this.errors.splice(i, 1).length !== 0;
				}
			}
		}
		return isRemoved;
	}
	private pushErrors() {
		let isPushed = false;
		this.fieldValidationConstraints
			?.filter((fvc) => !!fvc.errorMessage)
			.forEach((fvc) => {
				const doesMessageExist = _.find(
					this.errors,
					(err) =>
						err.message === fvc.errorMessage &&
						err.field === fvc.model &&
						fvc.pattern === err.pattern &&
						fvc.property === err.property
				);
				if (!doesMessageExist) {
					const controlError = new ControlError(
						fvc.model!,
						fvc.property!,
						fvc.errorMessage!,
						fvc.pattern!,
						this
					);
					this.errors.push(controlError);
					isPushed = true;
				}
			});
		return isPushed;
	}

	private setErrorMessage() {
		const propError = _.find(this.errors, (err: any) => err.field === this.model && err.property === this.property);
		if (propError && this.form.form.controls[this.name] && this.form.form.controls[this.name].touched) {
			this.error = propError.message.message;
			this._errorInterpolationParams = propError.message.interpolation;
			this.form.form.controls[this.name].markAsTouched(true);
			this.form.form.controls[this.name].setErrors({ incorrect: true });
		}
	}

	private updateValidationConstraints() {
		if (this.validationConstraints !== null && this.model && this.property) {
			const filtered = this.filterFieldConstraints() ?? [];

			const fieldValidationWithoutError =
				this.fieldValidationConstraints?.map((fvc) => {
					let copied = deepCopy(fvc);
					delete copied.errorMessage;
					return copied;
				}) || [];

			if (!_.isEqual(fieldValidationWithoutError, filtered)) {
				this.fieldValidationConstraints = filtered;

				// Force validation
				setTimeout(() => {
					if (this.form?.form.controls[this.name]) {
						this.form.form.controls[this.name].updateValueAndValidity();
					}
				}, 200);
			}
		}
	}

	private filterFieldConstraints(): ValidationConstraint[] {
		const filtered = _.filter(this.validationConstraints, {
			model: this.model,
			property: this.property,
		});
		return filtered;
	}
}
