import { Component, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import { PaginationService } from '../pagination.service';
import { Subscription } from 'rxjs';
import { PaginationConsumer } from '../pagination-consumer';

@Component({
	selector: 'ws-page-selector',
	templateUrl: './page-selector.component.html',
	styleUrls: ['./page-selector.component.scss'],
})
export class PageSelectorComponent extends PaginationConsumer implements OnInit {
	@Input() maxPages: number = 10;

	public displayPages!: number[];
	public currentPage!: number;

	private pagedSubscription!: Subscription;

	constructor(@Optional() paginationService: PaginationService) {
		super(paginationService);

		if (typeof this.currentPage === 'string') {
			this.currentPage = Number.parseInt(this.currentPage);
		}
	}

	public get totalPages(): number {
		return this.paginationService.totalPages;
	}

	ngOnInit() {
		const self = this;
		this.pagedSubscription = this.paginationService.paged$.subscribe((event) => {
			// reset this column's sort direction to hide the sort icons
			self.currentPage = event.page as unknown as number;
			this.displayPages = self.generatePageProximity(self.currentPage, this.maxPages);
		});
		this.displayPages = this.generatePageProximity(this.currentPage, this.maxPages);

		this.currentPage = this.paginationService.currentPage;
	}

	increment() {
		if (this.paginationService.totalPages > Number.parseInt(this.currentPage.toString()) + 1) {
			this.currentPage++;
			this.selectPage(this.currentPage);
		}
	}

	decrement() {
		if (this.currentPage > 0) {
			this.currentPage--;
			this.selectPage(this.currentPage);
		}
	}

	selectPage(index: number) {
		this.currentPage = index;

		this.paginationService.paged({ page: this.currentPage });
	}

	// Page limit cuts below current page
	pageLimitUnderflow(): boolean {
		return (
			!!this.displayPages &&
			Array.isArray(this.displayPages) &&
			!!this.displayPages.length &&
			this.displayPages[0] > 0
		);
	}

	// Page limit cuts above current page
	pageLimitOverflow(): boolean {
		return (
			!!this.displayPages &&
			Array.isArray(this.displayPages) &&
			!!this.displayPages.length &&
			this.displayPages[this.displayPages.length - 1] < this.totalPages - 1
		);
	}

	private generatePageProximity(currentPage: number = 0, max: number): Array<number> {
		if (this.paginationService.totalPages === 0) {
			return [0];
		}

		let start = 0;
		let end = this.totalPages;
		if (typeof max === 'number' && max > 0) {
			const halfMax = max / 2;
			start = currentPage - Math.floor(halfMax);
			end = currentPage + Math.ceil(halfMax);
			if (start < 0) {
				end += -start;
				start = 0;
			}
		}

		if (end > this.paginationService.totalPages) {
			let cut = -1 * (this.paginationService.totalPages - end);
			end = this.paginationService.totalPages;
			start = cut < start ? start - cut : 0;
		}
		const pages = [];
		for (let i = start; i < end; i++) {
			pages.push(i);
		}

		return pages;
	}
}
