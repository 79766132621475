import { ChangeDetectorRef, Component, Injector } from '@angular/core';
import { ExpansionService } from '../../expansion.service';
import { ExpanderComponent } from '../expander/expander.component';
import { ExpansionInstance } from '../../types/expansion-instance';

@Component({
	selector: 'ws-tab-expander',
	templateUrl: './tab-expander.component.html',
	styleUrls: ['./tab-expander.component.scss'],
})
export class TabExpanderComponent<
	COMPONENT_INTERFACE extends Record<string, any> = Record<string, any>
> extends ExpanderComponent {
	public activeTab?: string;

	constructor(expansionService: ExpansionService, cdr: ChangeDetectorRef) {
		super(expansionService, cdr);
	}

	public get refs() {
		return this.cluster?.instances.filter((i) => !i?.ref?.instance?.['hideTab']?.());
	}

	public setActive(id: string) {
		const _this = this;

		const previousActive = this.refs.find((ini) => ini.id === _this.activeTab);
		// @ts-ignore
		this.removeCssClassOnClusterInstance(previousActive, 'active');

		this.activeTab = id;

		const newActive = this.refs.find((ini) => ini.id === id);
		// @ts-ignore
		this.setCssClassOnClusterInstance(newActive, 'active');
	}

	ngAfterViewInit() {
		if (!this.styleClass) {
			this.styleClass = 'tab-expansion';
		}

		super.ngAfterViewInit();

		if (!!this.cluster.instances.length) {
			this.activeTab = this.cluster.instances[0].id;
			// @ts-ignore
			this.setCssClassOnClusterInstance(this.cluster.instances[0], 'active');
		}

		this.cdr.detectChanges();
	}

	protected setCssClassOnClusterInstance(instance: ExpansionInstance<COMPONENT_INTERFACE>, cssClass: string) {
		// @ts-ignore
		instance.ref?.hostView?.rootNodes[0].classList.add(cssClass);
	}

	protected removeCssClassOnClusterInstance(instance: ExpansionInstance<COMPONENT_INTERFACE>, cssClass: string) {
		// @ts-ignore
		instance.ref?.hostView?.rootNodes[0].classList.remove(cssClass);
	}
}
