<mat-checkbox [ngClass]="labelClass" *ngIf="checkbox">
	{{ label }}
</mat-checkbox>
<label *ngIf="!checkbox" [ngClass]="labelClass">{{ label }}</label
><span *ngIf="required">*</span>
<mat-form-field>
	<input
		appRestrict
		restrict="[0-9]"
		matInput
		[(ngModel)]="hours"
		(change)="calculateResult()"
		id="{{ label }}_hours"
		placeholder="HH"
		[disabled]="disabled"
		maxlength="2"
		appMouseWheel
		(mouseWheelUp)="decreaseHours()"
		(mouseWheelDown)="increaseHours()"
	/>
</mat-form-field>
<span class="colon">:</span>
<mat-form-field>
	<input
		matInput
		appRestrict
		restrict="[0-9]"
		[(ngModel)]="minutes"
		(change)="calculateResult()"
		id="{{ label }}_minutes"
		placeholder="MM"
		maxlength="2"
		[disabled]="disabled"
		appMouseWheel
		(mouseWheelUp)="decreaseMinutes()"
		(mouseWheelDown)="increaseMinutes()"
	/>
</mat-form-field>
