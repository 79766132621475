//import DOMPurify from 'dompurify';

export const SCROLLBAR_WIDTH = (function () {
	const scrollDiv = document.createElement('div');
	scrollDiv.style.width = '100px';
	scrollDiv.style.height = '100px';
	scrollDiv.style.overflow = 'scroll';
	scrollDiv.style.position = 'fixed';
	scrollDiv.style.top = '-99999px';
	document.body.appendChild(scrollDiv);
	// Get the scrollbar width
	let scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;

	// Delete the DIV
	document.body.removeChild(scrollDiv);

	return scrollbarWidth;
})();

function _deepCopy(obj: any, clone?: any) {
	if (clone === undefined) {
		clone = {};
	}

	if (obj === undefined || obj === null || typeof obj !== 'object') {
		return obj;
	}

	for (let att in obj) {
		if (obj.hasOwnProperty(att)) {
			clone[att] = obj[att];
		}
	}

	return clone;
}

export function deepObjectAssign<IN>(target: object, ...sources: Array<object>): IN {
	// From npm package object-deep-assign
	// @ts-ignore
	function getTypeOf(input) {
		if (input === null) {
			return 'null';
		} else if (typeof input === 'undefined') {
			return 'undefined';
		} else if (typeof input === 'object') {
			return Array.isArray(input) ? 'array' : 'object';
		}

		return typeof input;
	}

	/*
	 * Branching logic which calls the correct function to clone the given value base on its type.
	 */ // @ts-ignore
	function cloneValue(value) {
		// The value is an object so lets clone it.
		if (getTypeOf(value) === 'object') {
			return quickCloneObject(value);
		}

		// The value is an array so lets clone it.
		else if (getTypeOf(value) === 'array') {
			return quickCloneArray(value);
		}

		// Any other value can just be copied.
		return value;
	}

	/*
	 * Enumerates the given array and returns a new array, with each of its values cloned (i.e. references broken).
	 */ // @ts-ignore
	function quickCloneArray(input) {
		return input.map(cloneValue);
	}

	/*
	 * Enumerates the properties of the given object (ignoring the prototype chain) and returns a new object, with each of
	 * its values cloned (i.e. references broken).
	 */ // @ts-ignore
	function quickCloneObject(input) {
		const output = {};

		for (const key in input) {
			if (!input.hasOwnProperty(key)) {
				continue;
			}

			// @ts-ignore
			output[key] = cloneValue(input[key]);
		}

		return output;
	}

	/*
	 * Does the actual deep merging.
	 */ // @ts-ignore
	function executeDeepMerge(target, _objects = [], _options: { arrayBehaviour?: any } = {}) {
		const options = {
			arrayBehaviour: _options.arrayBehaviour || 'replace', // Can be "merge" or "replace".
		};

		// Ensure we have actual objects for each.
		const objects = _objects.map((object) => object || {});
		const output = target || {};

		// Enumerate the objects and their keys.
		for (let oindex = 0; oindex < objects.length; oindex++) {
			const object = objects[oindex];
			const keys = Object.keys(object);

			for (let kindex = 0; kindex < keys.length; kindex++) {
				const key = keys[kindex];
				const value = object[key];
				const type = getTypeOf(value);
				const existingValueType = getTypeOf(output[key]);

				if (type === 'object') {
					if (existingValueType !== 'undefined') {
						const existingValue = existingValueType === 'object' ? output[key] : {};
						// @ts-ignore
						output[key] = executeDeepMerge({}, [existingValue, quickCloneObject(value)], options);
					} else {
						output[key] = quickCloneObject(value);
					}
				} else if (type === 'array') {
					if (existingValueType === 'array') {
						const newValue = quickCloneArray(value);
						output[key] = options.arrayBehaviour === 'merge' ? output[key].concat(newValue) : newValue;
					} else {
						output[key] = quickCloneArray(value);
					}
				} else {
					output[key] = value;
				}
			}
		}

		return output;
	}

	// @ts-ignore
	return executeDeepMerge(target, sources);
}

export function deepCopy(obj: object) {
	return _deepCopy(obj);
}

export function parseJwt(token: string): Record<string, string> {
	let jsonPayload;
	try {
		const base64Url = token.split('.')[1];
		const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
		jsonPayload = Object.freeze(
			decodeURIComponent(
				atob(base64)
					.split('')
					.map(function (c) {
						return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
					})
					.join('')
			)
		);
	} catch (e) {
		console.error('Error while parsing JWToken.');
		console.error(e.message.toString());
	}

	// @ts-ignore
	return JSON.parse(jsonPayload);
}

export function sortArrayOfObjectsByProperty(inputArr: Array<any>, property: string) {
	let arr = [...inputArr];
	let result = [];
	while (arr.length != 0) {
		let val = {};
		let index = 0;
		val = arr[0][property];
		for (let j = 1; j < arr.length; j++) {
			if (val > arr[j][property]) {
				val = arr[j][property];
				index = j;
			}
		}
		result.push(arr.find((x) => x[property] == val));
		arr.splice(index, 1);
	}
	return result;
}

/*export function saneHTML(rawHTML: string, scopeStyle?: boolean) {
	return { __html: DOMPurify.sanitize(rawHTML, { FORCE_BODY: true }) };
}*/

export function validateEmail(email: string) {
	let re =
		/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase());
}

// for arrays with objects
// @ts-ignore
export function findElementWithProperty(arr, key, value) {
	if (!Array.isArray(arr)) {
		console.error("Type of first argument is not 'Array'");
		return;
	}

	if (typeof key.toLowerCase() !== 'string') {
		console.error("Type of key is not 'String'");
		return;
	}

	let result = undefined;

	for (let obj of arr) {
		if (obj[key] === value) {
			result = obj;
		}
	}

	return result;
}

// Local Storage

export enum StoredType {
	string,
	number,
	object,
	boolean,
}

export class LocalStorageField<T> {
	// @ts-ignore
	private _val: T;
	private readonly _name: string;

	constructor(name: string, type: StoredType = StoredType.string, fallback?: any) {
		this._name = name;

		let applyFallback = () => {
			let val = fallback;
			if (typeof fallback === 'function') {
				val = fallback();
			}

			this.storeValue(val);
		};

		let storedVal = localStorage.getItem(this._name);
		if (storedVal === undefined || storedVal === null) {
			applyFallback();

			return;
		}

		switch (type) {
			case StoredType.boolean: {
				if (storedVal === 'true') {
					this.storeValue(true as unknown as T);
				} else if (storedVal === 'false') {
					this.storeValue(false as unknown as T);
				}
				applyFallback();
				break;
			}
			case StoredType.number: {
				try {
					this.storeValue(parseInt(storedVal, 10) as unknown as T);
				} catch (e) {
					applyFallback();
				}
				break;
			}
			case StoredType.string: {
				this.storeValue(storedVal as unknown as T);
				break;
			}
			case StoredType.object: {
				try {
					this.storeValue(JSON.parse(storedVal));
				} catch (e) {
					applyFallback();
				}
				break;
			}
		}
	}

	private storeValue(val: any) {
		this._val = val;

		if (val === undefined || val === null) {
			return;
		}

		switch (typeof val) {
			case 'object': {
				val = JSON.stringify(val);
				break;
			}
			case 'string': {
				break;
			}
			default: {
				val = val.toString();
				break;
			}
		}

		localStorage.setItem(this._name, val);
	}

	public get getValue(): T {
		return this._val;
	}

	public set setValue(value: T) {
		this._val = value;
		this.storeValue(value);
	}
}

export const generateQueryStringFromObject = (obj: any): string => {
	if (!obj) {
		return '';
	}

	let queryString = '';
	let first = true;

	for (let key of Object.keys(obj)) {
		if (obj[key]) {
			for (let value of obj[key]) {
				if (value) {
					if (first) {
						queryString += '?' + key + '=' + value;
						first = false;
					} else {
						queryString += '&' + key + '=' + value;
					}
				}
			}
		}
	}

	return queryString;
};

export function dateToDateString(date: Date) {
	return date.toJSON();
}

export function mergeArraysAt(array1: Array<any>, array2: Array<any>, index: number = 0): Array<any> {
	return array1.slice(0, index).concat(array2, array1.slice(index));
}

export function is(obj: any): boolean {
	return obj !== null && obj !== undefined;
}

export function isNotEmpty(obj: object | string): boolean {
	if (is(obj) && typeof obj === 'object') {
		if (Array.isArray(obj)) {
			return obj.length > 0;
		} else {
			return Object.keys(obj).length > 0;
		}
	}
	if (is(obj) && typeof obj === 'string') {
		return obj.length > 0;
	}

	return false;
}

export function getFormat(file: string): string {
	let pattern = /\..+$/;
	let format = file.match(pattern);

	if (!format) {
		return '';
	} else {
		let f = format[0].replace('.', '');
		return f;
	}
}

export function nTimes(n: number): Array<number> {
	const result = [];

	for (let i = 0; i < n; i++) {
		result.push(i);
	}

	return result;
}

export function deleteSubObject(obj: Record<string, object>, path: string) {
	const keys = path.split('.');
	let current: Record<string, object> = obj;

	for (let i = 0; i < keys.length - 1; i++) {
		const key = keys[i];

		if (current[key] === undefined) {
			return; // Path doesn't exist, nothing to delete
		}

		if (Array.isArray(current[key])) {
			const arrayKeyRegex = /(\w+)\[(\d+)\]/g;
			const match = arrayKeyRegex.exec(key);

			if (match) {
				const arrayName = match[1];
				const index = Number(match[2]);

				if (current[arrayName] === undefined || !Array.isArray(current[arrayName])) {
					return; // Invalid array reference
				}

				// @ts-ignore
				if (current[arrayName][index] === undefined) {
					return; // Index out of bounds, nothing to delete
				}

				// @ts-ignore
				current = current[arrayName][index];
			} else {
				return; // Invalid array reference
			}
		} else {
			// @ts-ignore
			current = current[key];

			if (current === undefined || typeof current !== 'object') {
				return; // Path doesn't exist or not an object, nothing to delete
			}
		}
	}

	const lastKey = keys[keys.length - 1];

	if (Array.isArray(current) && /\w+\[\d+\]/.test(lastKey)) {
		const arrayKeyRegex = /(\w+)\[(\d+)\]/g;
		const match = arrayKeyRegex.exec(lastKey);

		if (match) {
			const arrayName = match[1];
			const index = Number(match[2]);

			if (current[arrayName] === undefined || !Array.isArray(current[arrayName])) {
				return; // Invalid array reference
			}

			// @ts-ignore
			current[arrayName].splice(index, 1);
		} else {
			return; // Invalid array reference
		}
	} else {
		delete current[lastKey];
	}
}
