import { Component, Host, OnInit, Optional } from '@angular/core';
import { PagedEvent, PaginationService } from '../pagination.service';
import { Subscription } from 'rxjs';
import { PaginationConsumer } from '../pagination-consumer';

@Component({
	selector: 'ws-page-size-selector',
	templateUrl: './page-size-selector.component.html',
	styleUrls: ['./page-size-selector.component.scss'],
})
export class PageSizeSelectorComponent extends PaginationConsumer implements OnInit {
	public size = 10;
	private pagedSubscription!: Subscription;

	constructor(@Optional() paginationService: PaginationService) {
		super(paginationService);
	}

	ngOnInit() {
		const self = this;
		this.pagedSubscription = this.paginationService.paged$.subscribe((event) => {
			// reset this column's sort direction to hide the sort icons
			self.size = Number.parseInt(event.size as unknown as string) || self.size;
		});
	}

	onSelect() {
		this.paginationService.paged({ page: 0, size: this.size });
	}
}
