import {
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
} from '@angular/core';
import { FloatLabelType, MatFormFieldAppearance } from '@angular/material/form-field';
import { ValidationConstraint } from '../../domain/validation-constraint';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UtilService } from '../../services/util.service';
import * as _ from 'lodash';
import { Tooltip } from '../../domain/tooltip';

@Component({
	selector: 'ws-autocomplete',
	templateUrl: './autocomplete.component.html',
	styleUrls: ['./autocomplete.component.scss'],
})
export class AutocompleteComponent implements OnInit, OnChanges {
	@Input() options!: string[];
	@Input() labelClass!: string;
	@Input() placeholder!: string;
	@Input() required!: boolean;
	@Input() label!: string;
	@Input() tooltips!: Tooltip[];
	@Input() tooltipKey!: string;
	@Input() name!: string;
	@Input() value!: string;
	@Input() appearance!: MatFormFieldAppearance;
	@Input() styleClass!: string;

	@Input() tabIndex!: number;
	@Input() errors!: object;
	@Input() modelPath!: string;
	@Input() validationConstraints!: ValidationConstraint[];
	@Input() floatLabel!: FloatLabelType;
	public error!: string;
	public fieldValidationConstraints!: ValidationConstraint[];

	@Output() valueChange = new EventEmitter();
	formGroup: FormGroup;

	constructor(private cd: ChangeDetectorRef, private formBuilder: FormBuilder) {
		this.formGroup = this.formBuilder.group({});
		this.name = this.name || 'control';
		const validators = [];
		if (this.required) {
			validators.push(Validators.required);
		}
		this.formGroup.addControl(this.name, new FormControl(this.value, validators));
		this.cd.markForCheck();
	}

	ngOnInit(): void {
		this.error = UtilService.getObjByPath(this.errors, this.modelPath);
		if (this.error) {
			this.formGroup.controls[this.name].markAsTouched();
			this.formGroup.controls[this.name].setErrors({ required: true });
		}
		this.updataValidationConstraints();
		this.cd.markForCheck();
		this.formGroup.controls[this.name].valueChanges.subscribe((value) => {
			this.valueChange.emit(value);
		});
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.errors !== undefined) {
			this.error = UtilService.getObjByPath(this.errors, this.modelPath);
			if (this.error) {
				this.formGroup.controls[this.name].markAsTouched();
				this.formGroup.controls[this.name].setErrors({ required: true });
				this.cd.markForCheck();
			}
		}
		if (!this.error) {
			this.formGroup.controls[this.name].markAsUntouched();
			this.cd.markForCheck();
		}

		if (changes.validationConstraints) {
			this.updataValidationConstraints();
			this.cd.markForCheck();
		}

		if (changes.options && changes.options.currentValue && changes.options.currentValue.length > 0) {
			/* this.filteredOptions = this.formGroup.controls[this.name].valueChanges.pipe(
                startWith(this.value),
                map(value => this._filter(value))
            );*/
			this.cd.markForCheck();
		}

		if (changes.value) {
			this.formGroup.controls[this.name].setValue(changes.value.currentValue);
			this.cd.markForCheck();
		}
	}

	private updataValidationConstraints() {
		if (this.validationConstraints !== null && this.modelPath) {
			this.fieldValidationConstraints = _.filter(this.validationConstraints, ['field', this.modelPath]);
			this.cd.markForCheck();
		}
	}
}
