<mat-form-field>
	<!--<mat-label>{{ label }}</mat-label>
    <input matInput [ngxMatDatetimePicker]="picker" placeholder="Choose a date" [(ngModel)]="result"
           (ngModelChange)="onResultChange($event)"
           [min]="minDate" [max]="maxDate" [disabled]="disabled">
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <ngx-mat-datetime-picker #picker [showSpinners]="showSpinners" [showSeconds]="showSeconds"
                             [stepHour]="stepHour" [stepMinute]="stepMinute" [stepSecond]="stepSecond"
                             [color]="color" [enableMeridian]="enableMeridian"
                             [disableMinute]="disableMinute" [hideTime]="hideTime" [defaultTime]="defaultTime">
    </ngx-mat-datetime-picker>-->
</mat-form-field>
