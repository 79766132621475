import { HttpResponse } from './HttpResponse';
import { ResourceError } from './ResourceError';
import {
	CancelablePromise,
	CancelHandle,
	GET,
	IBundleOptions,
	IInterceptor,
	IResourceOptions,
	IsMatchHeader,
	MatchHeaderParams,
	RestHeader,
	RestMethod,
	RestParams,
	UnsetMatchHeader,
} from './RestResource';

/**
 * docu
 */
export class RestBundle {
	// TODO possibly rewrite this class to use httpclient instead of fetch

	private static _globalInterceptors: Array<IInterceptor> = [];

	public static GlobalInterceptors(interceptors: Array<IInterceptor>): void {
		RestBundle._globalInterceptors = interceptors;
	}

	public static rq<M>(
		url: string,
		fetchOptions: IFetchOptions,
		resourceName: string,
		bundleName: string,
		cleanupFunc: () => void,
		timoutMilliseconds?: number
	): CancelablePromise<HttpResponse<M>> {
		const routedURL = url;

		const abortController = createAbortController();
		fetchOptions.signal = abortController.signal;
		const timoutController = new TimoutController();

		const promise = Promise.race<HttpResponse<M>>([
			new Promise<HttpResponse<M>>(function (resolve, reject) {
				fetch(routedURL, fetchOptions).then(
					async (response: Response) => {
						timoutController.abort();

						const resolvedResponse = await RestBundle.responseFactory<M>(response);

						// Execute interceptors
						try {
							if (!!fetchOptions.interceptors && Array.isArray(fetchOptions.interceptors)) {
								for (const interceptor of fetchOptions.interceptors) {
									if (typeof interceptor === 'function') {
										// @ts-ignore
										interceptor(resolvedResponse, resolve, reject);
									}
								}
							}
						} catch (e) {
							console.log('Error while applying interceptors.');
							console.log(e);
						}

						if (!response.ok) {
							reject(resolvedResponse);
						}
						resolve(resolvedResponse);
					},
					async (response: Response) => {
						timoutController.abort();
						// @ts-ignore, response might be an error when fetch was used incorrectly
						if (!!response && response.stack && response.message) {
							console.error(response);
						}

						const resolvedResponse = await RestBundle.responseFactory<M>(response);

						// Execute interceptors
						try {
							if (!!fetchOptions.interceptors && Array.isArray(fetchOptions.interceptors)) {
								for (const interceptor of fetchOptions.interceptors) {
									if (typeof interceptor === 'function') {
										// @ts-ignore
										interceptor(resolvedResponse, resolve, reject);
									}
								}
							}
						} catch (e) {
							console.error('Error while applying interceptors.');
							console.error(e);
						}

						if (!abortController.signal.aborted) {
							reject(resolvedResponse);
						}
					}
				);
			}),
			new Promise<HttpResponse<M>>((resolve, reject) => {
				if (timoutMilliseconds && typeof timoutMilliseconds === 'number') {
					timoutController.timer = setTimeout(() => {
						abortController.abort();
						reject(createTimoutResponse());
					}, timoutMilliseconds);
				}
			}),
		]).finally(() => {
			if (cleanupFunc && typeof cleanupFunc === 'function') {
				cleanupFunc();
			}

			Object.defineProperty(promise, 'cancel', {
				value: () => {},
			});
		});

		Object.defineProperty(promise, 'cancel', {
			value: () => {
				timoutController.abort();
				abortController.abort();
			},
			configurable: true,
		});

		Object.defineProperty(promise, 'name', {
			value: resourceName,
		});

		Object.defineProperty(promise, 'bundleName', {
			value: bundleName,
		});

		return promise as unknown as CancelablePromise<HttpResponse<M>>;
	}

	private static async responseFactory<M>(r: Response): Promise<HttpResponse<M>> {
		const res: any = {
			body: undefined,
			bodyUsed: r.bodyUsed,
			headers: r.headers,
			ok: r.ok,
			redirected: r.redirected,
			status: r.status,
			statusText: r.statusText,
			type: r.type,
			url: r.url,
			timedOut: false,
		};

		let plainText;
		if (typeof r.clone === 'function') {
			plainText = await r.clone().text();
		}

		if (typeof r.json === 'function') {
			try {
				res.body = await r.json();
			} catch (e) {
				if (plainText) {
					res.body = plainText;
				} else {
					console.warn(e);
					if (!!r && !!r.url) {
						console.warn(r.url);
					}
					res.body = undefined;
				}
			}
		}

		return Promise.resolve(res);
	}

	protected _options!: IBundleOptions;
	private _activeRequests!: Map<string, CancelHandle>;
	private static _authorization: string | null = null;

	constructor() {
		// Make the Class name the default bundle name
		// @ts-ignore
		if (!this.__proto__._options) {
			// @ts-ignore
			this.__proto__._options = { name: this.__proto__.constructor.name };
		}
	}

	public static setApiAuthorization(authorization: string) {
		RestBundle._authorization = authorization;
	}

	public async makeRequest<M>(
		options: IResourceOptions,
		params: RestParams,
		headers: RestHeader,
		body: Object,
		resourceName: string
	): Promise<HttpResponse<M> | ResourceError> {
		if (options === undefined || options === null) {
			throw new ResourceError(resourceName, this.bundleName, 'Resource is missing path');
		}

		if (!this._activeRequests) {
			this._activeRequests = new Map<string, CancelHandle>();
		}

		const [url, fetchOptions, timeoutMilliseconds] = await this.processOptions(
			options,
			params,
			headers,
			body,
			resourceName
		);

		if (!options.allowMultiple) {
			this.cancelPreviousRequest(resourceName);
		}

		return this.makeClientRequest(
			url,
			fetchOptions,
			resourceName,
			this.bundleName,
			// @ts-ignore
			options.allowMultiple,
			timeoutMilliseconds
		);

		// @ts-ignore
		return null;
	}

	public buildQueryPath(
		options: IResourceOptions,
		params: RestParams,
		resourceName: string,
		headers: RestHeader
	): string {
		let path: string | undefined;
		let query;

		if (options === undefined) {
			options = {};
		}

		if (!options.ignorePathAffixes) {
			// Apply bundle path prefix and suffix
			path =
				(this.options.pathPrefix ? this.options.pathPrefix : '') +
				options.path +
				(this.options.pathSuffix ? this.options.pathSuffix : '');
		} else {
			path = options.path;
		}

		const queryParams = this.buildParamsIncludingHeaders(params, headers);

		const pathVariables = options.path!.match(/{([A-z]|[0-9])+}/g);

		if (pathVariables === null) {
			if (options.path!.indexOf('{') > 0 || options.path!.indexOf('}') > 0) {
				throw new ResourceError(resourceName, this.bundleName, 'Invalid path variable name.');
			}
		} else {
			// Insert path variable values
			// Params that are used as path variables are removed from the regular query param stack
			pathVariables.forEach((v) => {
				const prop = v.slice(1, v.length - 1);
				if (queryParams[prop] === undefined) {
					throw new ResourceError(
						resourceName,
						this.bundleName,
						'Path variable is missing or undefined in rest parameters.'
					);
				}
				path = path!.replace(v, <string>queryParams[prop]);
				delete queryParams[prop];
			});
		}

		const finalParams = queryParams;

		// Stack query string
		if (Object.keys(finalParams).length > 0) {
			const queryParamsList = [];
			for (const p in finalParams) {
				if (finalParams.hasOwnProperty(p) && finalParams[p] !== undefined && finalParams[p] !== null) {
					if (
						typeof finalParams[p] === 'string' ||
						typeof finalParams[p] === 'number' ||
						typeof finalParams[p] === 'boolean'
					) {
						queryParamsList.push(p + '=' + finalParams[p].toString()); // Push string or number params as strings
					} else if (Array.isArray(finalParams[p]) && (finalParams[p] as Array<unknown>).length) {
						let arrayStack = '';
						let first = true;
						for (const e of finalParams[p] as Array<string | number>) {
							if (typeof e === 'string' || typeof e === 'number') {
								if (!first) {
									arrayStack += ',';
								} else {
									first = false;
								}
								arrayStack += e.toString();
							}
						}
						queryParamsList.push(p + '=' + arrayStack); // Arrays are concatenated to strings with dividing ','
					}
				}
			}
			if (queryParamsList.length) {
				query =
					'?' +
					queryParamsList.reduce((acc, val) => {
						if (acc !== undefined) {
							return acc + '&' + val;
						}

						return val;
					});
				path += query;
			}
		}

		return path!;
	}

	public async buildHeaders(options: IResourceOptions, headers: RestHeader = {}): Promise<RestHeader> {
		let result;

		// Merge headers
		let dynamicHeaders,
			dynamicBundleHeaders = {};
		if (typeof options.dynamicHeaders === 'function') {
			dynamicHeaders = await options.dynamicHeaders();
		}
		if (typeof this.options.dynamicHeaders === 'function') {
			dynamicBundleHeaders = await this.options.dynamicHeaders();
		}

		if (!options.ignoreBundleHeaders) {
			result = Object.assign(
				{},
				this.options.staticHeaders,
				dynamicBundleHeaders,
				options.staticHeaders,
				dynamicHeaders,
				headers
			);
		} else {
			result = Object.assign({}, options.staticHeaders, dynamicHeaders);
		}

		if (RestBundle._authorization) {
			result['Authorization'] = RestBundle._authorization;
		}

		return Promise.resolve(result);
	}

	private get bundleName(): string {
		return this._options.name;
	}

	private get options(): IBundleOptions {
		// @ts-ignore
		return this._options;
	}

	private cancelPreviousRequest(resourceName: string) {
		const previous = this._activeRequests.get(resourceName);
		if (previous) {
			previous.cancel();
		}
		this._activeRequests.delete(resourceName);
	}

	private buildParamsIncludingHeaders(params: RestParams, headers: RestHeader): RestParams {
		const result: RestParams = Object.assign({}, params);

		if ((result as unknown as MatchHeaderParams)[IsMatchHeader] === IsMatchHeader) {
			for (const field in result) {
				if ((result as MatchHeaderParams)[field] === UnsetMatchHeader) {
					if (!!headers[field]) {
						delete result[field];
						(result as MatchHeaderParams)[field] = headers[field];
					} else {
						delete result[field];
						console.warn(`Error while processing MatchHeaderParams. No such header '"${field}'`);
					}
				}
			}
			// @ts-ignore
			delete result[IsMatchHeader];
		}

		return result;
	}

	private buildBody(body: string | Object, headers: RestHeader, resourceName: string): string | FormData {
		// Stringify body or send as FormData
		try {
			if (body instanceof FormData) {
				// Content type will be decided automatically by the browser
				return body;
			} else {
				headers['Content-type'] = 'application/json;charset=UTF-8';
				return JSON.stringify(body);
			}
		} catch (e) {
			throw new ResourceError(resourceName, this.bundleName, 'Failed to process request body.' + e.message);
		}
	}

	private async processOptions(
		options: IResourceOptions,
		params: RestParams,
		headers: RestHeader,
		body: Object,
		resourceName: string
	): Promise<[string, IFetchOptions, number | undefined]> {
		if (!options.allowMultiple) {
			options.allowMultiple = false;
		}

		// Default request method
		if (!options.method) {
			options.method = GET;
		}

		const mergedHeaders = await this.buildHeaders(options, headers);

		const path = this.buildQueryPath(options, params, resourceName, mergedHeaders);

		const finalBody = this.buildBody(body, mergedHeaders, resourceName);

		// Timout setting
		let timoutMilliseconds;
		if (options.timeout && typeof options.timeout === 'number') {
			timoutMilliseconds = options.timeout;
		} else if (this._options.timeout && typeof this._options.timeout === 'number') {
			timoutMilliseconds = this._options.timeout;
		}

		// Merge interceptors, endpoint specific take precedence
		let interceptors: any[] = [];
		if (!!options.interceptors && Array.isArray(options.interceptors)) {
			interceptors = interceptors.concat(options.interceptors);
		}
		if (!!this._options.interceptors && Array.isArray(this._options.interceptors)) {
			interceptors = interceptors.concat(this._options.interceptors);
		}
		interceptors = interceptors.concat(RestBundle._globalInterceptors);

		return Promise.resolve([
			path,
			{ method: options.method, body: finalBody, headers: mergedHeaders, interceptors: interceptors },
			timoutMilliseconds,
		]);
	}

	private makeClientRequest<M>(
		url: string,
		options: IFetchOptions,
		resourceName: string,
		bundleName: string,
		allowMultiple: boolean,
		timoutMilliseconds?: number
	): Promise<HttpResponse<M>> {
		const _this = this;
		const cleanup = () => {
			_this._activeRequests.delete(resourceName);
		};

		if (!allowMultiple) {
			const handle = RestBundle.rq<M>(
				url,
				options,
				resourceName,
				bundleName,
				cleanup,
				timoutMilliseconds
			) as CancelablePromise<HttpResponse<M>>;
			this._activeRequests.set(resourceName, handle);
			return handle;
		}

		return RestBundle.rq<M>(url, options, resourceName, bundleName, cleanup, timoutMilliseconds);
	}
}

class TimoutController {
	private _timer: string | number | any | undefined;
	private _aborted = false;

	constructor() {}

	set timer(timer: any) {
		this._timer = timer;
	}

	get aborted() {
		return this._aborted;
	}

	abort() {
		if (!this._aborted && this._timer) {
			clearTimeout(this._timer);
		}
		this._aborted = true;
	}
}

function createAbortController() {
	if (typeof AbortController === 'function') {
		return new AbortController();
	} else {
		return {
			signal: null,
			abort() {},
		} as unknown as AbortController;
	}
}

function createTimoutResponse() {
	return {
		body: { timedOut: true },
		ok: false,
		status: 0,
		statusText: 'Request was timed out by issuer.',
	};
}

interface IFetchOptions {
	method: RestMethod;
	body: string | FormData;
	headers: RestHeader;
	signal?: AbortSignal;
	mode?: RequestMode;
	interceptors?: Array<IInterceptor>;
}
