import { Injectable } from '@angular/core';
import { RestBundle } from '../../http/RestBundle';
import { IRestFunc, POST, Rest, RestResource } from '../../http/RestResource';

@Injectable({
	providedIn: 'root',
})
@Rest({
	name: 'Authentication',
	pathPrefix: '/api/v1/auth',
})
export class AuthenticationResource extends RestBundle {
	@RestResource({
		path: '/token-auth',
		method: POST,
	})
	login!: IRestFunc<any, undefined, { username: string; password: string }>;
}
