import { AfterViewInit, Component, ElementRef, OnInit, Optional, TemplateRef, ViewChild } from '@angular/core';
import { Column, SmartTableService } from '../smart-table.service';
import { SmartTableConsumer } from '../smart-table-consumer';

@Component({
	selector: 'ws-column-selector',
	templateUrl: './column-selector.component.html',
	styleUrls: ['./column-selector.component.scss'],
})
export class ColumnSelectorComponent extends SmartTableConsumer implements OnInit, AfterViewInit {
	public columns: Column[] = [];
	public show = false;

	constructor(@Optional() smartTableService: SmartTableService) {
		super(smartTableService);
	}

	ngOnInit() {
		this.columns = this.smartTableService.columns;
	}

	ngAfterViewInit() {}

	openModal() {
		this.show = true;
	}

	onClickShade() {
		this.show = false;
	}

	onClickColumnCheck(column: Column) {
		if (column.hideable) {
			column.hide = !column.hide;
		}
	}
}
