import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControlComponent } from '../form-control/form-control.component';

@Component({
	selector: 'ws-checkbox',
	templateUrl: './checkbox.component.html',
	styleUrls: ['./checkbox.component.scss'],
})
export class CheckboxComponent extends FormControlComponent implements OnInit {
	@Input() labelPosition: any;
	@Input() value!: string;
	@Input() color: any;
	@Output() toggled = new EventEmitter<boolean>();

	constructor() {
		super();
	}

	ngOnInit(): void {
		super.ngOnInit();
	}

	get checked() {
		return this.result;
	}

	toggle() {
		this.toggled.emit(this.checked);
	}
}
