import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
	selector: 'ws-date-time-picker',
	templateUrl: './date-time-picker.component.html',
	styleUrls: ['./date-time-picker.component.scss'],
})
export class DateTimePickerComponent implements OnInit {
	@Input() minDate: any;
	@Input() maxDate: any;
	@Input() disabled!: boolean;
	@Input() result: any;
	@Input() showSpinners!: boolean;
	@Input() showSeconds!: boolean;
	@Input() stepHour!: number;
	@Input() stepMinute!: number;
	@Input() stepSecond!: number;
	@Input() color: any;
	@Input() enableMeridian!: boolean;
	@Input() disableMinute!: boolean;
	@Input() hideTime!: boolean;
	@Input() label!: string;
	@Output() resultChange = new EventEmitter<any>();

	public defaultTime: any;

	constructor() {}

	ngOnInit(): void {
		this.disabled = this.disabled === undefined ? false : this.disabled;
		this.showSeconds = this.showSeconds === undefined ? false : this.showSeconds;
		this.stepHour = this.stepMinute = this.stepSecond = 1;
		this.defaultTime = [0, 0, 0];
		this.color = 'primary';
	}

	onResultChange($event: any) {
		this.resultChange.emit($event);
	}
}
