<ng-container ngForm #frm="ngForm">
	<mat-form-field class="w-100" [ngClass]="inputClass" [floatLabel]="floatLabel" [appearance]="appearance">
		<mat-label *ngIf="label" [ngClass]="labelClass">{{ label }}<span *ngIf="required">*</span></mat-label>
		<mat-select
			[id]="id"
			[multiple]="multiple"
			[placeholder]="placeholder"
			[disabled]="disabled || readonly"
			[(ngModel)]="result"
			(selectionChange)="onSelectionChanged($event)"
			[name]="name"
			[panelClass]="panelClass"
			[compareWith]="compareWith"
			[appCustomPatternValidator]="fieldValidationConstraints"
		>
			<mat-option
				*ngFor="let option of options | minMaxFilter: value:minValue:maxValue"
				[value]="getProperty(option, value)"
			>
				{{
					getProperty(option, display) !== undefined
						? getProperty(option, display, optionTranslate)
						: getProperty(option, value)
				}}
			</mat-option>
		</mat-select>
		<mat-hint *ngIf="hint">{{ hint }}</mat-hint>
		<mat-error>
			<ws-field-error
				[error]="error"
				[interpolationParams]="errorInterpolationParams"
				[control]="frm.controls[name]"
			></ws-field-error>
		</mat-error>
	</mat-form-field>
</ng-container>
