import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ShopService } from '../shop/services/shop.service';
import { TenantService } from 'ws-framework';

@Pipe({
	name: 'shopCurrency',
	pure: false,
})
export class ShopCurrencyPipe implements PipeTransform {
	private currency: string | null = null;
	private loading = false;
	constructor(
		private translateService: TranslateService,
		private shopService: ShopService,
		private tenantService: TenantService
	) {}

	transform(value: any, args?: any): any {
		if (!value) {
			return '';
		}

		if (!this.loading && !this.currency && !args?.currency) {
			this.loading = true;
			const tenantId = this.tenantService.tenantId;
			this.shopService.fetchShopConfig(tenantId!).then((config) => {
				this.currency = this.shopService.shopConfig?.currency.iso;
				this.loading = false;
			});
		}

		if (!(typeof value === 'number') && !isNaN(Number(value))) {
			value = Number(value);
		}

		if (typeof value === 'number') {
			const locale = this.isoLocale(args?.locale || this.translateService.currentLang || 'de');
			return value.toLocaleString(locale, {
				style: 'currency',
				currency: args?.currency || this.currency || 'EUR',
			});
		}
		return value;
	}

	private isoLocale(locale: string) {
		switch (locale) {
			case 'de':
				return 'de-DE';
			case 'en':
				return 'en-US';
			case 'fr':
				return 'fr-FR';
			default:
				return locale;
		}
	}
}
