import { EventEmitter, Injectable, PipeTransform } from '@angular/core';
import { Subject } from 'rxjs';
import { PaginationService } from '../pagination/pagination.service';
import { defaultValuePipe } from '../types/default-value-pipe';
import { IndexCheckbox } from './types/index-checkbox';
import { CheckboxComponent } from '../controls/checkbox/checkbox.component';

@Injectable()
export abstract class SmartTableService extends PaginationService {
	private _columnSortedSource = new Subject<ColumnSortedEvent>();
	private _columnFilteredSource = new Subject<ColumnSortedEvent>();
	private _columnToggledSource = new Subject<any>();
	private _columnCountChanged = new Subject<number>();

	private _columns: Array<Column> = [];
	//@SessionStorageObj TODO add Proxies or smth to session storage to enable proper updates for object
	private _criteria!: ColumnSortedEvent;
	// @ts-ignore
	private columnPresets: { id: string; columns: number[] }[];

	protected _indexCheckboxes: IndexCheckbox[] = [];
	protected _loaded$: EventEmitter<any> = new EventEmitter<any>();

	protected constructor() {
		super();
		if (!this._criteria) {
			this._criteria = { sortCols: [] };
		}
	}

	public get columnSorted$() {
		return this._columnSortedSource.asObservable();
	}
	public get columnFiltered$() {
		return this._columnFilteredSource.asObservable();
	}
	public get columnToggled$() {
		return this._columnToggledSource.asObservable();
	}
	public get columnCountChanged$() {
		return this._columnCountChanged.asObservable();
	}

	public get indexCheckboxes(): readonly IndexCheckbox[] {
		return Object.freeze(this._indexCheckboxes.map((indexCheckbox) => indexCheckbox));
	}

	public init() {
		super.init();
		// apply defaults
	}

	public checkAll(checked: boolean) {
		this._indexCheckboxes.forEach((checkbox) => (checkbox.checkbox.result = checked));
	}

	public pushCheckbox(indexCheckbox: IndexCheckbox) {
		const registeredCheckboxesIndexes = this._indexCheckboxes.map(({ index }) => index);

		if (registeredCheckboxesIndexes.includes(indexCheckbox?.index)) {
			this.popIndexCheckbox(indexCheckbox.index);
		}
		this._indexCheckboxes.push(indexCheckbox);
	}

	public clearCheckboxes() {
		this._indexCheckboxes = [];
	}

	public popIndexCheckbox(indexOf: number) {
		const filteredIndexCheckboxes = this._indexCheckboxes.filter(({ index }) => index !== indexOf);
		if (filteredIndexCheckboxes.length !== this._indexCheckboxes.length) {
			this._indexCheckboxes = filteredIndexCheckboxes;
			return true;
		}
		return false;
	}

	public updateCheckbox(index: number, checkbox: CheckboxComponent) {
		this._indexCheckboxes[index].checkbox = checkbox;
	}

	public get ASCENDING() {
		return 'ASC';
	}

	public get DESCENDING() {
		return 'DESC';
	}

	public get NONE() {
		return 'NONE';
	}

	public get DEFAULT_DIR() {
		return this.ASCENDING;
	}

	public criteriaToFilters(criteria: ColumnSortedEvent): Criterion[] {
		let res: Criterion[] = [];

		for (const cc of criteria.sortCols) {
			if (cc.filterKey && cc.filterKey.length > 0) {
				res.push({ filter: cc.id, value: cc.filterKey });
			}
			if (cc.dir && (cc.dir === this.ASCENDING || cc.dir === this.DESCENDING)) {
				res.push({ order: cc.id, value: cc.dir });
			}
		}

		return res;
	}

	public get columns(): Column[] {
		return this._columns;
	}

	public get visibleColumns(): Column[] {
		return this._columns.filter((col) => !col.hide);
	}

	public getCriteriaAsFilters(): Criterion[] {
		return this.criteriaToFilters(this._criteria);
	}

	public getParameters() {
		return {
			criteria: this.criteriaToFilters(this._criteria),
			pagination: this.pagination,
		};
	}

	public columnSorted(event: ColumnSortedEvent) {
		let clearedCriteria = [];

		for (const ec of this._criteria.sortCols) {
			if (this._columns.find((col) => col.field === ec.id)) {
				clearedCriteria.push(ec);
			} else if (ec.filterKey !== undefined) {
				// @ts-ignore
				ec.dir = undefined;
				clearedCriteria.push(ec);
			}
		}

		this._criteria.sortCols = clearedCriteria;

		for (const ec of event.sortCols) {
			let found = false;
			for (const c of this._criteria.sortCols) {
				if (c.id === ec.id) {
					c.dir = ec.dir;
					found = true;
				}
			}
			if (!found) {
				this._criteria.sortCols.push(ec);
			}
		}

		this._columnSortedSource.next(event);
	}

	public columnFiltered(event: ColumnSortedEvent) {
		if (event.sortCols.length === 0 || (event.sortCols.length > 0 && event.sortCols[0].filterKey === undefined)) {
			return;
		}
		for (const ec of event.sortCols) {
			let found = false;
			for (const c of this._criteria.sortCols) {
				if (c.id === ec.id) {
					c.filterKey = ec.filterKey;
					found = true;
				}
			}
			if (!found) {
				this._criteria.sortCols.push(ec);
			}
		}
		this.goToFirstPage();
		this._columnFilteredSource.next(event);
	}

	// @ts-ignore
	public sortByCriteria(criteria: ColumnSortedEvent, data) {
		// @ts-ignore
		return data.sort((a, b) => {
			return this.cascadeCriteria(a, b, criteria);
		});
	}

	public registerColumn(
		label: string,
		field?: string,
		transformer?: ValueTransformer,
		options?: {
			hideable?: boolean;
			hide?: boolean;
			stackable?: boolean;
			searchable?: boolean;
		}
	) {
		let _field = field;
		if (label.length > 1) {
			_field = field || label.trim()[0].toLowerCase() + label.trim().slice(1).replace(' ', '');
		} else {
			_field = label.trim().toLowerCase();
		}

		if (!!transformer) {
			this.setDefaultValuePipes(transformer);
		}

		this._columns.push(
			Object.assign(
				{
					hide: false,
					hideable: false,
					stackable: false,
					searchable: true,
				},
				options,
				{
					label,
					field: _field,
					valueTransformer: transformer ?? { field: _field, transformer: defaultValuePipe },
				}
			)
		);
		this._columnCountChanged.next(this._columns.length);
	}

	public registerColumnPreset(id: string, columns: string[]) {
		// TODO refactor for new column type
		/*if (!this.columnPresets) {
          this.columnPresets = [];
        }
        let preset = { id: id, columns: [] };
        let exists = false;
        for (let p of this.columnPresets) {
          if (p.id === id) {
            // @ts-ignore
            preset = p;
            exists = true;
          }
        }
        for (let col of columns) {
          for (let i = 0; i < this._columnTags.length; i++) {
            if (this._columnTags[i] === col || this._columnFields[i] === col) {
              // @ts-ignore
              preset.columns.push(i);
            }
          }
        }
        if (!exists) {
          this.columnPresets.push(preset);
        }*/
	}

	public applyColumnPreset(id: string) {
		// TODO refactor for new column type
		/*for (let i = 0; i < this._columnHide.length; i++) {
          this._columnHide[i] = true;
        }
        for (let p of this.columnPresets) {
          if (p.id === id ) {
            for (let col of p.columns) {
              if (this._columnHide.length > col) {
                this._columnHide[col] = false;
              }
            }
            return;
          }
        }*/
	}

	public isStackable(event: ColumnSortedEvent): boolean {
		let res: any = null;
		for (const sortCol of event.sortCols) {
			res = this._columns.find((col) => col.field === sortCol.id) || {};
			if (!!res && res.stackable) {
				return true;
			}
		}
		return res;
	}

	public flush() {
		super.flush();
		this._criteria = { sortCols: [] };
	}

	public flushColumns() {
		this._columns = [];
		this.columnPresets = [];
	}

	private setDefaultValuePipes(valueTransformer: ValueTransformer) {
		if (!valueTransformer.transformer) {
			valueTransformer.transformer = defaultValuePipe;
		}

		if (!!valueTransformer.dependentTransformer) {
			this.setDefaultValuePipes(valueTransformer.dependentTransformer);
		}

		if (!!valueTransformer.argResolver) {
			this.setDefaultValuePipes(valueTransformer.argResolver);
		}
	}

	private goToFirstPage() {
		this.config({
			currentPage: 0,
			pageSize: this.pageSize,
			totalPages: this.totalPages,
			datasetSize: this.dataSize,
		});
	}

	// @ts-ignore
	private cascadeCriteria(a, b, criteria: ColumnSortedEvent) {
		let greaterThan = -1;
		for (let i = 0; i < criteria.sortCols.length; i++) {
			if (typeof a[criteria.sortCols[i].id] === 'string') {
				if (a[criteria.sortCols[i].id].localeCompare(b[criteria.sortCols[i].id]) === 0) {
					continue;
				} else {
					greaterThan = a[criteria.sortCols[i].id].localeCompare(b[criteria.sortCols[i].id]);
					if (criteria.sortCols[i].dir !== this.ASCENDING) {
						greaterThan *= -1;
					}
					break;
				}
			} else {
				if (a[criteria.sortCols[i].id] > b[criteria.sortCols[i].id]) {
					greaterThan = 1;
					if (criteria.sortCols[i].dir !== this.ASCENDING) {
						greaterThan *= -1;
					}
					break;
				} else if (a[criteria.sortCols[i].id] < b[criteria.sortCols[i].id]) {
					greaterThan = -1;
					if (criteria.sortCols[i].dir !== this.ASCENDING) {
						greaterThan *= -1;
					}
					break;
				}
				// (a[col] === b[col])
			}
		}
		return greaterThan;
	}
}

export interface ColumnSortedEvent {
	sortCols: ColumnCriterion[];
}

export interface ColumnCriterion {
	id: string;
	dir?: string;
	filterKey: string;
}

export interface Column {
	label: string;
	field: string;
	hide: boolean;
	hideable: boolean;
	stackable: boolean;
	searchable: boolean;
	valueTransformer: ValueTransformer;
}

export interface ArgResolver extends ValueTransformer {
	argsWriteTo: string;
}

export interface ValueTransformer {
	field?: string;
	transformer?: PipeTransform;
	args?: any;
	dependentTransformer?: ValueTransformer;
	argResolver?: ArgResolver;
}

export interface Criterion extends Record<string, string | undefined> {
	filter?: string;
	order?: string;
	value?: string;
}
