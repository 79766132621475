<div class="page-range-wrapper">
	<div class="page-button first-page" (click)="selectPage(0)"></div>
	<div class="page-button previous-page" (click)="decrement()"></div>
	<div *ngIf="pageLimitUnderflow()" class="page-button page-placeholder"></div>
	<div
		class="page-button"
		[ngClass]="{ active: currentPage == p, inactive: currentPage != p }"
		*ngFor="let p of displayPages; let i = index"
		(click)="selectPage(p)"
	>
		{{ p + 1 }}
	</div>
	<div *ngIf="pageLimitOverflow()" class="page-button page-placeholder"></div>
	<div class="page-button next-page" (click)="increment()"></div>

	<div class="page-button last-page" (click)="selectPage(totalPages - 1)"></div>
</div>
