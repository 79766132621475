import { NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { TranslateModule } from '@ngx-translate/core';
import { NotificationsService } from './notifications.service';

@NgModule({
	declarations: [],
	imports: [MatSnackBarModule, TranslateModule],
	exports: [MatSnackBarModule, TranslateModule],
	providers: [NotificationsService],
})
export class NotificationsModule {}
