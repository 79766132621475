import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
	providedIn: 'root',
})
export class NotificationsService {
	private _ERROR_CONFIG = {
		duration: STANDARD_NOTIFICATION_DURATION,
		panelClass: [ERROR_NOTIFICATION_CLASS],
	};

	private _SUCCESS_CONFIG = {
		duration: STANDARD_NOTIFICATION_DURATION,
		panelClass: [SUCCESS_NOTIFICATION_CLASS],
	};

	constructor(private matSnackBar: MatSnackBar, private translateService: TranslateService) {}

	public success(message: string, config?: MatSnackBarConfig) {
		this.matSnackBar.open(message, undefined, config ?? this._SUCCESS_CONFIG);
	}

	public error(message: string, config?: MatSnackBarConfig) {
		this.matSnackBar.open(message, undefined, config ?? this._ERROR_CONFIG);
	}

	public spawnNotification(message: any, type: NotificationType = NotificationType.SUCCESS) {
		this._message(message, type);
	}

	private _message(message: string, type: NotificationType) {
		switch (type) {
			case NotificationType.SUCCESS:
				this.success(message, this._SUCCESS_CONFIG);
				break;
			case NotificationType.ERROR:
				this.error(message, this._ERROR_CONFIG);
				break;
			case NotificationType.ERROR_CODE:
				const contact = this.translateService.instant('_ws-framework.error-codes.contact-administrator');
				message = this.translateService.instant(`_ws-framework.error-codes.${message.toString()}`);
				this.error(`${message} ${contact}`, this._ERROR_CONFIG);
				break;
			default:
				this.success(message, this._SUCCESS_CONFIG);
				break;
		}
	}
}

export enum NotificationType {
	SUCCESS,
	ERROR,
	ERROR_CODE,
}

export const STANDARD_NOTIFICATION_DURATION = 5000;
export const SUCCESS_NOTIFICATION_CLASS = 'snack-bar-success';
export const ERROR_NOTIFICATION_CLASS = 'snack-bar-error';
