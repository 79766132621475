import { NgModule } from '@angular/core';
import { FileSizePipe } from './pipes/file-size.pipe';
import { RouterModule } from '@angular/router';
import { InputsModule } from './inputs/inputs.module';
import { DialogsModule } from './dialogs/dialogs.module';
import { UpperCasePipe } from '@angular/common';
import { SafePipe } from './pipes/safe.pipe';
import { TimePipe } from './pipes/time.pipe';
import { ControlsModule } from './controls/controls.module';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { LocaleService } from './services/locale.service';
import { SmartTableModule } from './smart-table/smart-table.module';
import { PaginationModule } from './pagination/pagination.module';
import { ExpansionModule } from './expansion/expansion.module';

@NgModule({
	declarations: [FileSizePipe],
	imports: [RouterModule, InputsModule, DialogsModule],
	exports: [InputsModule, DialogsModule, ControlsModule, SmartTableModule, PaginationModule, ExpansionModule],
	providers: [LocaleService, UpperCasePipe, SafePipe, SafeHtmlPipe, TimePipe, FileSizePipe],
})
export class WsFrameworkExports {}
