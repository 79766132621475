import { MatchAuthorizationParams } from '../../authentication/util/authorization-params';
import { MatchHeaderParams } from '../../http/RestResource';

//!!ToDo!! Check Authorization Problems
export class MatchTenantAndAuthorizationParams extends MatchAuthorizationParams {
	constructor(headerFields: Array<string> = []) {
		super(['tenant-id'].concat(headerFields));
	}
}

export class MatchTenantParams extends MatchHeaderParams {
	constructor(headerFields: Array<string> = []) {
		super(['tenant-id'].concat(headerFields));
	}
}
