import { Directive, Input } from '@angular/core';
import { AttributeServiceConsumer } from '../util/service-consumer';
import { PaginationService } from './pagination.service';

@Directive()
export abstract class PaginationConsumer extends AttributeServiceConsumer<PaginationService> {
	@Input() service!: PaginationService;

	protected constructor(paginationService: PaginationService) {
		super(paginationService);
	}

	protected get paginationService(): PaginationService {
		return this.attributeService;
	}
}
