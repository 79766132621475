import { ComponentFactoryResolver, ComponentRef, Injectable, Injector, ViewContainerRef } from '@angular/core';
import { Expansion } from './types/expansion';
import { ExpansionInstance } from './types/expansion-instance';

@Injectable()
export class ExpansionService {
	private registeredExpansions: Map<string, Expansion[]> = new Map<string, Expansion[]>();

	constructor(private componentFactoryResolver: ComponentFactoryResolver, private injector: Injector) {}

	registerExpansion(scope: string, expansion: Expansion) {
		if (!this.registeredExpansions.has(scope)) {
			this.registeredExpansions.set(scope, []);
		}

		if (
			!this.registeredExpansions
				.get(scope)
				?.map((e) => e.id)
				.includes(expansion.id)
		) {
			this.registeredExpansions.get(scope)?.push(expansion);
		}
	}

	applyExpansions<COMPONENT_INTERFACE extends Record<string, any> = Record<string, any>>(
		scope: string,
		viewContainerRef: ViewContainerRef,
		styleClass?: string
	): ExpansionInstance<COMPONENT_INTERFACE>[] {
		const expansions = this.registeredExpansions.get(scope) || [];
		const instances: ExpansionInstance<COMPONENT_INTERFACE>[] = [];

		for (const expansion of expansions) {
			try {
				instances.push(
					new ExpansionInstance(
						expansion.id,
						expansion.label,
						this.createAndAttachComponent(expansion, viewContainerRef, expansion.id, styleClass),
						expansion.component,
						expansion.injector || this.injector,
						expansion.position
					)
				);
			} catch (e) {
				console.error(`Failed to apply expansion ${expansion.id} in scope ${scope}`);
				console.log(e);
			}
		}

		return instances;
	}

	private createAndAttachComponent<COMPONENT_INTERFACE>(
		expansion: Expansion,
		viewContainerRef: ViewContainerRef,
		id: string,
		styleClass?: string
	): ComponentRef<COMPONENT_INTERFACE> {
		const componentType = expansion.component;
		const factory = this.componentFactoryResolver.resolveComponentFactory(componentType);
		let componentRef = null;
		if (!!expansion.injector)
			componentRef = viewContainerRef.createComponent(factory, undefined, expansion.injector);
		else componentRef = viewContainerRef.createComponent(factory);

		try {
			// @ts-ignore
			componentRef.hostView.rootNodes[0].classList.add('expand_' + id);
			// @ts-ignore
			componentRef.hostView.rootNodes[0].classList.add('expansion');
			if (!!styleClass) {
				// @ts-ignore
				componentRef.hostView.rootNodes[0].classList.add(styleClass);
			}
		} catch (e) {
			console.error('Failed to apply css class to expansion ' + expansion.id);
		}

		return componentRef;
	}

	private searchForNode(nodes: any[], className: string, depth: number = 4): any {
		for (const node of nodes) {
			if (node.classList.contains(className)) {
				return node;
			}

			if (depth > 0 && node.childNodes.length > 0) {
				// Recursively search child nodes with reduced depth
				const childResult = this.searchForNode(Array.from(node.childNodes), className, depth - 1);

				if (childResult) {
					return childResult;
				}
			}
		}

		return null;
	}

	public getExpansionsByScope(scope: string) {
		console.log(this.registeredExpansions);
		return this.registeredExpansions.get(scope);
	}
}
