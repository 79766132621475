import { NgModule, Pipe } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DecimalToCommaPipe } from './decimal-to-comma.pipe';
import { CommaToDecimalPipe } from './comma-to-decimal.pipe';
import { OrderPipe } from './order.pipe';
import { MinMaxFilterPipe } from './min-max-filter.pipe';
import { MoneyPipe } from './money.pipe';
import { TimePipe } from './time.pipe';
import { EllipsisPipe } from './ellipsis.pipe';
import { SafePipe } from './safe.pipe';
import { AffixPipe } from './affix.pipe';
import { LocalizedDatePipe } from './localized-date-pipe';

@NgModule({
	declarations: [
		MinMaxFilterPipe,
		DecimalToCommaPipe,
		CommaToDecimalPipe,
		OrderPipe,
		MoneyPipe,
		SafePipe,
		LocalizedDatePipe,
		AffixPipe,
		TimePipe,
		EllipsisPipe,
	],
	imports: [CommonModule],
	exports: [
		MinMaxFilterPipe,
		DecimalToCommaPipe,
		CommaToDecimalPipe,
		LocalizedDatePipe,
		AffixPipe,
		OrderPipe,
		MoneyPipe,
		SafePipe,
		TimePipe,
		EllipsisPipe,
	],
	providers: [OrderPipe, AffixPipe],
})
export class PipesModule {}
