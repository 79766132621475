import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControlComponent } from '../form-control/form-control.component';
import { MatFormFieldAppearance } from '@angular/material/form-field';

@Component({
	selector: 'ws-input',
	templateUrl: './input.component.html',
	styleUrls: ['./input.component.scss'],
})
export class InputComponent extends FormControlComponent implements OnInit {
	@Input() dataType!: string;
	@Input() fieldType!: string;
	@Input() restrict!: string;
	@Input() maxlength!: number;
	@Input() unit!: string;
	@Input() upperCase!: boolean;
	@Input() showVisibilityToggle = false;
	@Input() appearance: MatFormFieldAppearance = 'outline';
	@Input() rows!: number;

	@Input() maxRows!: number;

	@Output() enterKeyDown = new EventEmitter();

	constructor() {
		super();
	}

	ngOnInit(): void {}

	onModelChanged($event: any) {
		if (this.upperCase) {
			this.result = $event.toUpperCase();
		}
		super.onModelChanged($event);
	}

	onEnterKeyDown() {
		this.enterKeyDown.emit();
	}

	toggleVisibility($event: any) {
		$event.stopPropagation();
		this.dataType = this.dataType === 'password' ? 'text' : 'password';
	}
}
