import { Injectable } from '@angular/core';
import { AuthenticationResource } from '../resources/authentication.resource';
import { Storage } from '../../util/storage';
import { HeaderInjector } from '../../http/InjectionBundle';
import { JwtHelperService } from '@auth0/angular-jwt';
import { PasswordResetResource } from '../resources/password-reset.resource';

@Injectable()
export class AuthenticationService implements HeaderInjector {
	@Storage({
		scopeByTargetClass: true,
		storageResolver: { storage: 'local', storageKey: 'tokenStorage', defaultStorage: 'session' },
	})
	private token: any;

	private _redirectUrl: string = '/'; // Default to home

	constructor(
		private authenticationResource: AuthenticationResource,
		private passwordResetResource: PasswordResetResource
	) {}

	public set redirectUrl(url: string) {
		this._redirectUrl = url;
	}

	public get redirectUrl(): string {
		return this._redirectUrl;
	}

	public async getHeader() {
		return Promise.resolve({ authorization: 'Bearer ' + this.token });
	}

	async login(credentials: { username: string; password: string }) {
		const token = await this.authenticationResource.login(undefined, credentials);

		if (!!token?.body?.token) {
			this.token = token.body.token;
		}

		return this.token;
	}

	//ToDo JwtToken
	isAuthenticated(): boolean {
		const jwtHelper = new JwtHelperService();
		if (this.token) {
			return jwtHelper.decodeToken(this.token).exp > Date.now() / 1000;
		}
		return false;
	}

	async requestPasswordReset(emailOrUsername: string) {
		return this.passwordResetResource.requestReset({ emailorusername: emailOrUsername });
	}

	async updatePassword(token: string, updatedPassword: string) {
		return this.passwordResetResource.updatePassword({ token }, { updatedPassword });
	}

	public getToken(): string {
		return this.token;
	}

	async logout() {
		this.token = null;
	}
}
