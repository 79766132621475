export class DeepProxy<T> {
	constructor(target: any, callback?: (update: any) => any | void) {
		// defines the root object with which updates will be called
		const main = proxyfy(target, target, callback);

		return new Proxy(main, {
			get(obj, name: string) {
				return obj[name];
			},
			set(obj, name: string, value) {
				if (typeof value !== 'object') {
					obj[name] = value;
				} else {
					obj[name] = new SubProxy(main, callback, value);
				}

				if (!!callback && typeof callback === 'function') {
					callback(main);
				}

				return true;
			},
		});
	}
}

class SubProxy {
	constructor(
		main: any,
		callback?: (update: any) => any | void,
		sub?: { [x: string]: any; hasOwnProperty: (arg0: string) => any }
	) {
		// @ts-ignore
		return new Proxy(sub, {
			get(obj, name: string) {
				// @ts-ignore
				return obj[name];
			},
			set(obj, name: string, value) {
				if (typeof value !== 'object') {
					// @ts-ignore
					obj[name] = value;
				} else {
					// @ts-ignore
					obj[name] = new SubProxy(main, callback, value);
				}

				if (!!callback && typeof callback === 'function') {
					callback(main);
				}

				return true;
			},
		});
	}
}

// Push proxies all the way down in the original target
function proxyfy(
	main: any,
	target: {
		[x: string]: any;
		hasOwnProperty: (arg0: string) => any;
	},
	callback: ((update: any) => any) | undefined,
	c?: undefined
) {
	for (const prop in target) {
		if (typeof target[prop] === 'object' && target[prop] !== null && target.hasOwnProperty(prop)) {
			target[prop] = new SubProxy(main, callback, proxyfy(main, target[prop], callback, c));
		}
	}

	return target;
}
