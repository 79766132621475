import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'order',
})
export class OrderPipe implements PipeTransform {
	transform(array: any[], field: string, reverse?: boolean): any[] {
		if (array) {
			array.sort((a: any, b: any) => {
				if (a[field] < b[field]) {
					return -1;
				} else if (a[field] > b[field]) {
					return 1;
				} else {
					return 0;
				}
			});
			if (reverse) {
				array = array.reverse();
			}
		}
		return array;
	}
}
