import { MatDialogRef, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AlertDialogComponent } from './alert-dialog/alert-dialog.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';

@Injectable({
	providedIn: 'root',
})
export class DialogsService {
	constructor(private dialog: MatDialog) {}

	public alert(title: string, message: string, config?: any): Observable<boolean> {
		let dialogRef: MatDialogRef<AlertDialogComponent>;

		const dialogConfig = new MatDialogConfig();

		dialogConfig.panelClass = 'alert';
		dialogConfig.disableClose = true;
		dialogConfig.autoFocus = true;
		dialogConfig.data = {
			title: title,
			message: message,
			config: config,
		};

		dialogRef = this.dialog.open(AlertDialogComponent, dialogConfig);

		return dialogRef.afterClosed();
	}

	public confirm(title: string, message: string, config?: any): Observable<boolean> {
		let dialogRef: MatDialogRef<ConfirmDialogComponent>;

		const dialogConfig = new MatDialogConfig();

		dialogConfig.panelClass = 'confirm';
		dialogConfig.disableClose = true;
		dialogConfig.autoFocus = true;
		dialogConfig.data = {
			title: title,
			message: message,
			config: config,
		};

		dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);

		return dialogRef.afterClosed();
	}
}
