import { Injectable, Optional } from '@angular/core';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { LocalStorage } from '../util/storage';
import { LOCALES } from '../enums/locales';
import { DateAdapterService } from '../controls/service/date-adapter.service';

@Injectable({
	providedIn: 'root',
})
export class LocaleService {
	// @ts-ignore
	@LocalStorage({ storageKey: 'ws_locale', defaultValueConfig: LOCALES.GERMAN }) private _currentLocale: LOCALES;

	private currentLocaleSource = new Subject<LOCALES>();

	constructor(private translate: TranslateService, @Optional() private dateAdapter: DateAdapterService) {
		if (!this.currentLocale) {
			this._currentLocale = this.getLocaleFromBrowserLanguage();
		}
	}

	public get currentLocaleObservable$() {
		return this.currentLocaleSource.asObservable();
	}

	public get currentLocale() {
		return this._currentLocale;
	}

	public set currentLocale(locale: LOCALES) {
		this._currentLocale = locale;
		this.translatePage();
		this.translateDates();
		this.currentLocaleSource.next(this._currentLocale);
		this.dateAdapter.setLocale(locale);
	}

	public translatePage() {
		this.translate.use(this.currentLocale);
	}

	public translateDates() {
		switch (this.currentLocale) {
			case LOCALES.GERMAN:
				moment.locale('de');
				break;
			case LOCALES.ENGLISH:
				moment.locale('en');
				break;
			case LOCALES.FRENCH:
				moment.locale('fr');
				break;
			default:
				moment.locale('de');
				break;
		}
	}

	private getLocaleFromBrowserLanguage() {
		switch (this.translate.getBrowserLang()) {
			case 'de':
				return LOCALES.GERMAN;
			case 'en':
				return LOCALES.ENGLISH;
			case 'fr':
				return LOCALES.FRENCH;
			default:
				return LOCALES.GERMAN;
		}
	}
}
