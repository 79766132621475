<button type="button" class="btn btn-white" (click)="openModal()">
	<i class="fa fa-eye-slash"></i> Show / Hide columns
</button>

<div class="shade" [ngClass]="{ transparent: !show }" (click)="onClickShade()"></div>
<div class="wrapper arrow" [ngClass]="{ hidden: !show }">
	<ul>
		<li *ngFor="let col of columns; let i = index">
			<input
				id="{{ col }}"
				type="checkbox"
				[checked]="!columns[i].hide"
				(change)="onClickColumnCheck(col)"
			/><label for="{{ col }}">&nbsp;{{ col }}</label>
		</li>
	</ul>
</div>
