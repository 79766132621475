import { Component, HostListener, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import { ColumnCriterion, ColumnSortedEvent, SmartTableService } from '../smart-table.service';
import { SmartTableConsumer } from '../smart-table-consumer';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'ws-smart-column',
	templateUrl: './smart-column.component.html',
	styleUrls: ['./smart-column.component.scss'],
})
export class SmartColumnComponent extends SmartTableConsumer implements OnInit, OnDestroy {
	private static getColumnCriteria(id: string, event: ColumnSortedEvent): ColumnCriterion {
		for (const col of event.sortCols) {
			if (col.id === id) {
				return col;
			}
		}
		// @ts-ignore

		return undefined;
	}

	@Input() columnName!: string;

	@Input() sortDirection!: string;

	@Input() stackable!: boolean;

	private _columnSortedSubscription: any;
	private _ignoreNextEvent = false;
	private _displayName = '';

	constructor(
		@Optional() smartTableService: SmartTableService,
		@Optional() private _translateService: TranslateService
	) {
		super(smartTableService);
	}

	get displayName() {
		return this._displayName;
	}

	@HostListener('click')
	sort() {
		if (this.sortDirection === null || this.sortDirection === this.smartTableService.NONE) {
			this.sortDirection = this.smartTableService.DEFAULT_DIR;
		} else if (this.sortDirection === this.smartTableService.ASCENDING) {
			this.sortDirection = this.smartTableService.DESCENDING;
		} else if (this.sortDirection === this.smartTableService.DESCENDING) {
			this.sortDirection = this.smartTableService.NONE;
		}
		this.reportSorting();
	}

	ngOnInit() {
		if (!!this._translateService) {
			this._translateService
				.get(this.columnName)
				.toPromise()
				.then((res) => {
					this._displayName = res;
				});
			this._translateService.onLangChange.subscribe(() => {
				this._translateService
					.get(this.columnName)
					.toPromise()
					.then((res) => {
						this._displayName = res;
					});
			});
		}

		const self = this;
		if (!this.sortDirection) {
			this.sortDirection = this.smartTableService.NONE;
		}
		// subscribe to sort changes so we can react when other columns are sorted
		// @ts-ignore
		this._columnSortedSubscription = this.smartTableService.columnSorted$.subscribe((event) => {
			let isContained = SmartColumnComponent.getColumnCriteria(self.columnName, event);
			// reset this column's sort direction to hide the sort icons
			if (this._ignoreNextEvent) {
				this._ignoreNextEvent = false;
			} else {
				if (event.sortCols.length > 0 && !self.smartTableService.isStackable(event) && !isContained) {
					self.sortDirection = this.smartTableService.NONE;
				}
				if (!!isContained && !!isContained.dir) {
					self.sortDirection = isContained.dir;
				}
			}
		});

		if (!!this.sortDirection && this.sortDirection !== this.smartTableService.NONE) {
			this.reportSorting();
		}
	}

	ngOnDestroy() {
		this._columnSortedSubscription.unsubscribe();
	}

	private reportSorting() {
		let filterName = this.columnName;
		this._ignoreNextEvent = true;

		this.smartTableService.columnSorted({
			sortCols: [
				{
					id: filterName,
					// @ts-ignore
					dir: this.sortDirection,
					// @ts-ignore
					filterKey: undefined,
				},
			],
		});
	}
}
