import { Injectable, Injector } from '@angular/core';
import {
	Expansion,
	ExpansionService,
	TenantSelectorComponent,
	TenantService,
	TenantProvider,
	Tenant,
} from 'ws-framework';
import { ShopTenantResource } from '../resources/shop-tenant.resource';

@Injectable()
export class ShopTenantService implements TenantProvider {
	public static TenantIdHeader = 'tenant-id';

	constructor(
		private shopTenantResource: ShopTenantResource,
		tenantService: TenantService,
		expansionService: ExpansionService,
		injector: Injector
	) {
		tenantService.attachTenantProvider(this);

		expansionService.registerExpansion(
			'adminHeaderRightScope',
			new Expansion('shop-tenant-selector', '', TenantSelectorComponent, 0, injector)
		);
	}

	async fetchTenants(): Promise<Tenant[]> {
		/*const tenants = [
			{ label: 'msf proto', id: '1' },
			{ label: 'testb', id: '2' },
		];*/
		const res = await this.shopTenantResource.getList({ s: 99999, p: 0 });

		// @ts-ignore
		const tenants: Tenant[] = res.body?.content.filter((t) => t.id > 0) || [];

		return Promise.resolve(tenants);
	}
}
