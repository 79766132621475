import { NgModule } from '@angular/core';
import { ControlsModule } from 'ws-framework';
import { EntityDetailHeaderComponent } from './components/entity-detail-header/entity-detail-header.component';
import { TranslateModule } from '@ngx-translate/core';
import { NotificationsModule, SmartTableModule } from 'ws-framework';
import { EntityListHeaderComponent } from './components/entity-list-header/entity-list-header.component';
import { RouterModule } from '@angular/router';
import { EntitySlideModalHeaderComponent } from './components/entity-slide-modal-header/entity-slide-modal-header.component';
import { CommonModule } from '@angular/common';

@NgModule({
	declarations: [EntityDetailHeaderComponent, EntityListHeaderComponent, EntitySlideModalHeaderComponent],
	imports: [
		ControlsModule,
		TranslateModule,
		NotificationsModule,
		RouterModule,
		TranslateModule,
		SmartTableModule,
		CommonModule,
		TranslateModule,
	],
	exports: [EntityDetailHeaderComponent, EntityListHeaderComponent, EntitySlideModalHeaderComponent],
})
export class EntityModule {}

export * from './components/entity-list/entity-list.component';
export * from './components/entity-list-header/entity-list-header.component';
export * from './components/entity-detail/entity-detail.component';
export * from './components/entity-detail-tab/entity-detail-tab.component';
export * from './components/entity-detail-header/entity-detail-header.component';
export * from './components/entity-slide-modal-header/entity-slide-modal-header.component';

export * from './resources/entity.resource';
