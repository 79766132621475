import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'minMaxFilter' })
export class MinMaxFilterPipe implements PipeTransform {
	transform(items: any[], path: string, minVal: number, maxVal: number): any[] {
		if (!items) {
			return [];
		}
		if (!minVal && !maxVal) {
			return items;
		}
		let result = [];

		if (minVal !== undefined) {
			result = items.filter((it) => {
				return minVal <= this.getValue(it, path);
			});
		}

		if (maxVal !== undefined) {
			result = result.filter((it) => {
				return maxVal >= this.getValue(it, path);
			});
		}
		return result;
	}

	getValue(option: any, path: string) {
		let val = option;
		if (path !== '') {
			const dp = path.split('.');
			for (let i = 0; i < dp.length; i++) {
				val = val[dp[i]];
			}
		}
		return val;
	}
}
