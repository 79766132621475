import { NgModule } from '@angular/core';
import { ShopService } from './services/shop.service';
import { ShopResource } from './resources/shop.resource';

@NgModule({
	declarations: [],
	imports: [],
	providers: [ShopService, ShopResource],
	exports: [],
})
export class ShopServiceModule {}
