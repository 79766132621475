<div class="container-fluid mb-3">
	<div class="row">
		<div class="col-12 actions d-flex justify-content-between flex-grow-1">
			<div>
				<ws-button
					*ngIf="!noBack"
					styleClass="back-button"
					type="button"
					[routerLink]="backPath"
					matIcon="arrow_back"
				>
					{{ 'entities.go-back' | translate }}
				</ws-button>
				<ng-content select="[left]"></ng-content>
			</div>
			<div class="d-flex flex-nowrap">
				<ng-content select="[right]"></ng-content>
				<ws-button
					*ngIf="!noSave"
					styleClass="save-button"
					type="submit"
					text="{{ 'entities.save' | translate }}"
				>
				</ws-button>
				<ws-button
					*ngIf="!noReset"
					styleClass="reset-button"
					type="button"
					text="{{ 'entities.reset' | translate }}"
					(click)="onReset.emit()"
				>
				</ws-button>
				<ws-button
					*ngIf="!noDelete"
					styleClass="reset-button"
					type="button"
					text="{{ 'entities.delete' | translate }}"
					(click)="onDelete.emit()"
				>
				</ws-button>
			</div>
		</div>
	</div>
</div>
