import { Component, Input, OnInit } from '@angular/core';
import { ArgResolver, Column, ValueTransformer } from '../smart-table.service';
import { defaultValuePipe } from '../../types/default-value-pipe';
import { getValueByPath } from '../../util/helpers';

@Component({
	selector: 'ws-smart-cell',
	templateUrl: './smart-cell.component.html',
	styleUrls: ['./smart-cell.component.scss'],
})
export class SmartCellComponent implements OnInit {
	@Input() column!: Column;
	@Input() entry!: Record<string, any>;

	constructor() {}

	public get value() {
		return getValueByPath(this.entry, this.column.field);
	}

	public get transformedValue() {
		if (this.column.valueTransformer) {
			return this.applyTransformers(this.column.valueTransformer);
		}
		return this.value;
	}

	ngOnInit() {}

	private applyTransformers(transformer: ValueTransformer): any {
		if (!transformer) {
			return '';
		}

		if (!!transformer.argResolver) {
			transformer.args = {
				...transformer.args,
				...this.applyTransformers(transformer.argResolver!),
			};
		}

		if (!transformer.dependentTransformer) {
			let returnVal = transformer.transformer!.transform(
				getValueByPath(this.entry, transformer.field!),
				transformer.args
			);
			if (!!(transformer as ArgResolver).argsWriteTo) {
				returnVal = { [(transformer as ArgResolver).argsWriteTo]: returnVal };
			}
			return returnVal;
		}

		const value = this.applyTransformers(transformer.dependentTransformer);

		let transformedValue = transformer.transformer!.transform(value, transformer.args);

		if (!!(transformer as ArgResolver).argsWriteTo) {
			transformedValue = { [(transformer as ArgResolver).argsWriteTo]: transformedValue };
		}

		return transformedValue;
	}
}
