<div class="panel-row" *ngFor="let field of Object.keys(fieldNames)">
	<div>
		<ws-input [(result)]="fieldNames[field]" (resultChange)="updateResult()"> </ws-input>
	</div>
	<div>
		<ws-input [(result)]="result.fields[field]" (resultChange)="updateResult()"> </ws-input>
	</div>
	<mat-icon class="icon-primary pointer" (click)="removeField(field)">close</mat-icon>
</div>
<ws-button
	type="button"
	styleClass="button-primary"
	(click)="addField()"
	matIcon="add"
	[text]="'_item-management.category.add-field' | translate"
>
</ws-button>
