import { NgModule } from '@angular/core';
import { ControlsModule } from '../controls/controls.module';
import { CalendarComponent } from './calendar/calendar.component';
import { PipesModule } from '../pipes/pipes.module';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ErrorMessageComponent } from './error-message/error-message.component';
import { NgSelectModule } from '@ng-select/ng-select';
import {
	NGX_MAT_DATE_FORMATS,
	NgxMatDateAdapter,
	NgxMatDatetimePickerModule,
	NgxMatNativeDateModule,
	NgxMatTimepickerModule,
} from '@angular-material-components/datetime-picker';
import { DateTimePickerComponent } from './date-time-picker/date-time-picker.component';
import { TimePickerComponent } from './time-picker/time-picker.component';
import { ReactiveFormsModule } from '@angular/forms';
import { LegendComponent } from './legend/legend.component';
import { SimpleTextComponent } from './simple-text/simple-text.component';
import { MatMenuModule } from '@angular/material/menu';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
	declarations: [
		CalendarComponent,
		ErrorMessageComponent,
		DateTimePickerComponent,
		TimePickerComponent,
		LegendComponent,
		SimpleTextComponent,
	],
	imports: [
		RouterModule,
		ControlsModule,
		ReactiveFormsModule,
		PipesModule,
		NgSelectModule,
		NgxMatDatetimePickerModule,
		NgxMatNativeDateModule,
		NgxMatTimepickerModule,
		MatMenuModule,
		TranslateModule,
	],
	exports: [
		CalendarComponent,
		ControlsModule,
		RouterModule,
		CommonModule,
		PipesModule,
		ErrorMessageComponent,
		DateTimePickerComponent,
		TimePickerComponent,
		LegendComponent,
		SimpleTextComponent,
	],
	providers: [],
})
export class InputsModule {}
