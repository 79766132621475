import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
	name: 'booleanTranslate',
})
export class BooleanTranslatePipe implements PipeTransform {
	constructor(private translateService: TranslateService) {}

	transform(value: boolean, args?: any): any {
		// TODO get options from services
		if (typeof value === 'boolean') {
			return value
				? this.translateService.instant('entities.active').toUpperCase()
				: this.translateService.instant('entities.inactive').toUpperCase();
		}
		return value;
	}
}

// options should not come from the args:
// TODO make a locale module and use it here to get the displayed locale
// TODO make shop or currency module and get shop currency iso
