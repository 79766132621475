import { TranslateService } from '@ngx-translate/core';
import {
	NotificationsService,
	NotificationType,
	Guard,
	GuardServiceAction,
	TemplateUtils,
	DialogsService,
	Column,
	TenantService,
	getValueByPath,
} from 'ws-framework';
import { EntityService } from '../../services/entity.service';
import { EntityResource } from '../../resources/entity.resource';
import { Entity } from '../../../types/entity';
import { Component, OnInit } from '@angular/core';

export interface EntityListExpansionContext<
	ENTITY extends Entity,
	SERVICE extends EntityService<ENTITY, any> = EntityService<ENTITY, any>
> {
	service: SERVICE;
}

@Component({
	template: '',
})
export abstract class EntityListComponent<
		ENTITY extends Entity,
		RESOURCE extends EntityResource<ENTITY>,
		SERVICE extends EntityService<ENTITY, RESOURCE>
	>
	extends TemplateUtils
	implements OnInit
{
	private readonly _context: Record<string, any>;

	protected constructor(
		private _entityService: EntityService<ENTITY, RESOURCE>,
		tenantService?: TenantService,
		protected dialogsService?: DialogsService,
		protected translateService?: TranslateService,
		protected notificationsService?: NotificationsService
	) {
		super();

		tenantService?.tenantChanged$.subscribe(() => {
			this.onTenantChanged();
		});

		this._context = { service: this.entityService };
	}

	public get context() {
		return this._context;
	}

	public get identifier() {
		return 'id';
	}

	public id(entity: ENTITY) {
		return getValueByPath(entity || {}, this.identifier) || undefined;
	}

	public get entityLabel() {
		return 'entities.entity';
	}

	public get labelForEntities() {
		return 'entities.entities';
	}

	public get labelForEntitiesNotification() {
		return 'entities.entities';
	}

	public get entityService(): SERVICE {
		return this._entityService as SERVICE;
	}

	public get entities() {
		return !!this.entityService.entityPage ? this.entityService.entityPage.content : [];
	}

	public get columns(): Column[] {
		return this.entityService.columns || [];
	}

	public ngOnInit(): void {
		this.fetchData();
	}

	public onParametersChanged() {
		this.fetchData();
	}

	public onFiltered(event: any) {
		this.fetchData();
	}

	public delete(item: any) {
		const title: string = this.translateService?.instant('entities.delete-title');
		const entity = Array.isArray(item)
			? this.translateService?.instant(this.labelForEntities)
			: this.entityLabelForEntity(item);
		const message: string = this.translateService?.instant('entities.delete-message', {
			entity: entity,
		});

		this.dialogsService?.confirm(title, message).subscribe((confirmed) => {
			if (confirmed) this._delete(item);
		});
	}

	public deleteChecked() {
		const checkedEntities = this.entityService.checkedEntities;
		const deletable: any =
			checkedEntities?.length == 0
				? undefined
				: checkedEntities.length === 1
				? checkedEntities[0]
				: checkedEntities;
		if (!!deletable) {
			this.delete(deletable);
		}
	}

	protected onTenantChanged() {
		this.fetchData();
	}

	protected entityLabelForEntity(entity: ENTITY) {
		return this.id(entity);
	}

	protected fetchData() {
		this.entityService.fetchEntities();
	}

	protected spawnNotification(
		message: string,
		type: NotificationType = NotificationType.SUCCESS,
		item?: any,
		interpolationParams?: Record<any, any>
	) {
		if (type === NotificationType.ERROR_CODE) {
			this.notificationsService?.spawnNotification(message, type);
			return;
		}

		if (!interpolationParams && !Array.isArray(item)) {
			interpolationParams = {
				entity: this.translateService?.instant(item ? this.entityLabelForEntity(item) : this.entityLabel),
			};
		} else if (!interpolationParams) {
			interpolationParams = {
				entity: this.translateService?.instant(this.labelForEntitiesNotification),
			};
		}
		const _message = this.translateService?.instant(message, interpolationParams);
		this.notificationsService?.spawnNotification(_message, type);
	}

	protected spawnErrorNotification(errorI18N: string, action: GuardServiceAction, item?: any) {
		const entity = Array.isArray(item) ? this.labelForEntitiesNotification : this.entityLabel;
		const _interpolationParams = {
			action: this.translateService?.instant(action).toLowerCase(),
			entity: this.translateService?.instant(entity).toLowerCase(),
		};
		this.spawnNotification(errorI18N, NotificationType.ERROR, item, _interpolationParams);
	}

	protected serviceActionViable(service: any, action: GuardServiceAction) {
		try {
			Guard.serviceExists(service);
		} catch (e) {
			console.log(e);
			this.spawnErrorNotification(e.message, action);
			return false;
		}
		return true;
	}

	protected async _delete(item: any, refreshList = true) {
		this.serviceActionViable(this.entityService, GuardServiceAction.DELETE);
		try {
			this.entityService.delete(item, refreshList)?.then(
				() => {
					const deleteSuccessfulMessage = Array.isArray(item)
						? 'entities.delete-successful-multiple'
						: 'entities.delete-successful';
					this.spawnNotification(deleteSuccessfulMessage, NotificationType.SUCCESS, item);
				},
				(res) => {
					console.log(res);
					this.spawnNotification(res.status, NotificationType.ERROR_CODE);
				}
			);
		} catch (e) {
			console.log(e);
			const deleteErrorMessage = Array.isArray(item) ? 'entities.delete-error-multiple' : 'entities.delete-error';
			this.spawnNotification(deleteErrorMessage, NotificationType.ERROR, item);
		}
	}
}
