export interface Currency {
	iso: string;
	label: string;
	symbol: string;
}

export const STUB_CURRENCIES: Currency[] = [
	{ iso: 'EUR', label: 'Euro', symbol: '€' },
	{ iso: 'USD', label: 'US Dollar', symbol: '$' },
];
