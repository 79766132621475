import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'commaToDecimal' })
export class CommaToDecimalPipe implements PipeTransform {
	transform(value: string) {
		if (value != null) {
			const val = value.trim().replace(',', '.');
			return parseFloat(val);
		}
		return null;
	}
}
