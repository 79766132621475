interface IPoll<TSender, TArgs, TSubscriber, TResult> {
	subscribe(fn: (sender: TSender, args: TArgs, subscriber: TSubscriber) => TResult, subscriber: TSubscriber): void;
	unsubscribe(subscriber: TSubscriber): void;
}

export class PollDispatcher<TSender, TArgs, TSubscriber, TResult>
	implements IPoll<TSender, TArgs, TSubscriber, TResult>
{
	private _subscriptions: Array<(sender: TSender, args: TArgs, subscriber: TSubscriber) => TResult> = new Array<
		(sender: TSender, args: TArgs, subscriber: TSubscriber) => TResult
	>();
	private _subscriber: Array<TSubscriber> = new Array<TSubscriber>();

	subscribe(fn: (sender: TSender, args: TArgs, subscriber: TSubscriber) => TResult, subscriber: TSubscriber): void {
		if (fn) {
			for (let i = 0; i < this._subscriptions.length; i++) {
				if (this._subscriptions[i] === fn && this._subscriber[i] === subscriber) {
					return;
				}
			}

			this._subscriptions.push(fn);
			this._subscriber.push(subscriber);
		}
	}

	unsubscribe(subscriber: TSubscriber): void {
		const i = this._subscriber.indexOf(subscriber);
		if (i > -1) {
			this._subscriber.splice(i, 1);
			this._subscriptions.splice(i, 1);
		}
	}

	close() {
		this._subscriber = [];
		this._subscriptions = [];
	}

	poll(sender: TSender, args: TArgs): TResult[] {
		const dispatch = this._subscriptions.slice(0, this._subscriptions.length);
		const dispatchTargets = this._subscriber.slice(0, this._subscriber.length);

		const results: TResult[] = [];

		for (let i = 0; i < dispatch.length; i++) {
			if (dispatch[i] && dispatchTargets[i]) {
				results.push(dispatch[i](sender, args, dispatchTargets[i]));
			}
		}

		return results;
	}
}
