import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageComponent } from './components/image/image.component';
import { FileResource } from './resources/file.resource';
import { FileUploadResource } from './resources/file-upload.resource';
import { ImageUploadComponent } from './components/image-upload/image-upload.component';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
	declarations: [ImageComponent, ImageUploadComponent],
	imports: [CommonModule, MatIconModule, TranslateModule],
	providers: [FileResource, FileUploadResource],
	exports: [ImageComponent, ImageUploadComponent],
})
export class ImageModule {}

export * from './components/image/image.component';
export * from './components/image-upload/image-upload.component';
export * from './resources/file.resource';
export * from './resources/image.resource';
export * from './resources/file-upload.resource';
export * from './types/ws-file';
