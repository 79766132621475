import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FileResource } from '../../resources/file.resource';
import { FileUploadResource } from '../../resources/file-upload.resource';
import { MatchTenantParams } from '../../../../tenancy/util/tenant-params';

@Component({
	selector: 'ws-img-upload',
	templateUrl: 'image-upload.component.html',
	styleUrls: ['image-upload.component.scss'],
})
export class ImageUploadComponent implements OnDestroy {
	@Output() fileChanged: EventEmitter<any> = new EventEmitter<any>();

	constructor() {}

	onFileSelected(event: any) {
		const file: any = event.target.files[0];
		if (file) {
			this.fileChanged.emit(file);
		}
	}

	ngOnDestroy() {
		this.fileChanged.complete();
	}
}
