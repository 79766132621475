import { Injectable } from '@angular/core';

@Injectable()
export class AppSettingsService {
	get appStorageScopeKey(): string {
		return 'WS.storage';
	}

	get tokenStorageKey(): string {
		return 'WS.token';
	}
}
