<ng-container ngForm #frm="ngForm">
	<mat-form-field [ngClass]="inputClass" appearance="legacy" [appearance]="appearance">
		<mat-label *ngIf="label" [ngClass]="labelClass">{{ label }}<span *ngIf="required">*</span></mat-label>
		<textarea
			#control
			matInput
			class="form-control"
			ngModel
			[id]="id"
			[placeholder]="placeholder"
			[disabled]="disabled"
			[(ngModel)]="result"
			[tabIndex]="tabIndex"
			[readonly]="readonly"
			(keyup.enter)="onEnter()"
			(ngModelChange)="onModelChanged($event)"
			maxlength="{{ maxlength }}"
			cdkTextareaAutosize
			[cdkAutosizeMinRows]="rows"
			[cdkAutosizeMaxRows]="maxRows"
			[name]="name"
		></textarea>
		<mat-hint *ngIf="hint">{{ hint }}</mat-hint>
		<mat-error>
			<ws-field-error
				[error]="error"
				[interpolationParams]="errorInterpolationParams"
				[control]="frm.controls[name]"
			></ws-field-error>
		</mat-error>
	</mat-form-field>
</ng-container>
