import { Injectable } from '@angular/core';
import { IRestFunc, POST, PUT, Rest, RestResource } from '../../../http/RestResource';
import { InjectionBundle } from '../../../http/InjectionBundle';

import { AuthenticationService } from '../../../authentication/service/authentication.service';
import { MatchTenantAndAuthorizationParams } from '../../../tenancy/util/tenant-params';

@Injectable({
	providedIn: 'root',
})
@Rest({
	name: 'FileUpload',
	pathPrefix: '/api/v1/admin/files',
})
export class FileUploadResource extends InjectionBundle {
	@RestResource({
		method: POST,
		path: '/',
	})
	upsert!: IRestFunc<any, MatchTenantAndAuthorizationParams, FormData>;

	constructor(authentication: AuthenticationService) {
		super(authentication);
	}
}
