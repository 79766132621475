import { Component, Optional } from '@angular/core';
import { PaginationService } from '../pagination.service';
import { PaginationConsumer } from '../pagination-consumer';

@Component({
	selector: 'ws-page-interval',
	templateUrl: './page-interval.component.html',
	styleUrls: ['./page-interval.component.scss'],
})
export class PageIntervalComponent extends PaginationConsumer {
	constructor(@Optional() _paginationService: PaginationService) {
		super(_paginationService);
	}

	public get firstEntry(): number {
		return this.totalData > this.paginationService.pageSize
			? this.paginationService.currentPage * this.paginationService.pageSize + 1
			: this.totalData > 0
			? 1
			: 0;
	}

	public get lastEntry(): number {
		const page = this.paginationService.currentPage || 0;
		const size = this.paginationService.pageSize || 10;
		const totalPages = this.paginationService.totalPages || 0;

		if (page === undefined) {
			return 0;
		} else if (page === totalPages - 1 || totalPages === 0) {
			return this.paginationService.dataSize || 0;
		} else if (page * size + size > this.paginationService.dataSize) {
			return this.paginationService.dataSize;
		} else {
			return page * size + size;
		}
	}

	public get totalData(): number {
		return this.paginationService.dataSize || 0;
	}

	public get currentPage(): number {
		return this.paginationService.currentPage || 0;
	}

	public get totalPages(): number {
		return this.paginationService.totalPages || 0;
	}
}
