import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'money' })
export class MoneyPipe implements PipeTransform {
	transform(value: number, decimal?: number, d?: string, t?: string) {
		const n = value;
		let k;
		decimal = isNaN((decimal = Math.abs(decimal as unknown as number))) ? 2 : decimal;
		d = d || ',';
		t = t || '.';
		const s = n < 0 ? '-' : '';
		const i = parseInt(Math.abs(+n || 0).toFixed(decimal), 10);
		k = i + '';
		const j = k.length > 3 ? k.length % 3 : 0;
		return (
			s +
			(j ? k.substr(0, j) + t : '') +
			k.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + t) +
			(decimal
				? d +
				  Math.abs(n - i)
						.toFixed(decimal)
						.slice(2)
				: '')
		);
	}
}
