import { Component, EventEmitter, Host, Input, OnInit, Optional, Output } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
	selector: 'ws-html-editor',
	templateUrl: './html-editor.component.html',
	styleUrls: ['./html-editor.component.scss'],
})
export class HtmlEditorComponent {
	@Input() toolbar: string =
		'undo redo | formats | bold italic | alignleft aligncenter alignright alignjustify | ' +
		'bullist numlist indent outdent | link image | print preview media | forecolor backcolor emoticons | ' +
		'fontselect fontsizeselect | code | codesample';

	@Input() plugins: string =
		'lists advlist image link textcolor table code help wordcount preview media emoticons codesample';

	@Input() menu: string = 'insert tools';

	@Input() result: any;
	@Output() resultChange: EventEmitter<any> = new EventEmitter<any>();
}
