import { Injectable } from '@angular/core';
import { GET, IRestFunc, Rest, RestResource } from '../../../http/RestResource';
import { InjectionBundle } from '../../../http/InjectionBundle';
import { TenantService } from '../../../tenancy/service/tenant.service';
import { AuthenticationService } from '../../../authentication/service/authentication.service';

@Injectable({
	providedIn: 'root',
})
@Rest({
	name: 'File',
	pathPrefix: '/api/v1/files',
})
export class FileResource extends InjectionBundle {
	@RestResource({
		path: '/{fileId}',
	})
	get!: IRestFunc<unknown, { fileId: string }>;

	constructor(tenantService: TenantService, auth: AuthenticationService) {
		super(tenantService, auth);
	}
}
