import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { ExpanderComponent, TenantService, Tenant, getValueByPath } from 'ws-framework';
import {
	deepCopy,
	DialogsService,
	NotificationsService,
	NotificationType,
	Guard,
	GuardServiceAction,
	TemplateUtils,
	ValidationError,
	ControlError,
} from 'ws-framework';
import { TranslateService } from '@ngx-translate/core';
import { Entity } from '../../../types/entity';
import { EntityResource } from '../../resources/entity.resource';
import { EntityService } from '../../services/entity.service';
import { Location } from '@angular/common';

export interface EntityExpansionContext<ENTITY extends Entity> {
	entity?: ENTITY;
	isNew: boolean;
	validationErrors: ValidationError[];
}

@Component({
	template: '',
})
export abstract class EntityDetailComponent<
		ENTITY extends Entity,
		RESOURCE extends EntityResource<ENTITY>,
		SERVICE extends EntityService<ENTITY, RESOURCE>,
		ENTITY_EXPANSION_CONTEXT extends EntityExpansionContext<ENTITY>
	>
	extends TemplateUtils
	implements OnInit
{
	@ViewChild('tabExpander') tabExpander!: ExpanderComponent;

	private readonly _context: ENTITY_EXPANSION_CONTEXT;

	private _validationErrors: ValidationError[] = [];

	protected constructor(
		private _entityService?: EntityService<ENTITY, RESOURCE>,
		tenantService?: TenantService,
		protected route?: ActivatedRoute,
		protected notificationsService?: NotificationsService,
		protected translateService?: TranslateService,
		protected dialogsService?: DialogsService,
		protected location?: Location,
		protected router?: Router
	) {
		super();
		this._validationErrors = [];

		tenantService?.tenantChanged$.subscribe(({ newTenant }) => {
			this.onTenantChanged(newTenant);
		});

		const _this = this;

		// @ts-ignore
		this._context = {
			get isNew() {
				return _this.isNew();
			},
			validationErrors: this._validationErrors,
		};
	}

	public closeDetailView() {
		const basePath = this.location?.path().split('/')[1] || undefined;
		if (!!basePath) {
			this.router?.navigateByUrl(basePath);
		}
	}

	public get entity() {
		return this._context.entity;
	}

	public get identifier() {
		return '_id';
	}

	public get validationErrors() {
		return this._validationErrors;
	}

	public get context() {
		return this._context;
	}

	public ngOnInit(): void {
		this._initEntity();
	}

	public onSubmit(form: NgForm) {
		const _this = this;
		const valid = this.validate();
		if (valid) {
			this.spawnConfirmDialog('entities.save-title', 'entities.save-message', () => _this._save());
		} else {
			this.spawnValidationErrorMessage();
		}
	}

	public onReset(update?: Record<string, any>) {
		this._reset(update);
	}

	public onDelete() {
		const _this = this;
		this.spawnConfirmDialog('entities.delete-title', 'entities.delete-message', () => _this._delete());
	}

	public get id() {
		return (
			getValueByPath(this.entity || {}, this.identifier) ||
			this.route?.snapshot.params[this.identifier] ||
			this.route?.snapshot.url[0]?.path ||
			undefined
		);
	}

	public isNew() {
		return this.id == undefined || this.id == 'new';
	}

	protected spawnValidationErrorMessage() {
		const errorMsg = 'entities.validation-errors';
		const entity = this.translateService?.instant(this.entityLabel).toLowerCase();
		let action: string = this.translateService?.instant(GuardServiceAction.SAVE);
		action = action.substring(0, 1).toUpperCase() + action.substring(1);
		const interpolationParams = { entity: entity, action: action };
		this.spawnNotification(errorMsg, NotificationType.ERROR, interpolationParams);
	}

	protected onTenantChanged(tenant?: Tenant) {
		this.closeDetailView();
	}

	protected get entityService(): SERVICE {
		return this._entityService as SERVICE;
	}

	protected generateNewEntity(): ENTITY {
		return {} as ENTITY;
	}

	protected spawnConfirmDialog(
		title: string,
		message: string,
		onConfirmed: (...args: any) => any,
		onReject: (...args: any) => any = () => void 0
	) {
		const _title: string = this.translateService?.instant(title) || title;
		const _message: string = this.translateService?.instant(message) || message;

		this.dialogsService?.confirm(_title, _message).subscribe((confirmed) => {
			if (confirmed) {
				onConfirmed();
			} else {
				onReject();
			}
		});
	}

	protected get entityLabel() {
		return 'entities.entity';
	}

	protected set entity(value) {
		this._context.entity = value;
		//this.tabExpander.call('exOnInitEntity', value); // TODO tab expander not defined on init... defer
	}

	protected set id(value: string) {
		if (!!value && this.location?.path().includes('new')) {
			const current = this.location.path();
			// @ts-ignore
			this.location?.replaceState(current.replace('new', value));
		}
	}

	protected sanityCheckEntity(entity: ENTITY) {
		return entity;
	}

	protected spawnNotification(message: string, type: NotificationType, interpolationParams?: Record<any, any>) {
		if (type === NotificationType.ERROR_CODE) {
			this.notificationsService?.spawnNotification(message, type);
			return;
		}

		if (!interpolationParams) {
			interpolationParams = {
				entity: this.translateService?.instant(this.entityLabel).toLowerCase(),
			};
		}

		const _message = this.translateService?.instant(message, interpolationParams);
		this.notificationsService?.spawnNotification(_message, type);
	}

	protected spawnErrorNotification(errorI18N: string, action: GuardServiceAction) {
		const _interpolationParams = {
			action: this.translateService?.instant(action).toLowerCase(),
			entity: this.translateService?.instant(this.entityLabel).toLowerCase(),
		};
		this.spawnNotification(errorI18N, NotificationType.ERROR, _interpolationParams);
	}

	protected serviceActionViable(service: any, action: GuardServiceAction) {
		try {
			Guard.serviceExists(service);
		} catch (e) {
			console.log(e);
			this.spawnErrorNotification(e.message, action);
			return false;
		}
		return true;
	}

	protected validate() {
		this.tabExpander.call('exOnValidate');
		const valid = this.validationErrors.length === 0;
		if (!valid) {
			this.validationErrors.forEach((err) => {
				if (err instanceof ControlError) {
					err.detectErrors();
				}
			});
		}
		return valid;
	}

	protected _initEntity() {
		if (this.isNew()) {
			this.entity = this.generateNewEntity();
		} else {
			this._entityService?.fetchEntity(this.id).then((res) => {
				this.entity = this.sanityCheckEntity(deepCopy(res));
			});
		}
	}

	protected async _save(update?: any) {
		this.serviceActionViable(this._entityService, GuardServiceAction.SAVE);
		try {
			const payload = update || { entity: this.entity, oldEntity: this.entityService?.entity };
			this.tabExpander.call('exOnSave', payload);

			if (!!payload) {
				if (this.isNew()) {
					await this._create(payload);
				} else {
					await this._update(payload);
				}
			}
		} catch (e) {
			console.error(e);
			this.spawnNotification('entities.save-error', NotificationType.ERROR);
		}
	}

	protected async _create(create: any) {
		await this._entityService?.create(create).then(
			(res) => {
				this.entity = deepCopy(this.entityService.entity!);
				this.id = getValueByPath(this.entity!, this.identifier);

				this.tabExpander.callDeferred('exAfterSave', { entity: this.entity, oldEntity: create }).then(
					() => {
						this.entity = deepCopy(this._entityService?.entity!);
						this.id = getValueByPath(this.entity!, this.identifier);
						this.spawnNotification('entities.save-successful', NotificationType.SUCCESS);
					},
					(res) => {
						console.log(res);
						this.spawnNotification(res.status, NotificationType.ERROR_CODE);
					}
				);
			},
			(res) => {
				console.log(res);
				this.spawnNotification(res.status, NotificationType.ERROR_CODE);
			}
		);
	}

	protected async _update(update: any) {
		await this._entityService?.update(update).then(
			(res) => {
				this.entity = deepCopy(this.entityService.entity!);
				this.id = getValueByPath(this.entity!, this.identifier);

				this.tabExpander
					.callDeferred(
						'exAfterSave',
						update || { entity: this.entity, oldEntity: this.entityService?.entity }
					)
					.then(
						() => {
							this.entity = deepCopy(this._entityService?.entity!);
							this.id = getValueByPath(this.entity!, this.identifier);
							this.spawnNotification('entities.save-successful', NotificationType.SUCCESS);
						},
						(res: any) => {
							this.spawnNotification(res.status, NotificationType.ERROR);
						}
					);
			},
			(res) => {
				console.log(res);
				this.spawnNotification(res.status, NotificationType.ERROR_CODE);
			}
		);
	}

	protected _reset(update?: Record<string, any>) {
		this.entity = deepCopy(this._entityService?.entity || {});

		this.tabExpander.call('exOnReset', update || this.entity);

		this.spawnNotification('entities.reset-successful', NotificationType.SUCCESS);
	}

	protected _delete(refreshList = false) {
		this.serviceActionViable(this._entityService, GuardServiceAction.DELETE);
		try {
			this._entityService!.delete(this.entity!, refreshList)?.then(
				() => {
					this.spawnNotification('entities.delete-successful', NotificationType.SUCCESS);
				},
				(res) => {
					this.spawnNotification(res.status, NotificationType.ERROR);
				}
			);
		} catch (e) {
			console.error(e);
			this.spawnNotification('entities.delete-error', NotificationType.ERROR);
		}
	}
}
