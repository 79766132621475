import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'time' })
export class TimePipe implements PipeTransform {
	transform(value: number) {
		if (!value) {
			return;
		}
		let hours = Math.floor(value / 60).toString();
		let minutes = (value % 60).toString();
		if (minutes.length === 1) {
			minutes = '0' + minutes;
		}
		if (hours.length === 1) {
			hours = '0' + hours;
		}
		return hours + ':' + minutes;
	}
}
