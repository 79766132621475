<form class="example-form" [formGroup]="formGroup" *ngIf="formGroup">
	<label *ngIf="label" [ngClass]="labelClass"
		>{{ label }}<span *ngIf="required">*</span>
		<ws-tooltip
			*ngIf="tooltipKey && tooltips"
			type="INFO"
			position="after"
			[tooltips]="tooltips"
			[type]="tooltipKey"
			class="tooltip"
		></ws-tooltip>
	</label>
	<mat-form-field [appearance]="appearance" [ngClass]="styleClass">
		<input
			type="text"
			[placeholder]="placeholder"
			matInput
			[appCustomPatternValidator]="fieldValidationConstraints"
			[formControlName]="name"
			[matAutocomplete]="auto"
		/>
		<mat-autocomplete #auto="matAutocomplete">
			<mat-option *ngFor="let option of options" [value]="option">
				{{ option }}
			</mat-option>
		</mat-autocomplete>
		<mat-error *ngIf="formGroup.controls[name].invalid"> Bitte machen Sie eine Angabe </mat-error>
	</mat-form-field>
</form>
