import { PipeTransform } from '@angular/core';

export const defaultValuePipe: PipeTransform = Object.assign(
	{},
	{
		transform: (value: any) => {
			return `${value || ''}`;
		},
	}
);
