import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { TenantSelectorComponent } from './components/tenant-selector/tenant-selector.component';
import { ControlsModule } from '../controls/controls.module';
import { TenantService } from './service/tenant.service';

@NgModule({
	declarations: [TenantSelectorComponent],
	imports: [ControlsModule, CommonModule, TranslateModule],
	providers: [TenantService],
	exports: [],
})
export class TenancyModule {}

export * from './components/tenant-selector/tenant-selector.component';
export * from './service/tenant.service';
export * from './types/tenant';
export * from './util/tenant-params';
