import { ComponentRef, Injector, Type } from '@angular/core';
import { Expansion } from './expansion';

export class ExpansionInstance<COMPONENT_INTERFACE> extends Expansion {
	constructor(
		_id: string,
		_label: string,
		private _ref: ComponentRef<COMPONENT_INTERFACE>,
		component: Type<any>,
		injector: Injector,
		_position = 0
	) {
		super(_id, _label, component, _position, injector);
	}

	public get id(): string {
		return this._id;
	}

	public get ref(): ComponentRef<COMPONENT_INTERFACE> {
		return this._ref;
	}

	public get position(): number {
		return this._position;
	}
}
