import {
	Directive,
	EventEmitter,
	Output,
	OnDestroy,
	OnInit,
	Optional,
	ElementRef,
	AfterViewInit,
	ComponentFactoryResolver,
	Renderer2,
	ViewContainerRef,
	Input,
	ComponentRef,
	ViewChildren,
} from '@angular/core';
import { Mailbox } from '../util/mailbox';
import { SmartTableService } from './smart-table.service';
import { SmartTableConsumer } from './smart-table-consumer';
import { CheckboxComponent } from '../controls/checkbox/checkbox.component';
import { Observable } from 'rxjs';
import { MatIcon } from '@angular/material/icon';

type HeaderCheckboxClickedFunction = (checked: boolean) => void;

@Directive({
	selector: '[wsSmartTable]',
})
export class SmartTableDirective extends SmartTableConsumer implements OnInit, AfterViewInit, OnDestroy {
	@Output()
	sorted = new EventEmitter();

	@Output()
	filtered = new EventEmitter();

	@Output()
	toggled = new EventEmitter();

	public columnTags: string[] = [];

	@Input() checkboxBuildObservable!: Observable<any>;
	@Input() showCheckboxes = true;
	@Input() headerCheckboxClicked: HeaderCheckboxClickedFunction = (checked: boolean) =>
		this.smartTableService.checkAll(checked);
	@Input() overrideService!: SmartTableService;
	protected mailbox: Mailbox = new Mailbox();
	private columnsHide: boolean[] = [];

	private columnFields: string[] = [];
	private columnSortedSubscription: unknown;
	private columnFilteredSubscription: unknown;
	private columnToggledSubscription: unknown;

	constructor(
		@Optional() smartTableService: SmartTableService,
		protected _elementRef: ElementRef,
		private renderer: Renderer2,
		private resolver: ComponentFactoryResolver,
		private viewContainerRef: ViewContainerRef
	) {
		super(smartTableService);
		this.service = this.overrideService ?? this.smartTableService;
	}

	ngOnInit() {
		const smartTableService = this.overrideService ?? this.smartTableService;
		smartTableService.init();

		if (!this.checkboxBuildObservable) {
			this.checkboxBuildObservable = this.smartTableService.paged$;
		}

		this.mailbox.push(
			(this.columnSortedSubscription = smartTableService.columnSorted$.subscribe((event) => {
				this.sorted.emit(event);
			}))
		);
		this.mailbox.push(
			(this.columnFilteredSubscription = smartTableService.columnFiltered$.subscribe((event) => {
				this.filtered.emit(event);
			}))
		);
		this.mailbox.push(
			(this.columnToggledSubscription = smartTableService.columnCountChanged$.subscribe(() => {
				this.updateNumberOfColumns();
			}))
		);
		this.mailbox.push(
			(this.columnToggledSubscription = smartTableService.columnToggled$.subscribe(() => {
				this.toggled.emit();
				this.updateNumberOfColumns();
			}))
		);
		if (this.showCheckboxes) {
			this.mailbox.push(
				this.checkboxBuildObservable.subscribe(() => {
					this.loadCheckboxes();
				})
			);
		}
	}

	ngAfterViewInit(): void {
		this.updateNumberOfColumns();
	}

	ngOnDestroy() {
		this.mailbox.burn();
	}

	public push(tag: string, field?: string, def: boolean = false) {
		if (tag.length > 1) {
			field = field || tag.trim()[0].toLowerCase() + tag.trim().slice(1).replace(' ', '');
		} else {
			field = field || tag.toLowerCase();
		}
		this.columnTags.push(tag);
		this.columnFields.push(field);
		this.columnsHide.push(!def);
	}

	public tag(col: number) {
		return this.columnTags[col];
	}

	public field(col: number): string {
		return this.columnTags[col];
	}
	protected updateNumberOfColumns() {
		this._elementRef.nativeElement.style = `--num-columns: ${this.smartTableService.columns.length}`;
	}

	private loadCheckboxes() {
		setTimeout(() => {
			this.createCheckboxInTableHead();
			this.createCheckboxesInTableBodyRows();
			this.createSmartSearchInputIcon();
		}, 50);
		this.updateNumberOfColumns();
	}

	private createCheckboxInTableHead() {
		if (!this._elementRef.nativeElement.querySelector('th ws-checkbox')) {
			const thead = this._elementRef.nativeElement.querySelector('thead');
			const checkboxFactory = this.resolver.resolveComponentFactory(CheckboxComponent);
			const checkboxComponentRef = this.viewContainerRef.createComponent(checkboxFactory);
			const checkboxComponent = checkboxComponentRef.instance as CheckboxComponent;
			checkboxComponent.resultChange.subscribe(this.headerCheckboxClicked);
			const thCheckbox = this.renderer.createElement('th');
			this.renderer.appendChild(thCheckbox, checkboxComponentRef.location.nativeElement);
			this.renderer.setStyle(thCheckbox, 'min-width', '45px');
			this.renderer.setStyle(thCheckbox, 'max-width', '45px');
			this.renderer.setStyle(thCheckbox, 'overflow-y', 'hidden');
			this.renderer.setStyle(thCheckbox, 'width', '45px');
			this.renderer.addClass(thCheckbox, 'smart-table-checkbox');
			this.renderer.insertBefore(thead, thCheckbox, thead.firstChild);

			const smartSearchSelector = this._elementRef.nativeElement.querySelector(
				'tbody tr td ws-smart-search-input'
			);
			if (!!smartSearchSelector) {
				const tBodyFirstRow = this._elementRef.nativeElement.querySelector('tbody tr');
				const emptyTd = this.renderer.createElement('td');
				this.renderer.insertBefore(tBodyFirstRow, emptyTd, tBodyFirstRow.firstChild);
			}
		}
	}

	private createCheckboxesInTableBodyRows() {
		const rowsWithCheckboxesToBeAdded = Array.from(
			this._elementRef.nativeElement.querySelectorAll(
				'tbody tr:not(:has(.multi-select-list)):not(:has(ws-smart-search-input))'
			)
		);
		const rowsWithNoSmartSearchInput = Array.from(
			this._elementRef.nativeElement.querySelectorAll('tbody tr:not(:has(ws-smart-search-input))')
		);
		if (rowsWithCheckboxesToBeAdded.length > 0) {
			rowsWithCheckboxesToBeAdded.forEach((row: any) => {
				const checkboxFactory = this.resolver.resolveComponentFactory(CheckboxComponent);
				const checkboxComponentRef = this.viewContainerRef.createComponent(checkboxFactory);
				const checkboxComponent = checkboxComponentRef.instance as CheckboxComponent;
				const indexCheckbox = {
					index: rowsWithNoSmartSearchInput.indexOf(row),
					checkbox: checkboxComponent,
				};
				this.smartTableService.pushCheckbox(indexCheckbox);
				const tdCheckbox = this.renderer.createElement('td');
				this.renderer.addClass(tdCheckbox, 'multi-select-list');
				this.renderer.setStyle(tdCheckbox, 'min-width', '45px');
				this.renderer.setStyle(tdCheckbox, 'max-width', '45px');
				this.renderer.setStyle(tdCheckbox, 'overflow-y', 'hidden');
				this.renderer.appendChild(tdCheckbox, checkboxComponentRef.location.nativeElement);
				this.renderer.insertBefore(row, tdCheckbox, row.firstChild);
			});
		}

		this.smartTableService.indexCheckboxes.forEach((indexCheckbox) => {
			if (indexCheckbox.index >= rowsWithNoSmartSearchInput.length) {
				this.smartTableService.popIndexCheckbox(indexCheckbox.index);
			}
		});
	}

	private createSmartSearchInputIcon() {
		const tdSearchIconSmartSearchInput = this._elementRef.nativeElement.querySelector(
			'tbody tr:has(ws-smart-search-input) td:first-child:not(:has(mat-icon))'
		);
		if (tdSearchIconSmartSearchInput) {
			const matIconComponentFactory = this.resolver.resolveComponentFactory(MatIcon);
			const matIconComponentRef = this.viewContainerRef.createComponent(matIconComponentFactory);
			this.renderer.setProperty(matIconComponentRef.location.nativeElement, 'innerHTML', 'search');
			this.renderer.setAttribute(matIconComponentRef.location.nativeElement, 'style', 'margin-top: 5px;');
			this.renderer.appendChild(tdSearchIconSmartSearchInput, matIconComponentRef.location.nativeElement);
		}
	}
}
