import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import * as _ from 'lodash';

@Component({
	selector: ' ws-tooltip',
	templateUrl: './tooltip.component.html',
	styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent implements OnInit, OnChanges {
	@Input() type!: string;
	@Input() tooltips!: any[];
	@Input() id!: string;
	@Input() position!: any;
	@Input() tooltip!: string;
	icon!: string;
	content!: string;
	ariaLabel!: string;

	constructor() {}

	ngOnInit() {
		this.position = this.position || 'after';
		switch (this.type) {
			case 'INFO':
				this.icon = 'icon ic-icon_info';
				break;
			case 'TEXTBLOCK':
				this.icon = 'icon ic-icon_textbaustein';
				break;
			case 'QUESTION':
				this.icon = 'icon ic-icon_hilfestellung';
				break;
			default:
				this.icon = 'icon ic-icon_info';
		}
		if (this.tooltips !== null) {
			this.getTooltipContent();
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.tooltips !== undefined) {
			this.tooltips = changes.tooltips.currentValue;
			this.getTooltipContent();
		}
	}

	private getTooltipContent() {
		if (this.tooltip) {
			this.content = this.tooltip;
		} else {
			const obj = _.find(this.tooltips, ['tooltipId', this.id]);
			if (obj !== undefined) {
				this.content = obj.content;
				this.ariaLabel = obj.name;
			}
		}
	}
}
