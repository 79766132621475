<ng-container ngForm #frm="ngForm">
	<mat-form-field *ngIf="asMatFormField">
		<ng-container *ngTemplateOutlet="inputs"></ng-container>
	</mat-form-field>

	<ng-container *ngIf="!asMatFormField">
		<ng-container *ngTemplateOutlet="inputs"></ng-container>
	</ng-container>
</ng-container>

<ng-template #inputs>
	<ng-container *ngIf="!asRange">
		<mat-label *ngIf="label" [ngClass]="labelClass">{{ label }}<span *ngIf="required">*</span></mat-label>
		<input
			matInput
			[matDatepicker]="picker"
			[max]="maxDate"
			[(ngModel)]="result"
			name="{{ name }}"
			(ngModelChange)="onModelChanged($event)"
			[appCustomPatternValidator]="fieldValidationConstraints"
			[disabled]="disabled"
			[readonly]="readonly"
		/>
		<mat-datepicker-toggle matSuffix [for]="picker" [disabled]="disabled || readonly"></mat-datepicker-toggle>
		<mat-datepicker #picker></mat-datepicker>
		<mat-hint *ngIf="hint">{{ hint }}</mat-hint>
		<mat-error>
			<ws-field-error
				[error]="error"
				[interpolationParams]="errorInterpolationParams"
				[control]="frm.controls[name]"
			></ws-field-error>
		</mat-error>
	</ng-container>

	<ng-container *ngIf="asRange">
		<mat-label *ngIf="label" [ngClass]="labelClass">{{ label }}<span *ngIf="required">*</span></mat-label>
		<div style="max-width: 0; opacity: 0">
			<mat-date-range-input [rangePicker]="picker">
				<input [(ngModel)]="result.from" matStartDate placeholder="" />
				<input [(ngModel)]="result.to" (dateChange)="emitRangeChange()" matEndDate placeholder="" />
			</mat-date-range-input>
		</div>
		<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
		<mat-date-range-picker #picker></mat-date-range-picker>
		<div class="d-flex align-items-center" *ngIf="!!result.from && !!result.to">
			{{ result?.from | date }} - {{ result?.to | date }}
		</div>
	</ng-container>
</ng-template>
