import { ChangeDetectorRef, Component, DoCheck, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UtilService } from '../../services/util.service';
import { ValidationConstraint } from '../../domain/validation-constraint';
import * as _ from 'lodash';
import { ValidationService } from '../../services/validation.service';
import { ValidationError } from '../../domain/validation-error';
import { Required } from '../../util/decorators';

@Component({
	selector: 'ws-error-message',
	templateUrl: './error-message.component.html',
	styleUrls: ['./error-message.component.scss'],
})
export class ErrorMessageComponent implements OnInit, OnChanges, DoCheck {
	@Input() @Required validationTarget: any;
	@Input() @Required validationContext: any;
	@Input() errors!: ValidationError[];
	@Input() modelPath!: string;
	@Input() styleClass!: string;
	@Input() validationConstraints!: ValidationConstraint[];
	@Input() objToValidate: any;
	@Input() errorPath!: string; // if error path not equal to model path

	public valid = true;
	public error!: string;

	protected componentValidationConstraints!: ValidationConstraint[];

	constructor(protected cd: ChangeDetectorRef, protected validationService: ValidationService) {}

	ngOnInit() {
		this.errorPath = this.errorPath || this.modelPath;
		// this.constraintPath = this.constraintPath || this.errorPath;
		this.updateValidationConstraints();
	}

	ngOnChanges(changes: SimpleChanges): void {
		this.valid = true;
		// @ts-ignore
		this.error = null;
		if (
			changes.errors !== undefined &&
			changes.errors.currentValue !== undefined &&
			!_.isEmpty(changes.errors.currentValue)
		) {
			this.errorPath = this.errorPath || this.modelPath;
			const errorObj = _.find(this.errors, ['field', this.errorPath]);
			if (errorObj) {
				this.error = errorObj.message.message;
			}
			if (this.error) {
				this.valid = false;
			}
		}
		if (changes.objToValidate) {
			this.updateValidationConstraints();
		}
	}

	ngDoCheck(): void {
		if (
			this.componentValidationConstraints &&
			this.componentValidationConstraints.length > 0 &&
			this.error &&
			this.errorPath
		) {
			this.validate();
		}
	}

	protected validate(): boolean {
		const target: any = UtilService.getObjByPath(this.validationTarget, this.modelPath);
		const validationRes = this.validationService.validate(
			target,
			this.validationContext,
			this.componentValidationConstraints
		);
		this.valid = validationRes.valid;
		if (validationRes.errorMsg) {
			this.error = validationRes.errorMsg;
		}
		this.cd.markForCheck();

		return this.valid;
	}

	private updateValidationConstraints() {
		if (this.error && this.validationConstraints !== null && this.modelPath) {
			this.componentValidationConstraints = _.filter(this.validationConstraints, ['field', this.modelPath]);
			this.cd.markForCheck();
		}
	}
}
