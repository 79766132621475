<ng-container ngForm #frm="ngForm">
	<mat-label *ngIf="label" [ngClass]="labelClass">{{ label }}<span *ngIf="required"> * </span></mat-label>
	<mat-form-field [ngClass]="inputClass" [appearance]="appearance">
		<ng-container *ngIf="fieldType !== 'textarea' && dataType === 'number'">
			<input
				#control
				matInput
				type="number"
				autocomplete="off"
				[id]="id"
				[placeholder]="placeholder + (placeholder !== '' && required ? '*' : '')"
				[appCustomPatternValidator]="fieldValidationConstraints"
				[disabled]="disabled"
				[(ngModel)]="result"
				[tabIndex]="tabIndex"
				(ngModelChange)="onModelChanged($event)"
				(blur)="onBlur($event)"
				appRestrict
				[restrict]="restrict"
				[maxlength]="maxlength"
				(keyup.enter)="onEnter()"
				(keydown.enter)="onEnterKeyDown()"
				[name]="name"
				[readonly]="readonly"
			/>
		</ng-container>
		<ng-container *ngIf="fieldType !== 'textarea' && dataType !== 'number'">
			<input
				#control
				matInput
				autocomplete="off"
				[id]="id"
				[type]="dataType"
				[placeholder]="placeholder + (placeholder !== '' && required ? '*' : '')"
				[appCustomPatternValidator]="fieldValidationConstraints"
				[disabled]="disabled"
				[(ngModel)]="result"
				[tabIndex]="tabIndex"
				(ngModelChange)="onModelChanged($event)"
				(blur)="onBlur($event)"
				appRestrict
				[restrict]="restrict"
				[maxlength]="maxlength"
				(keyup.enter)="onEnter()"
				(keydown.enter)="onEnterKeyDown()"
				[name]="name"
				[readonly]="readonly"
			/>
		</ng-container>
		<ng-container *ngIf="fieldType === 'textarea'">
			<textarea
				#control
				matInput
				[id]="id"
				[type]="dataType"
				[placeholder]="placeholder + (placeholder !== '' && required ? '*' : '')"
				[appCustomPatternValidator]="fieldValidationConstraints"
				[disabled]="disabled"
				[(ngModel)]="result"
				[tabIndex]="tabIndex"
				(ngModelChange)="onModelChanged($event)"
				(blur)="onBlur($event)"
				appRestrict
				[restrict]="restrict"
				[maxlength]="maxlength"
				(keyup.enter)="onEnter()"
				(keydown.enter)="onEnterKeyDown()"
				[name]="name"
				[readonly]="readonly"
				cdkTextareaAutosize
				[cdkAutosizeMinRows]="rows"
				[cdkAutosizeMaxRows]="maxRows"
			></textarea>
		</ng-container>
		<mat-hint *ngIf="hint">{{ hint }}</mat-hint>

		<button
			mat-icon-button
			matSuffix
			disableRipple
			type="button"
			class="visibility-toggle"
			*ngIf="showVisibilityToggle"
			(click)="toggleVisibility($event)"
		>
			<mat-icon>{{ dataType === 'password' ? 'visibility' : 'visibility_off' }}</mat-icon>
		</button>

		<mat-error>
			<ws-field-error
				[error]="error"
				[interpolationParams]="errorInterpolationParams"
				[control]="frm.controls[name]"
			></ws-field-error>
		</mat-error>
	</mat-form-field>
	<span *ngIf="unit">{{ unit }}</span>
</ng-container>
