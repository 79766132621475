import { Injectable } from '@angular/core';
import { RestBundle } from '../../http/RestBundle';
import { GET, IRestFunc, POST, Rest, RestResource } from '../../http/RestResource';

@Injectable({
	providedIn: 'root',
})
@Rest({
	name: 'PasswordReset',
	pathPrefix: '/api/v1/password-reset',
})
export class PasswordResetResource extends RestBundle {
	@RestResource({
		path: '/request-reset',
		method: GET,
	})
	requestReset!: IRestFunc<any, { emailorusername: string }>;

	@RestResource({
		path: '/{token}',
		method: POST,
	})
	updatePassword!: IRestFunc<any, { token: string }, { updatedPassword: string }>;
}
