<div class="tabNavigation container-fluid">
	<ng-container *ngIf="refs.length > 1">
		<div
			*ngFor="let instance of refs"
			(click)="setActive(instance.id)"
			[className]="instance.id === activeTab ? 'flex-col expander-tab active' : 'flex-col expander-tab inactive'"
		>
			{{ instance.label || '' | translate }}
		</div>
	</ng-container>
</div>
<div class="tab-wrapper">
	<div class="tab-header"><ng-content select="[tab-header]"></ng-content></div>
	<div class="tab-content"><ng-container #container></ng-container></div>
	<div class="tab-footer"><ng-content select="[tab-footer]"></ng-content></div>
</div>
