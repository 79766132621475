import { Component } from '@angular/core';
import { Location, LocationStrategy, PathLocationStrategy, registerLocaleData } from '@angular/common';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { LocaleService } from 'ws-framework';
import { Router } from '@angular/router';
import localeFr from '@angular/common/locales/fr';
import localeFrExtra from '@angular/common/locales/extra/fr';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';

@Component({
	// tslint:disable-next-line:component-selector
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	providers: [Location, { provide: LocationStrategy, useClass: PathLocationStrategy }],
})
export class AppComponent {
	showProgressBar = false;

	constructor(
		private router: Router,
		private readonly matIconRegistry: MatIconRegistry,
		private readonly domSanitizer: DomSanitizer,
		private readonly translate: TranslateService,
		private readonly localeService: LocaleService
	) {
		this.registerLocales();
		if (localeService.currentLocale) {
			localeService.translatePage();
		}

		/*for (const icon of IconNames) {
            this.matIconRegistry.addSvgIcon(
                icon,
                domSanitizer.bypassSecurityTrustResourceUrl(`./assets/images/svg/${icon}.svg`)
            );
        }*/
	}

	private registerLocales() {
		registerLocaleData(localeFr, localeFrExtra);
		registerLocaleData(localeDe, localeDeExtra);
	}
}
