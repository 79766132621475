import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'decimalToComma' })
export class DecimalToCommaPipe implements PipeTransform {
	transform(value: string) {
		let ret = value != null ? value.toString().replace('.', ',') : null;
		if (ret) {
			const decArr = ret.split(',');
			if (decArr.length > 1) {
				const dec = decArr[1].length;
				if (dec >= 2) {
					ret = ret.substr(0, ret.indexOf(',') + 3);
				} else if (dec === 1) {
					ret += '0';
				}
			}
		}
		return ret;
	}
}
