import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'ws-edit-cell',
	templateUrl: './edit-cell.component.html',
	styleUrls: ['./edit-cell.component.scss'],
})
export class EditCellComponent {
	@Input() routerLink!: string;
	@Input() styleClass!: string;
}
