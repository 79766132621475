import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import * as _ from 'lodash';

@Component({
	selector: 'ws-tab',
	templateUrl: 'tab.component.html',
	styleUrls: ['tab.component.scss'],
})
export class TabComponent implements OnInit, OnDestroy, OnChanges {
	@Input() tabs: TranslateTab[] = [];
	@Input() tabClass: string = '';

	@Output() tabChanged: EventEmitter<string | undefined> = new EventEmitter<string | undefined>();

	public activeTab?: TranslateTab;

	ngOnInit() {
		this.tabChanged.subscribe((value) => {
			if (!value) {
				this.activeTab = undefined;
				return;
			}

			const foundTab = _.find(this.tabs, { value: value });
			this.activeTab = foundTab;

			if (!foundTab) {
				console.warn(`No tab with value ${value} found.`);
			}
		});
	}

	ngOnDestroy() {
		this.tabChanged.complete();
	}

	ngOnChanges(changes: SimpleChanges) {
		console.log(changes);
		const tabsChanges = changes.tabs;
		if (!!tabsChanges && !_.isEqual(tabsChanges.currentValue, tabsChanges.previousValue)) {
			const tabs = tabsChanges.currentValue as TranslateTab[] | undefined;

			if (tabs) {
				this.tabChanged.emit(tabs![0]?.value);
			} else {
				this.tabChanged.emit(undefined);
			}
		}
	}

	isTabActive(tab: TranslateTab) {
		return _.isEqual(tab, this.activeTab) ? 'active' : '';
	}

	display(tab: TranslateTab) {
		const translate = !tab.translateModule ? '' : `${tab.translateModule}.`;
		return `${translate}${tab.value}`;
	}
}

export interface TranslateTab {
	value: string;
	translateModule?: string;
}
