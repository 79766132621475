import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { FormControlComponent } from '../form-control/form-control.component';

@Component({
	selector: 'ws-input-number',
	templateUrl: './input-number.component.html',
	styleUrls: ['./input-number.component.scss'],
})
export class InputNumberComponent extends FormControlComponent implements OnInit, OnChanges {
	@Input() maxlength!: number;
	@Input() numberFormat!: string;
	@Input() max!: number;
	@Input() min!: number;
	@Input() allowDecimal = true;
	@Input() unit!: string;

	constructor() {
		super();
	}

	ngOnInit(): void {
		super.ngOnInit();
	}
}
