import { Component, OnInit } from '@angular/core';
import { ExpansionComponent, getValueByPath, TemplateUtils, ValidationConstraint } from 'ws-framework';
import { TranslateService } from '@ngx-translate/core';
import { Entity } from '../../../types/entity';
import { EntityExpansionContext } from '../entity-detail/entity-detail.component';
import {
	ExpansionOnChanges,
	ExpansionOnDelete,
	ExpansionOnReset,
	ExpansionOnSave,
	ExpansionOnAfterSave,
} from '../../../types/expansionTypes';

@Component({
	template: '',
})
export abstract class EntityDetailTabComponent<
		ENTITY extends Entity,
		ENTITY_EXPANSION_CONTEXT extends EntityExpansionContext<ENTITY>
	>
	extends TemplateUtils
	implements
		OnInit,
		ExpansionComponent<ENTITY_EXPANSION_CONTEXT>,
		ExpansionOnSave,
		ExpansionOnChanges,
		ExpansionOnReset,
		ExpansionOnDelete,
		ExpansionOnAfterSave
{
	public context!: ENTITY_EXPANSION_CONTEXT;

	protected _validationConstraints: ValidationConstraint[] = [];

	protected constructor(protected translateService: TranslateService) {
		super();
	}

	exOnChanges(): void {}
	exOnSave({ entity, oldEntity }: { entity: ENTITY; oldEntity: ENTITY }): void {}
	exOnDelete(entity: any): void {}
	exOnReset(update: Record<string, any>): void {}
	exOnInitEntity(entity: ENTITY): void {}
	exAfterSave(update: { entity: Entity; oldEntity: Entity } & Record<string, any>): void {}

	public get entity() {
		return this.sanityCheckEntity(this.context.entity!);
	}

	public get id() {
		return getValueByPath(this.entity || {}, this.identifier) || undefined;
	}

	public get identifier() {
		return 'id';
	}

	public isNew() {
		return this.context.isNew;
	}

	public get validationErrors() {
		return this.context.validationErrors;
	}

	public get validationConstraints() {
		return this._validationConstraints;
	}

	ngOnInit() {
		this.registerValidationConstraints();
	}

	public registerValidationConstraints() {}

	protected sanityCheckEntity(entity: ENTITY) {
		return entity;
	}
}
