import {
	DELETE,
	IRestFunc,
	PUT,
	Rest,
	Page,
	PaginationParams,
	RestResource,
	InjectionBundle,
	POST,
	AuthenticationService,
} from 'ws-framework';
import { Injectable } from '@angular/core';
import { Shop } from '../types/shop';
import { EntityResource } from '../../entity/resources/entity.resource';
import { Id } from '../../types/entity';

@Injectable()
@Rest({
	name: 'Shop',
	pathPrefix: '/api/v1/admin/tenant',
})
export class ShopResource extends InjectionBundle implements EntityResource<Shop> {
	@RestResource({
		path: '/{_id}',
	})
	get!: IRestFunc<Shop, { id: string }>;

	@RestResource({
		path: '/',
	})
	getList!: IRestFunc<Page<Shop>, PaginationParams | undefined>;

	@RestResource({
		method: POST,
		path: '/',
	})
	create!: IRestFunc<
		Shop,
		undefined,
		Shop & { templateDBHost: string; templateDBPort: number; templateDBName: string }
	>;

	@RestResource({
		method: PUT,
		path: '/',
	})
	update!: IRestFunc<Shop, undefined, Shop>;

	@RestResource({
		method: PUT,
		path: '/',
	})
	upsert!: IRestFunc<Shop, undefined, Shop>;

	@RestResource({
		method: DELETE,
		path: '/',
	})
	delete!: IRestFunc<Array<any>, undefined, Id[]>;

	constructor(auth: AuthenticationService) {
		super(auth);
	}
}
