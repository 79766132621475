import { Component, Host, Input, OnInit, Optional } from '@angular/core';
import { SmartTableService } from '../smart-table.service';
import { SmartTableConsumer } from '../smart-table-consumer';

@Component({
	selector: 'ws-smart-search-input',
	templateUrl: './smart-search-input.component.html',
	styleUrls: ['./smart-search-input.component.scss'],
})
export class SmartSearchInputComponent extends SmartTableConsumer implements OnInit {
	@Input() columnField!: string;

	@Input() placeholder!: string;

	@Input() delay = 1500;

	@Input() mode = 'cell';

	@Input() maxlength = -1;

	@Input() disabled = false;

	public value: any;

	private timoutCall!: string | number;

	constructor(@Optional() smartTableService: SmartTableService) {
		super(smartTableService);
	}

	ngOnInit() {
		this.initInputFromParams();
	}

	private initInputFromParams() {
		const params = this.smartTableService.getParameters();

		if (!!params?.criteria) {
			this.value = params.criteria.find((c) => c.filter === this.columnField)?.value;
		}
	}

	onInput(event: any) {
		const self = this;
		if (this.timoutCall) {
			// @ts-ignore
			clearTimeout(this.timoutCall);
		}
		// @ts-ignore
		this.timoutCall = setTimeout(() => {
			self.emitInput();
		}, this.delay);
	}

	onEnter() {
		this.emitInput();
	}

	private emitInput() {
		this.smartTableService.columnFiltered({
			sortCols: [
				{
					id: this.columnField,
					dir: undefined,
					filterKey: this.value,
				},
			],
		});
	}
}
