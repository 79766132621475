import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';

@Directive({
	selector: '[appRestrict]',
})
export class RestrictDirective {
	@Input() restrict!: string;

	private allowedControlKeyCombinationChars = ['v', 'c', 'V', 'C', 'a', 'A', 'x', 'X'];
	private allowedSystemKeyCodes = [8, 9, 13, 16, 18, 20, 27, 42, 43, 44, 45, 46, 37, 39, 35, 36];

	constructor(private _renderer: Renderer2, private _elementRef: ElementRef) {}

	@HostListener('keydown', ['$event']) keydown(event: KeyboardEvent) {
		if (this.restrict === undefined) {
			return;
		}
		let code;
		if (event.keyCode !== undefined) {
			code = event.keyCode;
		} else {
			code = event.which;
		}
		if (this.allowedSystemKeyCodes.indexOf(code) !== -1) {
			return;
		}

		let character;
		if (event.key !== undefined) {
			character = event.key;
		} else {
			character = String.fromCharCode(code);
		}

		if (character === 'Decimal') {
			character = ',';
		}

		if (event.ctrlKey && this.allowedControlKeyCombinationChars.indexOf(character) !== -1) {
			return;
		}

		if (!this.isCharacterValid(character)) {
			event.preventDefault();
			event.stopImmediatePropagation();
		}
	}

	@HostListener('paste', ['$event']) paste(event: any) {
		if (this.restrict === undefined) {
			return;
		}

		const data = event.clipboardData.getData('text');
		let res = '';
		for (let i = 0; i < data.length; i++) {
			if (this.isCharacterValid(data[i])) {
				res += data[i];
			}
		}
		if (data !== res) {
			event.preventDefault();
			this._renderer.setProperty(this._elementRef.nativeElement, 'value', res);
			const ev = new Event('input', { bubbles: true });
			this._elementRef.nativeElement.dispatchEvent(ev);
		}
	}

	isCharacterValid(character: string) {
		const restrictExpression = new RegExp(this.restrict);
		const expressionValid = restrictExpression.test(character);
		return character && restrictExpression.test(character);
	}
}
