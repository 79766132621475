<ng-container ngForm #frm="ngForm">
	<mat-form-field [ngClass]="inputClass" appearance="outline">
		<mat-label *ngIf="label" [ngClass]="labelClass">{{ label }}<span *ngIf="required">*</span></mat-label>
		<input
			#control
			matInput
			[id]="id"
			[placeholder]="placeholder"
			[appCustomPatternValidator]="fieldValidationConstraints"
			[disabled]="disabled"
			[(ngModel)]="result"
			[tabIndex]="tabIndex"
			(ngModelChange)="onModelChanged($event)"
			(blur)="onBlur($event)"
			(keyup.enter)="onEnter()"
			[name]="name"
			appFormatCurrency
			[format]="numberFormat"
			[min]="min"
			[max]="max"
			[allowDecimal]="allowDecimal"
		/>
		<mat-hint *ngIf="hint">{{ hint }}</mat-hint>
		<mat-error>
			<ws-field-error
				[error]="error"
				[interpolationParams]="errorInterpolationParams"
				[control]="frm.controls[name]"
			></ws-field-error>
		</mat-error>
	</mat-form-field>
	<span *ngIf="unit">{{ unit }}</span>
</ng-container>
