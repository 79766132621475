import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import * as _ from 'lodash';
import { AuthenticationService } from '../authentication/service/authentication.service';

@Injectable({
	providedIn: 'root',
})
export class JwtDecoderService {
	constructor(private authService: AuthenticationService) {}

	public get authToken() {
		return this.authService.getToken();
	}

	public getTokenData() {
		if (this.authToken !== undefined && this.authToken !== null) {
			const helper = new JwtHelperService();
			return helper.decodeToken(this.authToken);
		}
	}

	public isAuthenticated(): boolean {
		return !!this.authService.isAuthenticated();
	}

	public getUsername() {
		const decodedToken = this.getTokenData();
		if (decodedToken !== undefined) {
			return decodedToken.sub;
		}
	}

	public getName() {
		const decodedToken = this.getTokenData();
		if (decodedToken !== undefined && decodedToken.firstName && decodedToken.lastName) {
			return decodedToken.firstName + ' ' + decodedToken.lastName;
		}

		return null;
	}

	public getRoles() {
		const decodedToken = this.getTokenData();
		if (decodedToken) {
			return decodedToken.data.roles;
		}
		return null;
	}

	public hasRole(role: string) {
		const roles = this.getRoles();
		if (roles && roles.length > 0) {
			const roleObj = _.find(roles, ['name', role]);
			return !!roleObj;
		}
		return false;
	}

	/*public getRoles() {
        const decodedToken = this.getToken();
        if (decodedToken !== undefined) {
            return decodedToken.data.roles;
        }
    }

    public hasRole(role: string) {
        const roles = this.getRoles();
        if (roles !== undefined) {
            const obj = UtilService.getObjectByValue('name', role, roles);
            return obj !== undefined;
        }
        return false;
    }*/

	public hasPermission(permission: string) {
		const roles = this.getRoles();
		for (const role of roles) {
			for (const perm of role.permissions) {
				if (perm === permission) {
					return true;
				}
			}
		}
		return false;
	}
}
