import { Directive, HostListener, Input, Renderer2, ElementRef } from '@angular/core';
import { Router } from '@angular/router';

@Directive({
	selector: '[attachAnchor]',
})
export class AttachAnchorDirective {
	@Input() routerLink: string | any[] | undefined;
	@Input() href: string | undefined;
	@Input() target: string | undefined;

	constructor(private router: Router, private renderer: Renderer2, private elementRef: ElementRef) {}

	@HostListener('auxclick', ['$event'])
	onAuxClick(event: MouseEvent): void {
		if (event.button === 1) {
			// TODO Middle mouse button click
			event.preventDefault();

			this.handleNavigation();
		}
	}

	@HostListener('click', ['$event'])
	onClick(event: MouseEvent): void {
		if (event.button === 0 && !event.ctrlKey && !event.metaKey) {
			// Left mouse button click
			event.preventDefault();
			this.handleNavigation();
		}
	}

	private handleNavigation(): void {
		if (this.routerLink) {
			// @ts-ignore
			this.router.navigateByUrl(this.routerLink);
		} else if (this.href) {
			const anchor = this.renderer.createElement('a');
			this.renderer.setAttribute(anchor, 'href', this.href);
			if (this.target) {
				this.renderer.setAttribute(anchor, 'target', this.target);
			} else {
				this.renderer.setAttribute(anchor, 'target', '_blank');
			}
			this.renderer.setStyle(anchor, 'display', 'none');
			this.renderer.appendChild(this.elementRef.nativeElement, anchor);
			anchor.click();
			this.renderer.removeChild(this.elementRef.nativeElement, anchor);
		}
	}
}
