import { LOCALES } from 'ws-framework';
import * as moment from 'moment';

// TODO move to pipes module and rewrite to pipes

export const timestampToDateTime = Object.assign(
	{},
	{
		apply: (value: number) => {
			const timestampToSeconds = (t: number) => Math.floor(t / 1000);
			const timestampIsMilliseconds = value > 999999999999;
			if (timestampIsMilliseconds) value = timestampToSeconds(value);

			const date = moment.unix(value);
			return `${date.format('L')} ${date.format('LT')}`;
		},
	}
);

export const numberToString = (options?: Record<string, string>) =>
	Object.assign(
		{},
		{
			options: options,
			apply: (value: number, locale: LOCALES) => {
				// @ts-ignore
				if (this && this.options) {
					const implementedOptions = ['roundTo'];
					// @ts-ignore
					checkOptions(this.options, implementedOptions, 'numberToString');
					// @ts-ignore
					if (this.options['roundTo']) {
						// @ts-ignore
						const roundTo = this.options['roundTo'];

						return value.toFixed(roundTo);
					}
				}

				return value;
			},
		}
	);
