import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
	selector: 'ws-button',
	templateUrl: './button.component.html',
	styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnInit {
	@Input() routerLink!: string;
	@Input() text!: string;
	@Input() type: string = 'button';
	@Input() color!: string;
	@Input() styleClass!: string;
	@Input() icon!: string;
	@Input() disabled!: boolean;
	@Input() loading!: boolean;
	@Input() matIcon!: string;
	@Input() matIconFontSet!: string;
	@Output() onClick: EventEmitter<any> = new EventEmitter<any>();

	constructor() {}

	ngOnInit(): void {}
}
