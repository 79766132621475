import { NgModule } from '@angular/core';
import { ExpanderComponent } from './components/expander/expander.component';
import { TabExpanderComponent } from './components/tab-expander/tab-expander.component';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
	declarations: [ExpanderComponent, TabExpanderComponent],
	imports: [CommonModule, TranslateModule],
	providers: [],
	exports: [ExpanderComponent, TabExpanderComponent],
})
export class ExpansionModule {}

export * from './expansion.service';
export * from './components/expander/expander.component';
export * from './components/tab-expander/tab-expander.component';
export * from './types/expansion';
