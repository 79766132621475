import { Injector, Type } from '@angular/core';

export class Expansion {
	constructor(
		protected _id: string,
		protected _label: string,
		protected _component: Type<any>,
		protected _position = 0,
		protected _injector?: Injector
	) {}

	public get id(): string {
		return this._id;
	}

	public get label(): string {
		return this._label;
	}

	public get component() {
		return this._component;
	}

	public get position(): number {
		return this._position;
	}

	public get injector() {
		return this._injector;
	}
}
