import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ShopPipesModule } from './pipes/shop-pipes.module';
import { EntityModule } from './entity/entity.module';
import { TableUtilsModule } from './table-utils/table-utils.module';
import { ShopTenantModule } from './shop-tenancy/shop-tenant.module';
import { ShopServiceModule } from './shop/shop-service.module';

@NgModule({
	declarations: [],
	providers: [],
	imports: [EntityModule, CommonModule, RouterModule, ShopPipesModule, TableUtilsModule, ShopServiceModule],
	exports: [ShopPipesModule, EntityModule, ShopTenantModule, TableUtilsModule, ShopServiceModule],
})
export class ShopCommonModule {}
