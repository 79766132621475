import { NgModule } from '@angular/core';
import { PageSelectorComponent } from './page-selector/page-selector.component';
import { PageIntervalComponent } from './page-interval/page-interval.component';
import { PageSizeSelectorComponent } from './page-size-selector/page-size-selector.component';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { PaginatorDirective } from './paginator.directive';
//import { ControlsModule } from 'ws-framework';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
	declarations: [PaginatorDirective, PageSelectorComponent, PageIntervalComponent, PageSizeSelectorComponent],
	imports: [
		FormsModule,
		CommonModule,
		//ControlsModule,
		TranslateModule,
	],
	providers: [],
	exports: [PaginatorDirective, PageSelectorComponent, PageIntervalComponent, PageSizeSelectorComponent],
})
export class PaginationModule {}

export * from './pagination.service';
export * from './paginator.directive';
export * from './page-interval/page-interval.component';
export * from './page-selector/page-selector.component';
export * from './page-size-selector/page-size-selector.component';
