import { LOCALE_ID, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { MatMenuModule } from '@angular/material/menu';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { registerLocaleData } from '@angular/common';
import localeDE from '@angular/common/locales/de';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ModuleTranslateLoader } from './util/translation/ModuleTranslateLoader';
import { AppSettingsService } from './service/app-settings.service';
import {
	AuthenticationService,
	AuthGuard,
	ControlsModule,
	ExpansionService,
	JwtDecoderService,
	StorageSettingsToken,
} from 'ws-framework';
import { StorageSettingsImpl } from './auth/login/types/storage-settings';
import { MatSelectModule } from '@angular/material/select';
import { EntityTranslatePipe } from 'shop-common';
import { MatIconModule } from '@angular/material/icon';

registerLocaleData(localeDE);

const CUSTOM_MOMENT_FORMATS = {
	parse: {
		dateInput: 'l, LT',
	},
	display: {
		dateInput: 'l, LT',
		monthYearLabel: 'MMM YYYY',
		dateA11yLabel: 'LL',
		monthYearA11yLabel: 'MMMM YYYY',
	},
};

const FORMAT = {
	parse: {
		dateInput: 'DD.MM.YYYY',
	},
	display: {
		dateInput: 'DD.MM.YYYY',
		monthYearLabel: 'MMMM YYYY',
		dateA11yLabel: 'LL',
		monthYearA11yLabel: 'MMMM YYYY',
	},
};

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		FormsModule,
		AppRoutingModule,
		HttpClientModule,
		MatMenuModule,
		MatSelectModule,
		MatProgressBarModule,
		MatIconModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient],
			},
		}),
	],
	providers: [
		EntityTranslatePipe,
		AuthGuard,
		AuthenticationService,
		AppSettingsService,
		ControlsModule,
		ExpansionService,
		{ provide: LOCALE_ID, useValue: 'de-DE' },
		//{ provide: MAT_DATE_LOCALE, useValue: 'en-US' },
		//{ provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
		//{ provide: MAT_DATE_FORMATS, useValue: FORMAT },
		// ??? why would we need both these
		//{ provide: NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
		//{ provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_MOMENT_FORMATS },
		//{ provide: MomentDateAdapter, useClass: MomentDateAdapter },
		//{ provide: DateAdapter, useExisting: MomentDateAdapter },
		[JwtDecoderService, { provide: StorageSettingsToken, useClass: StorageSettingsImpl }],
	],
	bootstrap: [AppComponent],
})
export class AppModule {}

export function HttpLoaderFactory(http: HttpClient): TranslateLoader {
	return new ModuleTranslateLoader(http);
}
