import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TemplateUtils } from '../../components/template-utils/template-utils';
import { FormControlComponent } from '../form-control/form-control.component';

// WIP

@Component({
	selector: 'ws-json-form',
	templateUrl: './json-form.component.html',
	styleUrls: ['./json-form.component.scss'],
})
export class JsonFormComponent extends FormControlComponent implements OnInit {
	public fieldNames: Record<string, string> = {};

	public Object = Object;

	constructor() {
		super();
	}

	ngOnInit(): void {
		this.prepareFieldsForm();
	}

	updateFieldName(oldKey: string, newKey: string) {
		this.fieldNames[newKey] = newKey;
		delete this.fieldNames[oldKey];
		this.result = this.rebuildFields(this.result);
	}

	updateFieldValue(key: string, newValue: any) {
		this.result[key] = newValue;
	}

	updateResult() {
		this.result = this.rebuildFields(this.result);
	}

	public addField() {
		const tempFieldName = '_' + Object.keys(this.fieldNames).length * Math.random() * 1000;
		this.fieldNames[tempFieldName] = '';
	}

	public removeField(key: string) {
		delete this.fieldNames[key];
	}

	private prepareFieldsForm() {
		if (!this.result) {
			this.result = {};
		}

		this.fieldNames = {};
		for (let key in this.result) {
			this.fieldNames[key] = key;
		}
	}

	private rebuildFields(oldFields: Record<string, string> = {}) {
		const result: Record<string, string> = {};

		for (let key in this.fieldNames) {
			result[this.fieldNames[key]] = oldFields[key];
		}

		return result;
	}
}
