import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, PipeTransform, SimpleChanges } from '@angular/core';
import { FormControlComponent } from '../form-control/form-control.component';
import { MatSelectChange } from '@angular/material/select';
import { FloatLabelType } from '@angular/material/form-field/form-field';
import { TranslateService } from '@ngx-translate/core';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import * as _ from 'lodash';
const optionReference = Symbol('___option___');

@Component({
	selector: 'ws-select',
	templateUrl: './select.component.html',
	styleUrls: ['./select.component.scss'],
})
export class SelectComponent extends FormControlComponent implements OnInit, OnChanges {
	@Input() options!: any[];
	@Input() display: string = 'display';
	@Input() value!: string;
	@Input() minValue!: number;
	@Input() maxValue!: number;
	@Input() panelClass!: string;
	@Input() compareWith: (o1: any, o2: any) => boolean = this.compareFn;
	@Input() multiple!: boolean;
	@Input() floatLabel!: FloatLabelType;
	@Input() optionTranslate!: boolean;
	@Input() translateModule!: string;
	@Input() appearance: MatFormFieldAppearance = 'outline';

	constructor(private translateService: TranslateService, private cdr: ChangeDetectorRef) {
		super();

		// @ts-ignore
		this.value = optionReference;
		this.name = this.name || 'control';
		this.readonly = this.readonly || false;
	}

	ngOnInit() {
		super.ngOnInit();
		if (
			typeof this.result === 'number' &&
			this.options &&
			this.options.length > 0 &&
			typeof this.options[0][this.value] === 'string'
		) {
			this.result = this.result.toString();
		}
	}

	getProperty(option: any, path: string, translate = false) {
		let val = option;

		if (path) {
			// The optionReference symbol will make the entire option object the selected value
			if ((path as unknown as Symbol) === optionReference) {
				return val;
			}

			const dp = path.split('.');
			for (let i = 0; i < dp.length; i++) {
				val = val[dp[i]];
			}
		}

		if (translate && typeof val === 'string' && val.trim() !== '') {
			const translationPath = !!this.translateModule ? `${this.translateModule}.${val}` : val;
			val = this.translateService.instant(`${translationPath}`);
		}
		return val;
	}

	setValue(event: any) {
		this.resultChange.emit(event);
	}

	onSelectionChanged($event: MatSelectChange) {
		this.resultChange.emit($event.value);
	}

	ngOnChanges(changes: SimpleChanges) {
		super.ngOnChanges(changes);
		this.cdr.detectChanges();
	}

	compareFn(val1: any, val2: any) {
		return _.isEqual(val1, val2);
	}
}
