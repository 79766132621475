import { Component, Input } from '@angular/core';

@Component({
	selector: 'ws-entity-slide-modal-header',
	templateUrl: 'entity-slide-modal-header.component.html',
	styleUrls: ['entity-slide-modal-header.component.scss'],
})
export class EntitySlideModalHeaderComponent {
	@Input() isNew!: boolean;
	@Input() title!: string;
}
