import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'ws-simple-text',
	templateUrl: './simple-text.component.html',
	styleUrls: ['./simple-text.component.scss'],
})
export class SimpleTextComponent implements OnInit {
	@Input() data!: any;
	@Input() suffix!: any;
	@Input() dataType = 'int';
	@Input() precision!: any;
	precisionString!: string;

	constructor() {}

	ngOnInit() {
		if (this.dataType === 'double') {
			this.precision = this.precision || 2;
			this.precisionString = '1.' + this.precision + '-' + this.precision;
		}
	}
}
