import {
	Criterion,
	Page,
	PaginationParams,
	SmartTableService,
	HttpResponse,
	ValueTransformer,
	getValueByPath,
} from 'ws-framework';
import { Entity } from '../../types/entity';
import { EntityResource } from '../resources/entity.resource';

export interface ColumnRegistration {
	label: string;
	field: string;
	valueTransformer?: ValueTransformer;
	options?: {
		hideable?: boolean;
		hide?: boolean;
		stackable?: boolean;
		searchable?: boolean;
	};
}

export abstract class EntityService<
	ENTITY extends Entity = {},
	RESOURCE extends EntityResource<ENTITY> = EntityResource<ENTITY>
> extends SmartTableService {
	public static readonly MIN_PARAMETER_PREFIX = 'min_';
	public static readonly MAX_PARAMETER_PREFIX = 'max_';

	protected _entityPage?: Page<ENTITY>;
	protected _entity?: ENTITY;

	protected constructor(protected entityResource: RESOURCE) {
		super();
	}

	public init() {
		super.init();
	}

	public get entity() {
		return Object.freeze(this._entity);
	}

	public get entityPage() {
		return Object.freeze(this._entityPage);
	}

	public getEntityByRowIndex(index: number): ENTITY | undefined {
		return this._entityPage?.content[index];
	}

	public get checkedEntities(): ENTITY[] {
		const checkedCheckboxes = this._indexCheckboxes.filter(({ checkbox }) => checkbox.result);
		return checkedCheckboxes.map(({ index }) => this.getEntityByRowIndex(index)!);
	}

	public registerFields(fields?: Record<string, ColumnRegistration>) {
		this.flushColumns();
		for (let key in fields) {
			const { label, field, valueTransformer, options } = fields[key];
			this.registerColumn(label || key, field, valueTransformer, options);
		}
	}

	public getWssParameters(): PaginationParams {
		const smartParams = super.getParameters();

		return {
			p: smartParams.pagination.page,
			s: smartParams.pagination.page_size,
			f: !smartParams.criteria
				? undefined
				: this.criteriaToString(
						smartParams.criteria.filter((c) => !!c.filter),
						'filter'
				  ),
			o: !smartParams.criteria
				? undefined
				: this.criteriaToString(
						smartParams.criteria.filter((c) => !!c.order),
						'order'
				  ),
		};
	}

	public fetchEntity(_id: string) {
		this._entity = undefined;
		return new Promise<ENTITY>((resolve, reject) => {
			this.entityResource.get({ _id }).then((res) => {
				this._entity = res.body;

				resolve(res.body as unknown as ENTITY);
			});
		});
	}

	public fetchEntities(overrideParams?: PaginationParams) {
		if (!overrideParams) {
			overrideParams = this.getWssParameters();
		}

		this._entityPage = undefined;
		return this.entityResource.getList(overrideParams).then((res) => {
			this._entityPage = res.body;
			if (!!this._entityPage) {
				this.config({
					currentPage: this._entityPage.number,
					totalPages: this._entityPage.totalPages,
					datasetSize: this._entityPage.totalElements,
					pageSize: this._entityPage.size,
				});
			} else {
				this.config({
					currentPage: overrideParams?.p || 0,
					totalPages: 1,
					datasetSize: 0,
					pageSize: overrideParams?.s || 10,
				});
			}
		});
	}

	// Acts as an alias for upsert by default and can be overridden if required
	public async create({ entity }: { entity: ENTITY } | any): Promise<HttpResponse<ENTITY>> {
		return this.upsert({ entity });
	}

	// Acts as an alias for upsert by default and can be overridden if required
	public async update({
		entity,
		oldEntity,
	}: { entity: ENTITY; oldEntity: ENTITY } | any): Promise<HttpResponse<ENTITY>> {
		return this.upsert({ entity, oldEntity });
	}

	public async upsert({
		entity,
		oldEntity,
	}: { entity: ENTITY; oldEntity: ENTITY } | any): Promise<HttpResponse<ENTITY>> {
		return this.entityResource.upsert!(undefined, entity)
			.then((res) => {
				this._entity = res.body;

				return res;
			})
			.then((res) => {
				this.fetchEntities();
				return res;
			});
	}

	public async delete(entity: any, refreshList = false): Promise<any> {
		if (!entity) {
			return Promise.resolve();
		}

		let identifier: string;
		let value;
		if (!Array.isArray(entity)) {
			identifier = this.identifier;
			value = [getValueByPath(entity, identifier)];
		} else {
			value = entity.map((e) => getValueByPath(e, this.identifier));
		}

		const res = await this.entityResource.delete(undefined, value);

		if (refreshList) {
			this.fetchEntities();
		}

		return res;
	}

	protected get identifier() {
		return 'base._id';
	}

	private criteriaToString(criteria: Criterion[], key: string): string | undefined {
		// TODO add support for multiple values per field
		let criteriaString = '';
		for (let criterion of criteria) {
			if (!!criteriaString.length) {
				criteriaString += ',';
			}

			criteriaString += `${criterion[key]}::${criterion.value || ''}`;
		}

		return !!criteriaString.length ? criteriaString : undefined;
	}
}
