import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import * as moment from 'moment';

@Component({
	selector: 'ws-calendar',
	templateUrl: './calendar.component.html',
	styleUrls: ['./calendar.component.scss'],
})
export class CalendarComponent implements OnInit, OnChanges {
	@Input() monthCount!: number;
	@Input() startDate!: string;
	@Input() endDate!: string;
	@Input() mode!: string;

	@Output() startDateChange = new EventEmitter();
	@Output() endDateChange = new EventEmitter();
	@Output() rangeChange = new EventEmitter();

	monthNames: string[] = [
		'Jan.',
		'Feb.',
		'März',
		'April',
		'Mai',
		'Juni',
		'Juli',
		'Aug.',
		'Sep.',
		'Okt.',
		'Nov.',
		'Dez.',
	];
	weekDays: string[] = ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'];
	months: any[] = [];

	chosenMonth = moment().get('month');
	startYear = moment().get('year');

	constructor() {}

	ngOnInit() {
		this.buildCalendar();
	}

	changeMonths(back?: boolean) {
		if (back) {
			this.chosenMonth--;
			if (this.chosenMonth < 0) {
				this.chosenMonth = 11;
				this.startYear--;
			}
		} else {
			this.chosenMonth++;
			if (this.chosenMonth > 11) {
				this.chosenMonth = 0;
				this.startYear++;
			}
		}
		this.months = [];
		this.getMonths();
	}

	getNumberArray(length: number) {
		return new Array(7 - length);
	}

	setDate(day: number, month: number, year: number) {
		const tmpDate = this.formatDate(day, month, year);

		if (this.mode === 'singleSelect') {
			this.startDate = moment(tmpDate, 'DD.MM.YYYY').format();
			this.startDateChange.emit(this.startDate);
		} else if (this.mode === 'rangeSelect') {
			if (this.startDate === undefined) {
				this.startDate = moment(tmpDate, 'DD.MM.YYYY').format();
				this.endDate = moment(tmpDate, 'DD.MM.YYYY').format();
			} else if (moment(this.startDate).isSame(this.endDate)) {
				this.endDate = moment(tmpDate, 'DD.MM.YYYY').format();
				if (moment(this.endDate).isBefore(this.startDate)) {
					const t = this.startDate;
					this.startDate = this.endDate;
					this.endDate = t;
				}
			} else {
				this.startDate = moment(tmpDate, 'DD.MM.YYYY').format();
				this.endDate = moment(tmpDate, 'DD.MM.YYYY').format();
			}
			this.startDateChange.emit(this.startDate);
			this.endDateChange.emit(this.endDate);
			this.rangeChange.emit();
		}
	}

	isStartDate(day: number, month: number, year: number) {
		if (!this.startDate) {
			return false;
		}

		const tmpDate = this.formatDate(day, month, year);
		return (
			(moment(tmpDate, 'DD.MM.YYYY').isSame(this.startDate) &&
				!moment(tmpDate, 'DD.MM.YYYY').isAfter(this.endDate)) ||
			(moment(tmpDate, 'DD.MM.YYYY').isSame(this.endDate) &&
				moment(tmpDate, 'DD.MM.YYYY').isBefore(this.startDate))
		);
	}

	isEndDate(day: number, month: number, year: number) {
		if (!this.endDate === undefined) {
			return false;
		}

		const tmpDate = this.formatDate(day, month, year);
		return (
			(moment(tmpDate, 'DD.MM.YYYY').isSame(this.endDate) &&
				!moment(tmpDate, 'DD.MM.YYYY').isBefore(this.startDate)) ||
			(moment(tmpDate, 'DD.MM.YYYY').isSame(this.startDate) &&
				moment(tmpDate, 'DD.MM.YYYY').isAfter(this.endDate))
		);
	}

	isRangeDate(day: number, month: number, year: number) {
		if (!this.startDate) {
			return false;
		}

		const tmpDate = this.formatDate(day, month, year);
		return (
			moment(tmpDate, 'DD.MM.YYYY').isAfter(this.startDate) &&
			moment(tmpDate, 'DD.MM.YYYY').isBefore(this.endDate)
		);
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (
			changes.startDate &&
			(changes.startDate.currentValue !== this.startDate || changes.startDate.previousValue === null)
		) {
			this.buildCalendar();
		}
	}

	changeYear(back?: any) {
		if (back) {
			this.startYear--;
		} else {
			this.startYear++;
		}
		this.months = [];
		this.getMonths();
	}

	private getMonths() {
		let firstMonth = this.chosenMonth - this.monthCount + 1;
		for (let i = 0; i < this.monthCount; i++) {
			if (firstMonth >= 0) {
				this.months.push(this.getMonth(firstMonth));
			} else {
				const tmp = 11 + firstMonth + 1;
				this.months.push(this.getMonth(tmp, true));
			}
			firstMonth++;
		}
	}

	private getMonth(tmpMonth: number, yearBefore?: boolean) {
		let currentYear = this.startYear;
		if (yearBefore) {
			currentYear--;
		}
		if (tmpMonth > 11) {
			tmpMonth = tmpMonth - 12;
			currentYear++;
		}
		const currentMonth = tmpMonth;
		const firstDay = moment(new Date(currentYear, currentMonth, 1)).day();
		const daysInMonth = moment({ y: currentYear, M: currentMonth }).daysInMonth();

		const month = {
			weeks: [
				{
					days: [],
					first: true,
				},
			],
			month: {
				index: currentMonth,
				name: this.monthNames[currentMonth],
			},
			year: currentYear,
		};

		let tmpDay = 1;
		if (firstDay === 0) {
			// @ts-ignore
			month.weeks[0].days.push(tmpDay);
			tmpDay++;
		} else {
			for (let j = firstDay; j < 8; j++) {
				// @ts-ignore
				month.weeks[0].days.push(tmpDay);
				tmpDay++;
			}
		}
		const numberOfAddedWeeks = Math.floor((daysInMonth - tmpDay + 1) / 7) + 1;
		for (let i = 1; i < numberOfAddedWeeks + 1; i++) {
			for (tmpDay; tmpDay < daysInMonth + 1; tmpDay++) {
				if (month.weeks[i] === undefined) {
					month.weeks[i] = {
						days: [],
						first: false,
					};
				}
				// @ts-ignore
				month.weeks[i].days.push(tmpDay);
				if (month.weeks[i].days.length > 6) {
					tmpDay++;
					break;
				}
			}
		}
		return month;
	}

	private formatDate(day: number, month: number, year: number) {
		const tmpDate = new Date(year, month, day);
		return moment(tmpDate).format('DD.MM.YYYY');
	}

	private buildCalendar() {
		if (this.startDate) {
			const startMonth = moment(this.startDate).month();
			this.startYear = moment(this.startDate).year();
			this.chosenMonth = startMonth + this.monthCount - 1;
		}
		this.months = [];
		this.getMonths();
	}
}
