<mat-error
	class="text-left"
	*ngIf="control && (error || control?.errors?.regexp) && control?.invalid && (control?.touched || control.dirty)"
>
	<ng-container *ngIf="control.errors?.regexp">
		{{ control.errors?.regexp | translate: translatedInterpolationParams }}
	</ng-container>
	<ng-container *ngIf="!control.errors?.regexp">
		{{ errorString | translate: translatedInterpolationParams }}
	</ng-container>
</mat-error>
