import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { deepCopy } from '../../util/helpers';

@Component({
	selector: 'ws-field-error',
	templateUrl: './field-error.component.html',
	styleUrls: ['./field-error.component.scss'],
})
export class FieldErrorComponent implements OnInit {
	@Input()
	public error: string | undefined;
	@Input()
	public control: any;
	@Input() interpolationParams?: Record<string, any>;

	constructor(private translationService: TranslateService) {}

	public get translatedInterpolationParams() {
		if (this.interpolationParams) {
			let translatedParams = Object.assign({});
			Object.keys(this.interpolationParams).forEach((key) => {
				const interpolationParam = deepCopy(this.interpolationParams!);
				interpolationParam[key] = this.translationService.instant(interpolationParam[key]);
				translatedParams = Object.assign(translatedParams, interpolationParam);
			});
			return translatedParams;
		}
		return undefined;
	}

	public get errorString() {
		return this.error || '';
	}

	ngOnInit(): void {}
}
