import { Directive, ElementRef, forwardRef, HostListener, Input, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DecimalToCommaPipe } from '../pipes/decimal-to-comma.pipe';
import { CommaToDecimalPipe } from '../pipes/comma-to-decimal.pipe';

@Directive({
	selector: '[appFormatCurrency]',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => FormatCurrencyDirective),
			multi: true,
		},
	],
})
export class FormatCurrencyDirective implements ControlValueAccessor {
	@Input() format!: string;
	@Input() allowDecimal!: boolean;
	@Input() min!: number;
	@Input() max!: number;

	onChangeCallback = (_: any) => {};
	onTouchedCallback = (_: any) => {};

	private restrict = '[0-9,]';

	private allowedControlKeyCombinationChars = ['v', 'c', 'V', 'C', 'a', 'A', 'x', 'X'];
	private allowedSystemKeyCodes = [8, 9, 13, 16, 18, 20, 27, 42, 43, 44, 45, 46, 37, 39, 35, 36];

	constructor(private _renderer: Renderer2, private _elementRef: ElementRef) {}

	writeValue(value: string) {
		this._renderer.setProperty(this._elementRef.nativeElement, 'value', new DecimalToCommaPipe().transform(value));
	}

	registerOnChange(fn: any) {
		this.onChangeCallback = fn;
	}

	registerOnTouched(fn: any) {
		this.onTouchedCallback = fn;
	}

	@HostListener('blur', ['$event']) onBlur($event: any) {
		this.onTouchedCallback($event);
	}

	@HostListener('keydown', ['$event']) keydown(event: KeyboardEvent) {
		const oldValue = this._elementRef.nativeElement.value;

		let code;
		if (event.keyCode !== undefined) {
			code = event.keyCode;
		} else {
			code = event.which;
		}
		if (this.allowedSystemKeyCodes.indexOf(code) !== -1) {
			return;
		}

		let character;
		if (event.key !== undefined) {
			character = event.key;
		} else {
			character = String.fromCharCode(code);
		}

		if (character === 'Decimal') {
			character = ',';
		}

		if (event.ctrlKey && this.allowedControlKeyCombinationChars.indexOf(character) !== -1) {
			return;
		}

		if (!this.isCharacterValid(character)) {
			event.preventDefault();
			event.stopImmediatePropagation();
		}
		if (this.format) {
			let integerPlaces = 0;
			let decimalPlaces = 0;

			let separatorPosition = this.format.indexOf(',');
			if (separatorPosition === -1) {
				separatorPosition = this.format.indexOf('.');
			}

			if (separatorPosition !== -1) {
				integerPlaces = separatorPosition;
				decimalPlaces = this.format.substring(separatorPosition + 1).length;
			} else {
				integerPlaces = this.format.length;
			}

			// costruct new value
			const caretPosition = this._elementRef.nativeElement.selectionStart;
			const selectionEnd = this._elementRef.nativeElement.selectionEnd;

			let newValue = '';
			if (caretPosition === oldValue.length) {
				newValue = oldValue + character;
			} else {
				newValue = oldValue.substr(0, caretPosition) + character + oldValue.substr(selectionEnd);
			}
			const parts = newValue.split(',');
			if (parts[0].length > integerPlaces || (parts.length > 1 && parts[1].length > decimalPlaces)) {
				event.preventDefault();
				event.stopImmediatePropagation();
			}
		}
	}

	@HostListener('input', ['$event.target.value']) input(value: any) {
		if (value == null || value === '') {
			this._renderer.setProperty(this._elementRef.nativeElement, 'value', null);
			this.onChangeCallback(null);
			return;
		}
		if (value) {
			const sepPosition = value.toString().indexOf(',');
			if (sepPosition > -1 && value.toString().substr(sepPosition + 1).length > 2) {
				value = value.substr(0, sepPosition + 3);
				this._renderer.setProperty(this._elementRef.nativeElement, 'value', value);
			}
			const res = new CommaToDecimalPipe().transform(value);
			// @ts-ignore
			if (value[0] === '0' && value[1] !== ',' && res > 0) {
				value = value.substr(1);
				this._renderer.setProperty(this._elementRef.nativeElement, 'value', value);
			}
			this.onChangeCallback(res);
		}
	}

	@HostListener('paste', ['$event']) paste(event: any) {
		const oldValue = this._elementRef.nativeElement.value;
		const data = event.clipboardData.getData('text');
		let res = '';
		for (let i = 0; i < data.length; i++) {
			if (this.isCharacterValid(data[i])) {
				res += data[i];
			}
		}
		if (this.format) {
			let integerPlaces = 0;
			let decimalPlaces = 0;

			let separatorPosition = this.format.indexOf(',');
			if (separatorPosition === -1) {
				separatorPosition = this.format.indexOf('.');
			}

			if (separatorPosition !== -1) {
				integerPlaces = separatorPosition;
				decimalPlaces = this.format.substring(separatorPosition + 1).length;
			} else {
				integerPlaces = this.format.length;
			}
			const parts = res.split(',');
			if (parts[0].length > integerPlaces || (parts.length > 1 && parts[1].length > decimalPlaces)) {
				event.preventDefault();
				event.stopImmediatePropagation();
				return;
			}
		}
		if (data !== res) {
			event.preventDefault();
			this._renderer.setProperty(this._elementRef.nativeElement, 'value', res);
			const ev = new Event('input', { bubbles: true });
			this._elementRef.nativeElement.dispatchEvent(ev);
		}
	}

	private isCharacterValid(character: any) {
		const restrictExpression = new RegExp(this.restrict);
		return character && restrictExpression.test(character);
	}
}
