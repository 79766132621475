import { Injectable } from '@angular/core';
import { GET, IRestFunc, Rest, RestResource } from '../../../http/RestResource';
import { InjectionBundle } from '../../../http/InjectionBundle';
import { MatchTenantParams } from '../../../tenancy/util/tenant-params';
import { TenantService } from '../../../tenancy/service/tenant.service';

@Injectable({
	providedIn: 'root',
})
@Rest({
	name: 'Image',
	pathPrefix: '/api/v1/images',
})
export class ImageResource extends InjectionBundle {
	@RestResource({
		method: GET,
		path: '/{imageId}',
	})
	get!: IRestFunc<string, MatchTenantParams & { imageId: string }>;

	constructor(tenantService: TenantService) {
		super(tenantService);
	}
}
