import { Component, Input, OnInit } from '@angular/core';
import { FormControlComponent } from '../form-control/form-control.component';

@Component({
	selector: 'ws-radio-group',
	templateUrl: './radio-group.component.html',
	styleUrls: ['./radio-group.component.scss'],
})
export class RadioGroupComponent extends FormControlComponent implements OnInit {
	@Input() value!: string;
	@Input() display!: string;
	@Input() groupClass!: string;
	@Input() elementClass!: string;
	@Input() options!: any[];
	@Input() color: any;
	@Input() tooltips!: any[];

	constructor() {
		super();
	}

	ngOnInit(): void {
		super.ngOnInit();
		this.value = this.value || 'value';
		this.display = this.display || 'display';
	}

	getValue(option: any, path: string) {
		let val = option;
		if (path !== '') {
			const dp = path.split('.');
			for (let i = 0; i < dp.length; i++) {
				val = val[dp[i]];
			}
		}
		return val;
	}

	setValue(event: any) {
		this.resultChange.emit(event);
	}
}
