import { Injectable } from '@angular/core';
import { IRestFunc, Rest, RestResource } from '../http/RestResource';
import { MongoSetting } from '../types/mongo-setting';
import { TenantService } from '../tenancy/service/tenant.service';
import { InjectionBundle } from '../http/InjectionBundle';
import { Setting } from '../types/setting';
import { AuthenticationService } from '../authentication/service/authentication.service';

@Injectable()
@Rest({
	name: 'Settings',
	pathPrefix: '/api/v1/settings',
})
export class SettingsResource extends InjectionBundle {
	@RestResource({
		path: '/key/{key}',
		allowMultiple: true,
	})
	getByKey!: IRestFunc<Setting[], { key: string }>;

	@RestResource({
		path: '/domain/{domain}',
		allowMultiple: true,
	})
	getByDomain!: IRestFunc<Setting[], { domain: string }>;

	@RestResource({
		path: '/{domain}/{key}',
		allowMultiple: true,
	})
	getByDomainAndKey!: IRestFunc<Setting[], { key: string; domain: string }>;

	@RestResource({
		method: 'PUT',
		path: '/',
		allowMultiple: true,
	})
	put!: IRestFunc<Setting, undefined, Setting>;

	constructor(tenantService: TenantService, auth: AuthenticationService) {
		super(tenantService, auth);
	}
}
