import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
	name: 'entityTranslate',
})
export class EntityTranslatePipe implements PipeTransform {
	constructor(private translateService: TranslateService) {}

	transform(value: string, args?: any): any {
		if (args && args.translationPath && !!value && value !== '' && value !== 'undefined') {
			return this.translateService.instant(`${args.translationPath}.${value}`);
		}
		return value;
	}
}

// options should not come from the args:
// TODO make a locale module and use it here to get the displayed locale
// TODO make shop or currency module and get shop currency iso
