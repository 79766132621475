<div class="container">
	<div class="row">
		<div class="col-12 info-panel">
			<div class="panel-head">
				<div *ngIf="isNew">
					{{ 'entities.add' | translate: { entity: (title | translate) } }}
				</div>
				<div *ngIf="!isNew">
					{{ 'entities.edit' | translate: { entity: (title | translate) } }}
				</div>
			</div>
		</div>
	</div>
</div>
