import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { FormControlComponent } from '../form-control/form-control.component';
import { MatFormFieldAppearance } from '@angular/material/form-field';

@Component({
	selector: 'ws-textarea',
	templateUrl: './textarea.component.html',
	styleUrls: ['./textarea.component.scss'],
})
export class TextareaComponent extends FormControlComponent implements OnInit, OnChanges {
	@Input() maxlength: number | undefined;
	@Input() rows: number | undefined;
	@Input() maxRows: number | undefined;
	@Input() appearance: MatFormFieldAppearance = 'outline';

	constructor() {
		super();
	}

	ngOnInit(): void {
		super.ngOnInit();
	}
}
