import { Directive, Input } from '@angular/core';

@Directive()
export abstract class AttributeServiceConsumer<SERVICE> {
	@Input() service!: SERVICE;

	protected constructor(private _injectedService: SERVICE) {}

	protected get attributeService() {
		if (!this.service && !this._injectedService) {
			throw new Error(
				'AttributeServiceConsumer is neither passed a service instance nor was the service found by the injector.'
			);
		}

		return !!this.service ? this.service : this._injectedService;
	}
}
