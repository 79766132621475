import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';
import { ValidationConstraint } from '../../domain/validation-constraint';
import { Directive, Input } from '@angular/core';
import { ValidationService } from '../../services/validation.service';
import { error } from 'ng-packagr/lib/utils/log';

@Directive({
	selector: '[appCustomPatternValidator]',
	providers: [{ provide: NG_VALIDATORS, useExisting: CustomPatternValidatorDirective, multi: true }],
})
export class CustomPatternValidatorDirective implements Validator {
	@Input('appCustomPatternValidator') constraints!: ValidationConstraint[];

	private errorMsg!: string;

	constructor(private validationService: ValidationService) {}

	validate(control: AbstractControl) {
		const validationResult = this.validationService.validate(control.value, null, this.constraints);
		if (!validationResult.valid && validationResult.errorMsg) {
			this.setErrorMsg(validationResult.errorMsg);
		}
		return validationResult.valid ? null : { regexp: validationResult.errorMsg };
	}

	private setErrorMsg(msg: string) {
		this.errorMsg = msg;
	}
}
