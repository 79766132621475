import { forkJoin, Observable, from } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { deepObjectAssign } from '../helpers';

export class ModuleTranslateLoader implements TranslateLoader {
	private fallbackLang = 'en';
	constructor(private http: HttpClient, public prefix: string = '/assets/i18n/', public suffix: string = '.json') {}

	getTranslation(lang: string): Observable<Object> {
		return this.http.get(`${this.prefix}${lang}${this.suffix}`).pipe(
			switchMap((base) => this.mergeModuleTranslationsIntoBase(base, lang)),
			catchError(() =>
				this.http
					.get(`${this.prefix}${this.fallbackLang}${this.suffix}`)
					.pipe(switchMap((base) => this.mergeModuleTranslationsIntoBase(base, this.fallbackLang)))
			)
		);
	}

	private mergeModuleTranslationsIntoBase(base: any, lang: string): Observable<any> {
		// Create a deep copy of the base to avoid mutating the original object
		const mergedTranslation = { ...base };

		return this.http.get<{ modules: string[] }>('/modules/modules.json').pipe(
			switchMap((resModules) => {
				const moduleScopedRequests = resModules.modules.map((module) =>
					this.requestModuleTranslation(lang, module).pipe(
						catchError(() => {
							console.warn(
								`Unable to retrieve scoped translations for module: "${module}" lang: "${lang}"`
							);
							return from([null]);
						})
					)
				);

				const moduleGeneralRequests = resModules.modules.map((module) =>
					this.requestModuleGeneralTranslation(lang, module).pipe(
						catchError(() => {
							console.warn(
								`Unable to retrieve general translations for module: "${module}" lang: "${lang}"`
							);
							return from([null]);
						})
					)
				);

				return forkJoin([forkJoin(moduleScopedRequests), forkJoin(moduleGeneralRequests)]).pipe(
					map(([scopedTranslations, generalTranslations]) => {
						for (let i = 0; i < resModules.modules.length; i++) {
							const moduleKey = `_${resModules.modules[i]}`;
							if (scopedTranslations[i]) {
								mergedTranslation[moduleKey] = deepObjectAssign(
									{ ...scopedTranslations[i] }, // Avoid modifying the original object
									mergedTranslation[moduleKey] ? { ...mergedTranslation[moduleKey] } : {}
								);
							}
							if (generalTranslations[i]) {
								Object.keys(generalTranslations[i]).forEach((g) => {
									mergedTranslation[g] = generalTranslations[i][g];
								});
							}
						}
						return mergedTranslation;
					})
				);
			}),
			catchError((err, caught) => {
				console.warn(err);
				return from([mergedTranslation]);
			})
		);
	}

	private requestModuleTranslation(lang: string, module: string): Observable<any> {
		return this.http.get(`/modules/${module}/assets/i18n/${lang}.json`);
	}

	private requestModuleGeneralTranslation(lang: string, module: string): Observable<any> {
		return this.http.get(`/modules/${module}/assets/i18n/general/${lang}.json`);
	}
}
