<div class="tabs-container">
	<div
		*ngFor="let tab of tabs"
		class="tab"
		[class]="tabClass"
		[ngClass]="isTabActive(tab)"
		(click)="tabChanged.emit(tab.value)"
		[translate]="display(tab)"
	></div>
</div>
<ng-content></ng-content>
