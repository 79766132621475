import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from './service/authentication.service';

@Injectable()
export class AuthGuard implements CanActivate {
	constructor(private router: Router, private authService: AuthenticationService) {}

	canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		if (this.authService.isAuthenticated()) {
			return true;
		}
		// not logged in so redirect to login page
		this.authService.redirectUrl = state.url;
		this.router.navigate(['/login']);
		return false;
	}
}
