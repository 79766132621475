import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
	selector: 'ws-time-picker',
	templateUrl: './time-picker.component.html',
	styleUrls: ['./time-picker.component.scss'],
})
export class TimePickerComponent implements OnInit {
	@Input() result: any;
	@Output() resultChange = new EventEmitter<any>();

	constructor() {}

	ngOnInit(): void {}

	onResultChange($event: any) {
		this.resultChange.emit($event);
	}
}
