<div class="row container-fluid justify-content-between mt-3">
	<div class="d-flex flex-nowrap col-gap-1">
		<ws-button
			*ngIf="!noRefresh"
			styleClass="reset-button"
			type="button"
			text="{{ 'entities.refresh' | translate }}"
			(click)="onRefresh.emit()"
		>
		</ws-button>
		<ng-content select="[left]"></ng-content>
	</div>
	<div class="d-flex flex-nowrap col-gap-1">
		<ng-content select="[right]"></ng-content>
		<ws-button
			*ngIf="!noCreate"
			styleClass="add-button"
			(click)="createAction()"
			text="{{ 'entities.add' | translate: { entity: (entityLabel | translate) } }}"
			matIcon="add"
		></ws-button>
		<ws-button
			*ngIf="!noDelete"
			styleClass="delete-button"
			type="button"
			text="{{ 'entities.delete' | translate }}"
			(click)="onDelete.emit()"
			matIcon="delete"
		>
		</ws-button>
	</div>
</div>
