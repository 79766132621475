<button
	mat-raised-button
	[color]="color"
	[ngClass]="styleClass"
	[type]="type"
	[disabled]="disabled || loading"
	(click)="onClick.emit($event)"
	[routerLink]="routerLink"
>
	<i *ngIf="icon" class="{{ icon }}"></i>
	<mat-icon *ngIf="matIcon" fontSet="{{ matIconFontSet }}">{{ matIcon }}</mat-icon>
	<ng-container *ngIf="text">{{ text | translate }}</ng-container>
	<ng-container *ngIf="!text"><ng-content></ng-content></ng-container>

	<mat-icon *ngIf="loading">
		<mat-progress-spinner diameter="20" mode="indeterminate" strokeWidth="11%"></mat-progress-spinner>
	</mat-icon>
</button>
