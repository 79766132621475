import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';

declare const FileReader: any;

@Component({
	selector: 'ws-img',
	templateUrl: 'image.component.html',
	styleUrls: ['image.component.scss'],
})
export class ImageComponent implements OnInit, OnDestroy {
	@Input() fileChanged$!: Observable<any>;

	public resolvedImage!: string | undefined;

	private subscriptions: Subscription[] = [];

	constructor(public domSanitizer: DomSanitizer) {}

	public resolveImage(file: any) {
		if (file && typeof file !== 'string') {
			const reader = new FileReader();
			reader.onload = (e: any) => {
				this.resolvedImage = e.target.result;
			};
			reader.readAsDataURL(file);
		} else if (file && typeof file === 'string') {
			this.resolvedImage = file;
		} else {
			this.resolvedImage = undefined;
		}
	}

	ngOnInit() {
		if (this.fileChanged$) {
			this.subscriptions.push(this.fileChanged$.subscribe((val) => this.resolveImage(val)));
		}
	}

	ngOnDestroy() {
		this.subscriptions.forEach((s) => s.unsubscribe());
	}
}
