import { Directive } from '@angular/core';
import { AttributeServiceConsumer } from '../util/service-consumer';
import { SmartTableService } from './smart-table.service';

@Directive()
export abstract class SmartTableConsumer extends AttributeServiceConsumer<SmartTableService> {
	protected constructor(smartTableService: SmartTableService) {
		super(smartTableService);
	}

	protected get smartTableService(): SmartTableService {
		return this.attributeService;
	}
}
