import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { FormControlComponent } from '../form-control/form-control.component';

@Component({
	selector: 'ws-input-date',
	templateUrl: './input-date.component.html',
	styleUrls: ['./input-date.component.scss'],
})
export class InputDateComponent extends FormControlComponent implements OnInit, OnChanges {
	@Input() maxDate: any;
	@Input() asRange = false;
	@Input() asMatFormField = false;

	constructor() {
		super();
	}

	public emitRangeChange() {
		if (!!this.result.from && !!this.result.to) {
			this.resultChange.emit(this.result);
		}
	}

	ngOnInit(): void {
		super.ngOnInit();
	}
}
