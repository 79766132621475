import { DialogsService } from './dialogs.service';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { NgModule } from '@angular/core';
import { AlertDialogComponent } from './alert-dialog/alert-dialog.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { ControlsModule } from '../controls/controls.module';
import { SafeHtmlPipe } from '../pipes/safe-html.pipe';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
	declarations: [AlertDialogComponent, ConfirmDialogComponent, SafeHtmlPipe],
	imports: [MatDialogModule, MatButtonModule, ControlsModule, TranslateModule],
	exports: [AlertDialogComponent, MatDialogModule],
	providers: [DialogsService, SafeHtmlPipe],
	entryComponents: [AlertDialogComponent],
})
export class DialogsModule {}
