import { NgModule } from '@angular/core';
import { DeleteCellComponent } from './delete-cell/delete-cell.component';
import { CommonModule } from '@angular/common';
import { ControlsModule } from 'ws-framework';
import { EditCellComponent } from './edit-cell/edit-cell.component';

@NgModule({
	declarations: [DeleteCellComponent, EditCellComponent],
	imports: [CommonModule, ControlsModule],
	providers: [],
	exports: [DeleteCellComponent, EditCellComponent],
})
export class TableUtilsModule {}

export * from './delete-cell/delete-cell.component';
export * from './edit-cell/edit-cell.component';
