import { Injectable, Optional } from '@angular/core';
import { LOCALES } from '../../enums/locales';
import { DateAdapter } from '@angular/material/core';

@Injectable()
export class DateAdapterService {
	constructor(@Optional() private dateAdapter: DateAdapter<any>) {}

	public setLocale(locale: LOCALES) {
		this.dateAdapter.setLocale(locale);
	}
}
