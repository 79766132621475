import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class UtilService {
	public static resolveObject(id: string, options: any[], destObj: any, destName: string, generic?: boolean) {
		for (const option of options) {
			if (generic) {
				if (option[id] === destObj[destName][id]) {
					destObj[destName] = option;
				}
			} else {
				if (option.value[id] === destObj[destName][id]) {
					destObj[destName] = option.value;
				}
			}
		}
	}

	public static getObjectByValue(key: string, value: any, options: any[]) {
		if (options === null || options === undefined) {
			return undefined;
		}
		return options.find((x) => x[key] === value);
	}

	public static getObjByPath(obj: any, path: string) {
		if (obj === undefined || path === undefined || this.isEmpty(obj)) {
			return undefined;
		}
		if (obj[path] !== undefined) {
			return obj[path];
		}
		for (let i = 0, myPath = path.split('.'), len = myPath.length; i < len; i++) {
			if (obj[myPath[i]] === undefined) {
				return undefined;
			}
			obj = obj[myPath[i]];
		}
		return obj;
	}

	public static replaceAll(str: string, replacementMap: any) {
		const re = new RegExp(Object.keys(replacementMap).join('|'), 'g');

		return str.replace(re, function (matched) {
			return replacementMap[matched];
		});
	}

	public static camelCaseToDash(str: string) {
		return str.replace(/([a-zA-Z])(?=[A-Z])/g, '$1-').toLowerCase();
	}

	public static isEmpty(obj: object) {
		for (const key in obj) {
			if (obj.hasOwnProperty(key)) {
				return false;
			}
		}
		return true;
	}

	public static buildHourSelect(minuteStep: number) {
		const hoursSelect = [];

		for (let h = 0; h < 24; h++) {
			const hStr = h < 10 ? '0' + h.toString() : h.toString();
			for (let m = 0; m < 60; m += minuteStep) {
				const mStr = m < 10 ? '0' + m.toString() : m.toString();

				hoursSelect.push({
					value: { hours: h, minutes: m },
					display: hStr + ':' + mStr + ' Uhr',
				});
			}
		}
		return hoursSelect;
	}

	constructor() {}
}
