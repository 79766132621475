import { NgModule } from '@angular/core';
import { ShopCurrencyPipe } from './shopCurrency.pipe';
import { BooleanTranslatePipe } from './boolean-translate.pipe';
import { EntityTranslatePipe } from './entity-translate.pipe';

@NgModule({
	declarations: [ShopCurrencyPipe, BooleanTranslatePipe, EntityTranslatePipe],
	imports: [],
	exports: [ShopCurrencyPipe, BooleanTranslatePipe, EntityTranslatePipe],
	providers: [ShopCurrencyPipe, BooleanTranslatePipe, EntityTranslatePipe],
})
export class ShopPipesModule {}
