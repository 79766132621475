import {
	AuthenticationService,
	InjectionBundle,
	IRestFunc,
	Page,
	PaginationParams,
	Rest,
	RestResource,
	TenantService,
} from 'ws-framework';
import { Injectable } from '@angular/core';
import { ShopTenant } from '../types/tenant';

@Injectable()
@Rest({
	name: 'ShopTenant',
	pathPrefix: '/api/v1/admin/tenant',
})
export class ShopTenantResource extends InjectionBundle {
	@RestResource({
		path: '/',
	})
	getList!: IRestFunc<Page<ShopTenant>, PaginationParams | undefined>;

	constructor(authentication: AuthenticationService) {
		super(authentication);
	}
}
