import { LocalStorage } from '../../util/storage';
import { Tenant } from '../types/tenant';
import { Subject, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { HeaderInjector } from '../../http/InjectionBundle';
import { basename } from '@angular/compiler-cli/src/ngtsc/file_system';
import { NavigationEnd, Router } from '@angular/router';
import { AuthenticationService } from '../../authentication/service/authentication.service';

export interface TenantProvider {
	fetchTenants(): Promise<Tenant[]>;
}

@Injectable()
export class TenantService implements HeaderInjector {
	public static TenantIdHeader = 'tenant-id';

	@LocalStorage({ storageKey: 'tenant-id' }) protected _tenantId?: string;

	private _tenantProvider?: TenantProvider;
	private _initialized = false;
	protected _tenants: Tenant[] = [];
	protected _tenantInitialized = new Subject<{ tenant: Tenant }>();
	protected _tenantChanged = new Subject<{ newTenant: Tenant }>();

	private loadedWithJwt: string = '';

	public constructor(private router: Router, private authService: AuthenticationService) {
		this.router.events.subscribe((e) => {
			if (e instanceof NavigationEnd && !e.url.includes('login')) {
				if (this.loadedWithJwt !== authService.getToken() && !!this._tenantProvider) {
					this.reloadTenants().then(() => (this.loadedWithJwt = this.authService.getToken()));
				}
			}
		});
	}

	public async getHeader() {
		const tenantId = await this.getTenantId();

		return Promise.resolve({ [TenantService.TenantIdHeader]: tenantId! });
	}

	public get initialized() {
		return this._initialized;
	}

	public get tenantChanged$() {
		return this._tenantChanged.asObservable();
	}

	public get tenantInitialized$() {
		return this._tenantInitialized.asObservable();
	}

	public async attachTenantProvider(provider: TenantProvider) {
		if (!!this._tenantProvider) {
			throw new Error('Attempt was made to attach more than one TenantProvider.');
		}

		this._tenantProvider = provider;

		this.initTenantList();
	}

	public get tenantId() {
		return this._tenantId;
	}

	public getTenantId(): Promise<string> {
		if (!this._initialized) {
			return new Promise((resolve) => {
				this.tenantInitialized$.subscribe(({ tenant: { id } }) => {
					resolve(id.toString());
				});
			});
		}

		return Promise.resolve(this._tenantId!);
	}

	public get tenant() {
		return this.tenants.find((t) => t.id.toString() === this._tenantId);
	}

	public getTenant() {
		if (!!this._initialized) {
			return new Promise((resolve) => {
				this.tenantInitialized$.subscribe(({ tenant }) => {
					resolve(tenant);
				});
			});
		}

		return this.tenants.find((t) => t.id.toString() === this._tenantId);
	}

	public get tenants() {
		return this._tenants;
	}

	public selectTenant(tenantId: string) {
		if (this._tenants.find((t) => t.id.toString() === tenantId)) {
			this._tenantId = tenantId;

			this._tenantChanged.next({ newTenant: this.tenant! });
		}
	}

	public async reloadTenants() {
		return this.initTenantList();
	}

	protected async fetchTenants(): Promise<Tenant[]> {
		return (await this._tenantProvider?.fetchTenants()) || Promise.resolve([]);
	}

	private async initTenantList() {
		try {
			this._tenants = await this.fetchTenants();
			this._tenantId = this.initTenantId(this._tenants, this.tenantId);
			this._initialized = true;
			this._tenantInitialized.next({ tenant: this.tenant! });
		} catch (e) {
			throw Error('Failed to load tenant list.');
		}
	}

	private initTenantId(tenants: Tenant[], wish?: string): string {
		if (!tenants?.length) {
			throw Error('Tenant list is invalid.');
		}

		// If a tenant id was recovered from storage validate it. If not or if it cannot be validated default to first in list
		return !!wish && tenants.find((t) => t.id.toString() === wish) ? wish : tenants[0]!.id!.toString();
	}
}
