import { RestBundle } from './RestBundle';
import { RestHeader } from './RestResource';

export abstract class InjectionBundle extends RestBundle {
	protected readonly headerInjections;

	constructor(...headerInjections: HeaderInjector[]) {
		super();

		this.headerInjections = headerInjections;

		this._options.dynamicHeaders = () => this.dynamicHeaders();
	}

	protected async dynamicHeaders(): Promise<RestHeader> {
		let headers = {};

		for (let injector of this.headerInjections) {
			headers = Object.assign(headers, await injector.getHeader());
		}

		return Promise.resolve(headers);
	}
}

export interface HeaderInjector {
	getHeader(): Promise<RestHeader>;
}
