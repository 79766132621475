export class HttpResponse<M = unknown> {
	public readonly body?: M;

	public readonly bodyUsed?: boolean;

	public readonly headers?: Headers;

	public readonly ok?: boolean;

	public readonly redirected?: boolean;

	public readonly status?: number;

	public readonly statusText?: string;

	public readonly type?: string;

	public readonly url?: string;

	public readonly timedOut?: boolean;
}
