import { NgModule } from '@angular/core';
import { CONFIGURATION_CLASSES } from 'ws-framework';
import { ShopTenantService } from './service/shop-tenant.service';
import { ShopTenantResource } from './resources/shop-tenant.resource';

@NgModule({
	declarations: [],
	imports: [],
	exports: [],
	providers: [
		ShopTenantService,
		ShopTenantResource,
		{ provide: CONFIGURATION_CLASSES, useExisting: ShopTenantService, multi: true },
	],
})
export class ShopTenantModule {}

export * from './service/shop-tenant.service';
