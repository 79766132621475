import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { ValidationConstraint } from '../domain/validation-constraint';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
	providedIn: 'root',
})
export class ValidationService {
	constructor(private translationService: TranslateService) {}

	validate(controlValue: any, context: object | null, validationConstraints: ValidationConstraint[]) {
		const result: { valid: boolean; errorMsg?: string } = { valid: true, errorMsg: undefined };
		if (!!validationConstraints) {
			for (const constraint of validationConstraints) {
				if (result.valid) {
					result.valid = this._validate(controlValue, constraint);
				}
				switch (constraint.pattern) {
					case 'TRUE':
						constraint.errorMessage = { message: '_ws-framework.validation.please-confirm' };
						break;
					case 'NOT_NULL':
						constraint.errorMessage = { message: '_ws-framework.validation.provide-information' };
						break;
					case 'NOT_EMPTY':
						constraint.errorMessage = { message: '_ws-framework.validation.provide-information' };
						break;
					case 'NOT_BLANK':
						constraint.errorMessage = { message: '_ws-framework.validation.provide-information' };
						break;
					case 'EMAIL':
						constraint.errorMessage = { message: '_ws-framework.validation.invalid-email-address' };
						break;
					case 'EQUAL':
						const interpolation = (constraint.arguments as Record<string, any>)?.interpolation;
						constraint.errorMessage = {
							message: '_ws-framework.validation.not-equal',
							interpolation: { equalVal: interpolation },
						};
						break;
					default:
						constraint.errorMessage = { message: '_ws-framework.validation.please-check' };
				}
				if (this._validate(controlValue, constraint)) {
					delete constraint.errorMessage;
				}
				if (!result.errorMsg && !result.valid && !!constraint.errorMessage) {
					result.errorMsg = constraint.errorMessage.message;
				}
			}
		}

		return result;
	}

	private isConditionValid(constraint: ValidationConstraint, objToValidate: any) {
		if (!constraint.condition || !objToValidate) {
			return true;
		}

		const obj = _.get(objToValidate, constraint.condition);

		// Special case when we need to compare e.g. null number and empty condition value
		if (constraint.conditionValue === '' && !obj) {
			return true;
		}

		return obj && obj.toString() === constraint.conditionValue;
	}

	private _validate(controlValue: any, constraint: ValidationConstraint): boolean {
		let valid: boolean = true;
		switch (constraint.pattern) {
			case 'NULL':
				valid = controlValue === null || controlValue === undefined || controlValue === '';
				break;
			case 'NOT_NULL':
				valid = controlValue !== null && controlValue !== undefined;
				break;
			case 'NOT_EMPTY':
				valid =
					controlValue !== null &&
					controlValue !== undefined &&
					controlValue !== '' &&
					!(Array.isArray(controlValue) && controlValue.length === 0);
				break;
			case 'EMAIL':
				if (!controlValue) {
					valid = true;
				} else {
					const mailRegexp = new RegExp('^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$');
					valid = mailRegexp.test(controlValue);
				}
				break;
			case 'MIN':
				if (constraint.arguments) {
					const minValue = parseInt(constraint.arguments as string, 10);
					if (controlValue < minValue) {
						valid = false;
					}
				}
				break;
			case 'MAX':
				if (constraint.arguments) {
					const maxValue = parseInt(constraint.arguments as string, 10);
					if (controlValue > maxValue) {
						valid = false;
					}
				}
				break;
			case 'RANGE':
				if (constraint.arguments) {
					const arr = constraint.arguments.split(';');
					if (arr.length === 2) {
						const minValue = parseInt(arr[0], 10);
						const maxValue = parseInt(arr[1], 10);
						if (controlValue < minValue || controlValue > maxValue) {
							valid = false;
						}
					}
				}
				break;
			case 'MIN_LENGTH':
				if (constraint.arguments) {
					const minLength = parseInt(constraint.arguments as string, 10);
					valid = controlValue && controlValue.length >= minLength;
				}
				break;
			case 'MAX_LENGTH':
				if (constraint.arguments) {
					const maxLength = parseInt(constraint.arguments as string, 10);
					valid = !controlValue || controlValue.length <= maxLength;
				}
				break;
			case 'TRUE':
				valid = (controlValue && typeof controlValue === 'boolean') || typeof controlValue !== 'boolean';
				break;
			case 'NOT_BLANK':
				if (!constraint.arguments) {
					valid = !!controlValue && controlValue.toString().trim() !== '';
				} else {
					valid =
						!!controlValue &&
						!!controlValue[constraint.arguments as string] &&
						controlValue[constraint.arguments as string].toString().trim() !== '';
				}
				break;
			case 'EQUAL':
				valid = _.isEqual(controlValue, (constraint?.arguments as Record<string, any>)?.compareVal);
				break;
			case 'LIST_CONTAINS':
				valid = true;

				for (const arg of constraint?.arguments?.split('~') || []) {
					let found = false;
					for (const value of controlValue) {
						if (arg === value) {
							found = true;
							break;
						}
					}
					if (!found) {
						valid = false;
						break;
					}
				}

				break;
			default:
				const re = new RegExp(constraint.pattern as string);
				valid = re.test(controlValue);
		}

		return valid;
	}
}
