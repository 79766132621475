<div class="calendar-row clearfix">
	<div class="calendar-navigation">
		<button (click)="changeYear(true)" class="calendar-back-year" matTooltip="ein Jahr zurückgehen"><<</button>
		<button (click)="changeMonths(true)" class="calendar-back" matTooltip="einen Monat zurückgehen"><</button>
		<button (click)="changeYear()" class="calendar-next-year" matTooltip="einen Monat vor">>></button>
		<button (click)="changeMonths()" class="calendar-next" matTooltip="ein Jahr vor">></button>
	</div>
	<div class="calendar" *ngFor="let item of months; let monthIndex = index">
		<table aria-hidden="true" class="md-calendar-day-header">
			<thead>
				<tr>
					<th *ngFor="let weekDay of weekDays; let dayIndex = index">{{ weekDay }}</th>
				</tr>
			</thead>
		</table>
		<table role="grid" tabindex="0" class="md-calendar" aria-readonly="true">
			<tbody role="rowgroup" class="md-calendar-month ng-scope ng-isolate-scope">
				<tr role="row" aria-label="Week undefined">
					<td class="md-calendar-month-label" colspan="7">{{ item.month.name }} {{ item.year }}</td>
				</tr>
				<tr role="row" aria-label="Week" *ngFor="let week of item.weeks; let weekIndex = index">
					<ng-container *ngIf="week.first">
						<td
							tabindex="-1"
							class="md-calendar-date"
							role="gridcell"
							*ngFor="let num of getNumberArray(week.days.length); let dayIndex = index"
						></td>
					</ng-container>
					<td
						tabindex="-1"
						class="md-calendar-date"
						role="gridcell"
						*ngFor="let day of week.days; let weekDayIndex = index"
					>
						<span
							class="md-calendar-date-selection-indicator"
							[ngClass]="{
								startDate: isStartDate(day, item.month.index, item.year),
								endDate: isEndDate(day, item.month.index, item.year),
								rangeColor: isRangeDate(day, item.month.index, item.year)
							}"
							(click)="setDate(day, item.month.index, item.year)"
							>{{ day }}</span
						>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>
