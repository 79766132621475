<div class="sort-label" title="{{ displayName }}">
	<ng-content></ng-content>
	&nbsp; &nbsp;
	<span class="sort-indicator-wrapper">
		<i
			[ngClass]="{
				'fa fa-caret-up': true,
				'sort-indicator': true,
				'sort-idle': sortDirection !== 'ASC',
				'sort-hidden': sortDirection === 'DESC'
			}"
		></i>
		<i
			[ngClass]="{
				'fa fa-caret-down': true,
				'sort-indicator': true,
				'sort-idle': sortDirection !== 'DESC',
				'sort-hidden': sortDirection === 'ASC'
			}"
		></i>
	</span>
</div>
