import { property } from 'lodash';

declare const sessionStorage: any;
declare const localStorage: any;

export interface ScopedStorageConfig {
	defaultValueConfig?: any;
	storageKey?: string;
	scopeByTargetClass?: boolean;
}

export interface StorageConfig extends ScopedStorageConfig {
	storageResolver: StorageResolver;
}

export interface StorageResolver {
	storage: 'session' | 'local';
	storageKey?: string;
	defaultStorage?: 'session' | 'local';
}

export function Storage(config: StorageConfig): any;
export function Storage(config: StorageConfig, superTarget?: any, unsetOrProperty?: any): any;
export function Storage(config: StorageConfig, superTarget?: any, unsetOrProperty?: any) {
	if (!!superTarget || unsetOrProperty) {
		return defineStorageObject(config, superTarget, unsetOrProperty);
	}
	return function (target: Object, property: string) {
		defineStorageObject(config, target, property);
	};
}

export function SessionStorage(target: Object, property: string): any;
export function SessionStorage(config: ScopedStorageConfig): any;

export function SessionStorage(superTargetOrOverrideKey: any, unsetOrTargetProperty?: string) {
	const storageResolver: StorageResolver = { storage: 'session' };
	if (!isStorageConfig(superTargetOrOverrideKey)) {
		return Storage(
			{ storageResolver: storageResolver, scopeByTargetClass: true },
			superTargetOrOverrideKey,
			unsetOrTargetProperty
		);
	}
	return Storage({ ...superTargetOrOverrideKey, storageResolver: storageResolver });
}

export function LocalStorage(target: Object, property: string): any;
export function LocalStorage(config: ScopedStorageConfig): any;

export function LocalStorage(superTargetOrOverrideKey: any, unsetOrTargetProperty?: string) {
	const storageResolver: StorageResolver = { storage: 'local' };
	if (!isStorageConfig(superTargetOrOverrideKey)) {
		return Storage(
			{ storageResolver: storageResolver, scopeByTargetClass: true },
			superTargetOrOverrideKey,
			unsetOrTargetProperty
		);
	}
	return Storage({ ...superTargetOrOverrideKey, storageResolver: storageResolver });
}

// Type guard to check if an object adheres to the StorageConfig interface
function isStorageConfig(object: any): object is StorageConfig {
	return (
		typeof object === 'object' &&
		object !== null &&
		(object.defaultValueConfig || object.storageKey || object.storageResolver)
	);
}

function isOnlyDefaultValue(storageConfig: any) {
	return isStorageConfig(storageConfig) && !!storageConfig.defaultValueConfig && !storageConfig.storageKey;
}

function defineStorageObject(storageConfig: StorageConfig, superTarget: any, property: any) {
	let { storageKey, storageResolver, defaultValueConfig, scopeByTargetClass } = storageConfig;

	Object.defineProperty(superTarget, property, {
		get: function () {
			const target = this;
			let storageValue = getStorageValue(
				storageResolver,
				target,
				storageKey || property,
				scopeByTargetClass || false
			);

			return convertStorageValue(storageValue, defaultValueConfig);
		},
		set: function (v) {
			const target = this;

			const type = typeof v;
			const storageValue = JSON.stringify({ type: type, value: convertToString(v) });

			setStorageValue(storageResolver, target, storageKey || property, scopeByTargetClass || false, storageValue);
		},
	});
}

function convertStorageValue(storageValue: any, defaultValue: any) {
	let value;
	if (!!storageValue) {
		storageValue = JSON.parse(storageValue);
		value = castValueToType(storageValue.type, storageValue.value);
	} else {
		value = defaultValue;
	}

	return value;
}

function castValueToType(type: string, value: any) {
	if (value === null) return null;
	if (value === undefined) return undefined;

	switch (type) {
		case 'string':
			return String(value);
		case 'number':
			return Number(value);
		case 'boolean':
			// Simple conversion might not be enough for strings like "false" or "0"
			if (typeof value === 'string') {
				value = value.trim().toLowerCase();
				return !(value === 'false' || value === '0' || value === '');
			}
			return Boolean(value);
		case 'symbol':
			return Symbol(value);
		case 'object':
			// Check if the value is already an object or array (not null and not a JSON string)
			if (value && typeof value === 'object') {
				return value;
			}
			try {
				// Try to parse it as a JSON string to an object
				return JSON.parse(value);
			} catch (error) {
				console.error('Error parsing JSON:', error);
				return null; // Return null if parsing fails
			}
		default:
			console.warn('Unsupported type for casting.');
			return value;
	}
}

function convertToString(value: any) {
	switch (typeof value) {
		case 'undefined':
			return 'undefined';
		case 'number':
		case 'boolean':
		case 'bigint':
			return value.toString();
		case 'string':
			return value; // Already a string, return as is.
		case 'symbol':
			return value.toString(); // Convert symbol to string like 'Symbol(mySymbol)'
		case 'object':
			if (value === null) {
				return 'null'; // Handle null case in object, since typeof null is 'object'
			} else if (Array.isArray(value)) {
				return JSON.stringify(value); // Convert array to JSON string
			} else {
				try {
					return JSON.stringify(value); // Convert object to JSON string
				} catch (error) {
					console.error('Error converting object to JSON string:', error);
					return ''; // Return empty string if conversion fails
				}
			}

		case 'function':
			return value.toString(); // Convert function to string containing the function's code

		default:
			return ''; // For any other cases that might have been missed
	}
}

function getTargetClassPrefix(target: Object) {
	return (target?.constructor?.name || 'foo') + '__';
}

function getStorageValue(storageResolver: StorageResolver, target: any, storageKey: any, scopeByTargetClass: boolean) {
	const storage = resolveStorage(storageResolver);

	if (!storage) {
		console.error(
			`No Storage could be resolved. Can not get ${
				(scopeByTargetClass ? getTargetClassPrefix(target) : '') + storageKey
			}`
		);
		return;
	}

	return storage.getItem((scopeByTargetClass ? getTargetClassPrefix(target) : '') + storageKey);
}

function setStorageValue(storageResolver: any, target: any, storageKey: any, scopeByTargetClass: boolean, value: any) {
	const storage = resolveStorage(storageResolver);

	if (!storage) {
		console.error(
			`No Storage could be resolved. Can not set ${
				(scopeByTargetClass ? getTargetClassPrefix(target) : '') + storageKey
			} to ${value}`
		);
		return;
	}

	storage.setItem((scopeByTargetClass ? getTargetClassPrefix(target) : '') + storageKey, value);
}

function resolveStorage(storageResolver: StorageResolver) {
	let storage = storageResolver.storage === 'local' ? localStorage : sessionStorage;
	if (!storageResolver.storageKey) {
		return storage;
	}

	let value = storage.getItem(storageResolver.storageKey);
	value = convertStorageValue(value, undefined);
	if (value !== 'local' && value !== 'session' && !storageResolver.defaultStorage) {
		console.error(
			`StorageResolver couldn't resolve storage type for storage key ${storageResolver.storageKey} in storage ${storageResolver.storage}. ${value} does not match 'local' or 'session'`
		);
		return undefined;
	} else if (value !== 'local' && value !== 'session') {
		return !!storageResolver.defaultStorage && storageResolver.defaultStorage === 'local'
			? localStorage
			: sessionStorage;
	}

	return value === 'local' ? localStorage : sessionStorage;
}
