import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'ws-legend',
	templateUrl: './legend.component.html',
	styleUrls: ['./legend.component.scss'],
})
export class LegendComponent implements OnInit {
	@Input() colors!: string[];
	@Input() labels!: string[];
	@Input() width!: string;

	constructor() {}

	ngOnInit() {}
}
