import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'ws-entity-detail-header',
	templateUrl: './entity-detail-header.component.html',
	styleUrls: ['./entity-detail-header.component.scss'],
})
export class EntityDetailHeaderComponent {
	@Output() onReset: EventEmitter<any> = new EventEmitter();
	@Output() onDelete: EventEmitter<any> = new EventEmitter();
	@Input() backPath: string = this.automaticBackPath;

	@Input() noBack = false;
	@Input() noSave = false;
	@Input() noReset = false;
	@Input() noDelete = false;

	constructor() {}

	get automaticBackPath() {
		return '';
	}
}
