import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { AuthGuard } from 'ws-framework';
import { DetectableRoutes } from './util/routing/AutomatedNavigation';

const routes: DetectableRoutes = [
	{
		path: 'login',
		loadChildren: () => import('./auth/login/login.module').then((m) => m.LoginModule),
		hideInMain: true,
	},
	{
		path: 'login/change-password',
		loadChildren: () => import('./auth/login/login.module').then((m) => m.LoginModule),
		hideInMain: true,
	},
	{
		path: '',
		loadChildren: () => import('./stage.module').then((m) => m.StageModule),
		canActivate: [AuthGuard],
	},
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			preloadingStrategy: PreloadAllModules,
			onSameUrlNavigation: 'reload',
		}),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}
