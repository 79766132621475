import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RestrictDirective } from './restrict.directive';
import { CustomPatternValidatorDirective } from './validators/custom-pattern-validator.directive';
import { FormatCurrencyDirective } from './format-currency.directive';
import { ViewModeDirective } from './view-mode.directive';
import { EditModeDirective } from './edit-mode.directive';
import { EditableOnEnterDirective } from './editable-on-enter.directive';
import { DragDropFileUploadDirective } from './drag-drop-file-upload.directive';

@NgModule({
	declarations: [
		RestrictDirective,
		CustomPatternValidatorDirective,
		FormatCurrencyDirective,
		ViewModeDirective,
		EditModeDirective,
		EditableOnEnterDirective,
		DragDropFileUploadDirective,
	],
	imports: [CommonModule],
	exports: [
		RestrictDirective,
		CustomPatternValidatorDirective,
		FormatCurrencyDirective,
		ViewModeDirective,
		EditModeDirective,
		EditableOnEnterDirective,
		DragDropFileUploadDirective,
	],
})
export class DirectivesModule {}
