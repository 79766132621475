import { ShopResource } from '../resources/shop.resource';
import { Injectable, Injector } from '@angular/core';
import { ExpansionService, MongoSetting, Setting } from 'ws-framework';
import { Shop, ShopConfig } from '../types/shop';
import { MongoSettingsResource, SettingsResource } from 'ws-framework';
import { EntityService } from '../../entity/services/entity.service';
import { RegisterColumns } from '../../types/register-columns';
import { ShopTenantService } from '../../shop-tenancy/service/shop-tenant.service';

@Injectable()
export class ShopService extends EntityService<Shop, ShopResource> implements RegisterColumns {
	private _shopConfig?: MongoSetting<ShopConfig>;
	private _mongodbTemplateOptions?: Setting[];
	private _mongodbHostOptions?: Setting[];

	constructor(
		private mongoSettingsResource: MongoSettingsResource,
		private settingsResource: SettingsResource,
		shopResource: ShopResource,
		expansionService: ExpansionService,
		injector: Injector
	) {
		super(shopResource);
		this.init();

		this.registerFields({
			id: { label: '_shop-settings.shop.id', field: 'id' },
			name: {
				label: '_shop-settings.shop.name',
				field: 'label',
			},
		});
	}

	public init() {}

	public get shopConfig() {
		return this._shopConfig?.public || ({} as ShopConfig);
	}

	public get mongoTemplateOptions() {
		return this._mongodbTemplateOptions || [];
	}

	public get mongoHostOptions() {
		return this._mongodbHostOptions || [];
	}

	public async create({
		entity,
		shopConfig,
		mongodbTemplate,
	}: {
		entity: Shop;
		shopConfig: ShopConfig;
		mongodbTemplate: { host: string; port: number; name: string };
	}) {
		const { host, port, name } = mongodbTemplate;

		entity.label = shopConfig.name!;
		const res = await this.entityResource
			.create(
				undefined,
				Object.assign(entity, {
					templateDBHost: host,
					templateDBPort: port,
					templateDBName: name,
				})
			)
			.then((res) => {
				this._entity = res.body;

				return res;
			})
			.then((res) => {
				this.fetchEntities();
				return res;
			});
		const tenantId = res!.body!.id;

		return this.upsert({ entity, shopConfig, tenantId });
	}

	public async update({ entity, shopConfig }: { entity: Shop; shopConfig: ShopConfig }) {
		entity.label = shopConfig.name!;

		const res = await super.upsert({ entity, oldEntity: this._entity });
		const tenantId = res!.body!.id;

		return this.upsert({ entity, shopConfig, tenantId });
	}

	public async upsert({ entity, shopConfig, tenantId }: { entity: Shop; shopConfig: ShopConfig; tenantId: any }) {
		// relies on create or update being called first

		return this.mongoSettingsResource
			.put(
				undefined,
				{ public: shopConfig, key: 'shop_config' },
				{ [ShopTenantService.TenantIdHeader]: tenantId }
			)
			.then((res) => {
				return res;
			});
	}

	public fetchShopConfig(id: string) {
		this._shopConfig = undefined;

		return this.mongoSettingsResource
			.get({ key: 'shop_config' }, undefined, { [ShopTenantService.TenantIdHeader]: id })
			.then((res) => {
				this._shopConfig = res.body;

				return res;
			});
	}

	public async fetchMongoOptions() {
		const templatesResponse = await this.settingsResource.getByDomain({ domain: 'mongoTemplate' });
		this._mongodbTemplateOptions = templatesResponse.body;

		const hostsResponse = await this.settingsResource.getByDomain({ domain: 'mongoHost' });
		this._mongodbHostOptions = hostsResponse.body;

		return Promise.resolve({
			mongodbTemplateOptions: this._mongodbTemplateOptions,
			mongodbHostOptions: this._mongodbHostOptions,
		});
	}
}
