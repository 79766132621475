import { Injectable } from '@angular/core';
import { IRestFunc, Rest, RestResource } from '../http/RestResource';
import { MongoSetting } from '../types/mongo-setting';
import { TenantService } from '../tenancy/service/tenant.service';
import { InjectionBundle } from '../http/InjectionBundle';
import { AuthenticationService } from '../authentication/service/authentication.service';

@Injectable()
@Rest({
	name: 'MongoSettings',
	pathPrefix: '/api/v1/admin/settings',
})
export class MongoSettingsResource extends InjectionBundle {
	@RestResource({
		path: '/{key}',
		allowMultiple: true,
	})
	get!: IRestFunc<any, { key: string }>;

	@RestResource({
		method: 'PUT',
		path: '',
		allowMultiple: true,
	})
	put!: IRestFunc<any, undefined, MongoSetting>;

	constructor(tenantService: TenantService, auth: AuthenticationService) {
		super(tenantService, auth);
	}
}
