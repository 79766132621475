import { Component, HostListener, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'ws-alert-dialog',
	templateUrl: './alert-dialog.component.html',
	styleUrls: ['./alert-dialog.component.scss'],
})
export class AlertDialogComponent {
	title: string;
	message: string;
	buttonText: string;
	type: string;

	constructor(public dialogRef: MatDialogRef<AlertDialogComponent>, @Inject(MAT_DIALOG_DATA) data: any) {
		this.title = data.title;
		this.message = data.message;
		this.buttonText = data.config && data.config.button ? data.config.button : 'OK';
		this.type = (data.config && data.config.type) || 'regular';
	}

	@HostListener('keydown.enter', ['$event']) close(event: KeyboardEvent) {
		event.preventDefault();
		event.stopImmediatePropagation();
		event.stopPropagation();
		this.dialogRef.close(true);
	}
}
