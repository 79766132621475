import { Component } from '@angular/core';

@Component({
	template: '',
})
export abstract class TemplateUtils {
	public readonly Array = Array;
	public readonly JSON = JSON;

	public readonly Number = Number;

	protected constructor() {}

	public get Object() {
		return Object;
	}

	public typeof(obj: any): string {
		return typeof obj;
	}

	public isNumber(value: unknown) {
		return typeof value === 'number' || (typeof value === 'string' && value.match(/^[0-9]+$/));
	}
}
