import { HttpResponse } from './HttpResponse';
import { RestBundle } from './RestBundle';

export const GET = 'GET',
	POST = 'POST',
	PUT = 'PUT',
	PATCH = 'PATCH',
	DELETE = 'DELETE';

export type RestMethod = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';

export interface IInterceptor {
	(
		res: HttpResponse<unknown>,
		resolve?: (res: HttpResponse<unknown> | PromiseLike<HttpResponse<unknown>>) => any,
		reject?: (res: HttpErrorResponse) => any,
		options?: any
	): any;
}

export interface RestHeader {
	[name: string]: string;
}

export interface RestParams {
	[name: string]: string | number | Array<string | number>;
}

export interface ProxyConfig {
	proxy: ProxyRoutes;
	noManualRouting: boolean;
}

interface ProxyRoutes {
	[name: string]: ProxyRoute;
}

interface ProxyRoute {
	target: string;
	secure?: boolean;
	changeOrigin?: boolean;
	pathRewrite?: { [name: string]: string };
}

export const UnsetMatchHeader = Symbol('UNSET');
export const IsMatchHeader = Symbol('MatchHeader');

export abstract class MatchHeaderParams {
	readonly [IsMatchHeader] = IsMatchHeader;

	protected constructor(headerFields: string | Array<string>) {
		if (Array.isArray(headerFields)) {
			for (const field of headerFields) {
				this[field] = UnsetMatchHeader;
			}
		}
		this[headerFields as string] = UnsetMatchHeader;
	}
}

export interface MatchHeaderParams {
	[name: string]: string | number | (string | number)[] | symbol;
}

export interface IRestOptions {
	timeout?: number; // milliseconds
	ssr?: boolean;
	client?: boolean;
	staticHeaders?: RestHeader;
	interceptors?: Array<IInterceptor>;
	dynamicHeaders?(): Promise<RestHeader>;
	onError?(response: HttpResponse<unknown>): void;
	onSuccess?(response: HttpResponse<unknown>): void;
}

export interface IResourceOptions extends IRestOptions {
	path?: string;
	ignorePathAffixes?: boolean;
	ignoreBundleHeaders?: boolean;
	allowMultiple?: boolean;
	method?: RestMethod;
}

export interface IBundleOptions extends IRestOptions {
	name: string;
	pathPrefix?: string;
	pathSuffix?: string;
}

export function Rest(options: IBundleOptions) {
	return (target: any) => {
		target.prototype._options = options;
	};
}

export function RestResource(options: IResourceOptions): (target: RestBundle, resourceName: string) => void {
	return (target: RestBundle, resourceName: string) => {
		// @ts-ignore
		target[resourceName] = (...args) => {
			let [params, body, headers] = args;

			return target.makeRequest(options, params, headers, body, resourceName);
		};
		// @ts-ignore
		Object.defineProperty(target[resourceName], 'path', {
			enumerable: false,
			writable: false,
			configurable: false,

			value: (params: RestParams) => {
				return target
					.buildHeaders(options)
					.then((headers) => target.buildQueryPath(options, params, resourceName, headers))
					.catch();
			},
		});
	};
}

export interface CancelablePromise<T> extends Promise<T>, CancelHandle {}

export interface CancelHandle {
	cancel(): void;
	readonly name: string;
	readonly bundleName: string;
}

export interface HttpErrorResponse {
	readonly error?: string;
	readonly ok?: boolean;
	readonly stackTrace?: string;
	readonly validationErrors?: Array<unknown>;
	readonly body?: ErrorDTO;
	readonly status?: number;
	readonly statusText: string;
}

export interface ErrorDTO {
	error?: string;
	errorCode?: string;
}

interface RestProps<Parameters> {
	path(params: Parameters): string;
}

export interface IRestFunc<
	Model = unknown,
	Parameters = undefined | RestParams,
	Body = undefined | Object,
	IRestHeader = undefined | RestHeader
> extends RestProps<Parameters> {
	(params: Parameters, body: Body, headers: IRestHeader): CancelablePromise<HttpResponse<Model>>;
	(params: Parameters, body: Body): CancelablePromise<HttpResponse<Model>>;
	(params: Parameters): CancelablePromise<HttpResponse<Model>>;
	(): CancelablePromise<HttpResponse<Model>>;
}
