import { NgModule } from '@angular/core';
import { ColumnSelectorComponent } from './column-selector/column-selector.component';
import { SmartColumnComponent } from './smart-column/smart-column.component';
import { SmartSearchInputComponent } from './smart-search-input/smart-search-input.component';
import { SmartTableDirective } from './smart-table.directive';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SmartCellComponent } from './smart-cell/smart-cell.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
	declarations: [
		SmartTableDirective,
		ColumnSelectorComponent,
		SmartColumnComponent,
		SmartCellComponent,
		SmartSearchInputComponent,
	],
	imports: [FormsModule, CommonModule, TranslateModule],
	providers: [],
	exports: [
		SmartTableDirective,
		ColumnSelectorComponent,
		SmartColumnComponent,
		SmartCellComponent,
		SmartSearchInputComponent,
	],
})
export class SmartTableModule {}

export * from './smart-table.directive';
export * from './smart-table.service';
export * from './smart-search-input/smart-search-input.component';
export * from './smart-column/smart-column.component';
export * from './smart-cell/smart-cell.component';
export * from './column-selector/column-selector.component';
export * from './smart-table-consumer';
