import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'affix'
})
export class AffixPipe implements PipeTransform {
	transform(value: string, args = { prefix: '', suffix: '' }): string {
		// Combine prefix, value, and suffix to create the modified string
		return args.prefix || '' + value + args.suffix || '';
	}
}
