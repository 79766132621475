import { Directive, HostListener } from '@angular/core';
import { EditableFieldComponent } from '../controls/editable-field/editable-field.component';

@Directive({
	selector: '[appEditableOnEnter]',
})
export class EditableOnEnterDirective {
	constructor(private editable: EditableFieldComponent) {}

	@HostListener('keyup.enter')
	onEnter() {
		this.editable.toViewMode();
	}
}
