<ng-container ngForm #frm="ngForm">
	<mat-checkbox
		[ngClass]="inputClass"
		[color]="color"
		[checked]="checked"
		[labelPosition]="labelPosition"
		[disabled]="disabled || readonly"
		[value]="value"
		[indeterminate]="false"
		(change)="toggle()"
		[(ngModel)]="result"
		(ngModelChange)="onModelChanged($event)"
		[appCustomPatternValidator]="fieldValidationConstraints"
		[name]="name"
		#control="ngModel"
	>
		{{ label }}
	</mat-checkbox>
	<mat-error>
		<ws-field-error
			[error]="error"
			[interpolationParams]="errorInterpolationParams"
			[control]="frm.controls[name]"
		></ws-field-error>
	</mat-error>
</ng-container>
