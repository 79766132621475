import { Component, EventEmitter, Output } from '@angular/core';

@Component({
	selector: 'ws-delete-cell',
	templateUrl: './delete-cell.component.html',
	styleUrls: ['./delete-cell.component.scss'],
})
export class DeleteCellComponent {
	@Output() onDelete: EventEmitter<any> = new EventEmitter<any>();
}
